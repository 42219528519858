import React, {useEffect,useState} from 'react';
import dashboard from '../Dashboard.module.css';
import ProgressBars from './ProgressBars/ProgressBars';
import { dataService } from '@Services';
import { smcUtility } from '@Utilities';
import {LoadingShimmer} from '@CommonComponents/Loading/Loading';
import Chart from './Chart/Chart';

function BoxGroup() {
    const [firstBarData, setFirstBarData] = useState();
    const [secondBarData, setSecondBarData] = useState();
    const [categoryData, setCategoryData] = useState();
    

    useEffect(()=>{
        dataService.fetchWeeklyCategoryData().then((res)=>{
            if(res)
            {
                res=res.filter(d=>new Date(d.time).getHours()>=0 && new Date(d.time).getHours()<6)
                let cd = []
                for (const [ , value] of res.entries()) {
                    let count = 0;
                    let total = 0;
                    Object.keys(value['values']).map((result,index)=>{
                        total+=value['values'][result].score;
                        count++;
                        return 0;
                    });         
                    const average = parseFloat(total/count, 2);
                    const data = {
                        time: value.time,
                        score: average
                    }
                    cd.push(data);
                }
                setCategoryData(cd);
            }
        });
        smcUtility.smcSetting().then((data)=>{
            let techList = data.technologyList.child;
            const totalActivatedTechnology = data.technologyList.totalActivatedTechnology;
            let counter = 0;
            let max1Technology = {
                name: 'TECH 4',
                score: -1
            }
            let max2Technology = {
                name: 'TECH 3',
                score: -1
            }
            let max3Technology = {
                name: 'TECH 2',
                score: -1
            }
            let max4Technology = {
                name: 'TECH 1',
                score: -1
            }
            Object.keys(techList).map((tech,index)=>{
                if(techList[tech].show){ 
                    dataService.latestTechCompliance(tech).then((techCompData)=>{
                        ++counter;
                        if(techCompData)
                        {
                            const technologyName = techCompData.data.technology_name;
                            const technologyScore = techCompData.data.technology_compliance;
                            if(technologyScore > max1Technology.score){
                                max4Technology = {
                                    name: max3Technology.name,
                                    score: max3Technology.score
                                }
                                max3Technology = {
                                    name: max2Technology.name,
                                    score: max2Technology.score
                                }
                                max2Technology = {
                                    name: max1Technology.name,
                                    score: max1Technology.score
                                }
                                max1Technology = {
                                    name: technologyName,
                                    score: technologyScore
                                }
                            }
                            else if(technologyScore > max2Technology.score){
                                max4Technology = {
                                    name: max3Technology.name,
                                    score: max3Technology.score
                                }
                                max3Technology = {
                                    name: max2Technology.name,
                                    score: max2Technology.score
                                }
                                max2Technology = {
                                    name: technologyName,
                                    score: technologyScore
                                }
                            }
                            else if(technologyScore > max3Technology.score){
                                max4Technology = {
                                    name: max3Technology.name,
                                    score: max3Technology.score
                                }
                                max3Technology = {
                                    name: technologyName,
                                    score: technologyScore
                                }
                            }
                            else if(technologyScore > max4Technology.score){
                                max4Technology = {
                                    name: technologyName,
                                    score: technologyScore
                                }
                            }
                        }
                        if(counter === totalActivatedTechnology)
                        {
                            const fbData = [max1Technology, max3Technology];
                            const sbData = [max2Technology, max4Technology];
                            setFirstBarData(fbData);
                            setSecondBarData(sbData);
                        }
                    });
                }
                return 0;
            });
            
        });

    },[]);

    return (
        <div 
            className={dashboard.dashboardBoxWrapper}
            style={{padding: 0, background: "transparent"}}
        >
            <div id="pp" style={{display:"flex", width: "100%", height:"50%", marginBottom:"2%"}}>
                <div 
                    style={{
                        width: "100%", 
                        height:"100%", 
                        display:"flex", 
                        flexFlow:"column",
                        background:"#2b3348",
                        marginBottom: "2%"
                    }}
                >
                    <h5 style={titleStyle}>Overall Compliance</h5>
                    {categoryData? <Chart data={categoryData}/> : <LoadingShimmer />} 
                </div>
            </div>
            <div id="technologies" style={{display:"flex", height:"49%"}}>
                <div 
                    style={{
                        width: "49%", 
                        height:"100%", 
                        display: "grid", 
                        alignItems:"center", 
                        background:"#2b3348", 
                        marginRight: "1%"
                    }}
                >
                    {firstBarData ? <ProgressBars data={firstBarData}/> : <LoadingShimmer />}
                </div>
                <div 
                    style={{
                        width: "49%", 
                        height:"100%", 
                        display: "grid", 
                        alignItems:"center", 
                        background:"#2b3348", 
                        marginLeft: "1%"
                    }}
                >
                    {secondBarData ? <ProgressBars data={secondBarData}/> : <LoadingShimmer />}
                </div>
            </div>
        </div>
    )
}

const titleStyle = {
    marginLeft: "10px", 
    marginBottom: "-5px",
    color: "#cacaca",
    fontSize:"1.1em"
}

export default BoxGroup