import 'react-bootstrap';
import classes from './ScoreBoxes.module.css';
import { Loading } from '@CommonComponents/Loading/Loading';


function ScoreBoxes(props) {
    return (
        props.data.score < 0 ? 
            <div className={classes.wrapper} style={{justifyContent: 'center'}}>
                <Loading size={5} />
            </div>
        :
            <div className={classes.wrapper}
            style={{
                background:
                    parseInt(props.data.score) > 70 ? 
                    parseInt(props.data.score) > 80 ?
                        'linear-gradient(220deg, rgb(30, 180, 30) 0%, rgba(34,51,95,1) 70%)' : 
                        'linear-gradient(220deg, rgba(255,191,0,1) 0%, rgba(34,51,95,1) 70%)' :
                        'linear-gradient(220deg, rgb(192, 45, 45) 0%, rgba(34,51,95,1) 70%)'
            }}>
                
                <div className={classes.score}>
                    {props.data.score}
                </div>

                <div className={classes.title} style={{
                    fontSize:".7rem"
                }}>
                    {/* <div style={{whiteSpace:"nowrap"}}>{props.data.title}</div> */}
                    <div>{props.data.title.split(' ')[0]}</div>
                    <div>{props.data.title.split(' ').slice(1)}</div>
                </div>
            </div>
    )
}
export default ScoreBoxes