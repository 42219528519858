import AWSPage from "./components/AWSPage/AWSPage";
import Incident from "./components/Incidents/Incidents"


function CustomizableTechnologyInsightPage(props){
    const technologyName = props.techName ?? '';
  // console.log(technologyName);
    return (
        <div>
            {technologyName==='aws'?
                <AWSPage/>
            :null}
            {technologyName==='incident'?
                <Incident/>
            :null}
        </div>
    );
}

export default CustomizableTechnologyInsightPage;