import { BrowserRouter } from 'react-router-dom';
import Main from '@Containers/Main/Main';


function App() {
  return (
      <BrowserRouter>
        <Main />
      </BrowserRouter>
  );
}

export default App;
