import Chart from './Chart/Chart';
import dashboard from '../Dashboard.module.css';
import React,{useEffect, useState} from 'react';
import { dataService,commonService } from '@Services';
import { smcUtility } from '@Utilities';
import {Loading} from '@CommonComponents/Loading/Loading'

function TechnologyTrend() {
    const [technologyData, setTechnologyData] = useState();
    useEffect(()=>{
        let startDate = new Date();
        startDate.setUTCDate(startDate.getUTCDate() - 8);
        startDate = commonService.getNowStartDate(startDate);
        smcUtility.smcSetting().then((data)=>{
            let techList = data.technologyList.child;
            let allTechData = [];
            const totalActivatedTechnology = data.technologyList.totalActivatedTechnology;
            let counter = 0;
            Object.keys(techList).map((tech,index)=>{
                if(techList[tech].show){
                    dataService.fetchTechnologyData(tech,false,[],false,startDate,30).then(res=>{
                        ++counter;
                        if(res)
                        {
                            res=res.filter(d=>new Date(d.time).getHours()>=0 && new Date(d.time).getHours()<6)
                            allTechData.push(res);
                        }
                        if(totalActivatedTechnology === counter)
                        {
                            setTechnologyData(allTechData);
                        }
                    });
                }
                return 0;
            });
        });
    },[]);
    return (
        <div 
            className={dashboard.dashboardBoxWrapper}
            id = "trend"
       >
            <h4 
                style={{
                    color:"#cacaca", 
                    fontSize:"1em",
                    borderBottom: "1px solid",
                    paddingBottom:"10px"
                }}
            >Technology Trend</h4>
            <div>
                {technologyData?
                    <Chart data={technologyData}/>
                :<Loading/>}
            </div>
        </div>
    )
}
export default TechnologyTrend