import React from 'react'
import classes from './TechCard.module.css';
import Card from '@CommonComponents/Card/Card';
import { useNavigate } from "react-router-dom";
import { commonService } from '@Services';
import { Tooltip as ReactTooltip } from 'react-tooltip';



function TechCard(props){

    const navigate = useNavigate();
    function buttonOnClick(technology){
      // console.log(technology)
        navigate('/technology-details/'+technology);
    }
    function imgPath(img){
        img=img.toLowerCase()
        let r;
        try {
            r=require('../../../assets/'+img+'.png');
        } catch (error) {
            r=require('../../../assets/SMClogo_2.png');
        }
        return r
    }
    return (
        <div style={{height:"100%"}}>
            <Card style={{height: '300px'}}>
                <div className={classes.image}>
                    <img src={imgPath(props.techName)} alt=""/>
                </div>
                <div className={classes.content}>
                    <h1>{commonService.technologyKnownAs(props.techName)}</h1>
                    <p>Compliance Score : {props.complianceScore}</p>
                </div>
                <div className={classes.actions}>
                    <button onClick={()=>buttonOnClick(props.techName)}>Details</button>
                    {/* <FaFileDownload data-for='csv' data-tip='csv-report' style={{marginLeft:'90%',marginBottom:'50px', cursor:'pointer'}} onClick={generateCSV} size={20}/> */}
                </div>
            </Card>
            <ReactTooltip id='csv' place='bottom' textColor='#FFFFFF' backgroundColor='#303d5099' effect='float' />
        </div>
    );
}

export default TechCard;