import ReactECharts from 'echarts-for-react';

function Chart(props) {
    const options = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Storage', 'Memory', 'CPU'],
          textStyle: {
            color: '#cacaca',
            fontFamily: 'monospace',
            fontSize: '12'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '15%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          axisLine: {
            lineStyle: {
                color:'white'
            }
          }
        },
        yAxis: {
          type: 'value',
          max: 100,
          axisLine: {
            lineStyle: {
                color:'white'
            }
          }
        },
        series: [
          {
            name: 'Storage',
            type: 'line',
            data: [90, 70, 85, 68, 87, 79, 82]
            // data: [0, 0, 0, 0, 0, 0, 0]
          },
          {
            name: 'Memory',
            type: 'line',
            data: [93, 50, 85, 58, 81, 69, 89]
            // data: [0, 0, 0, 0, 0, 0, 0]
          },
          {
            name: 'CPU',
            type: 'line',
            data: [70, 90, 45, 78, 97, 69, 92]
            // data: [0, 0, 0, 0, 0, 0, 0]
          }
        ]
    };

    return (
        <ReactECharts 
            option={options}
            style={{width:"100%", height:"100%"}}
        />
    )
}
export default Chart