import React,{useState} from 'react';
import { Steps } from "intro.js-react";
// import  "./Guide.css"
import "intro.js/introjs.css";

function Guide(){

  const [stepsEnabled, setStepsEnabled] = useState(true);
  const [hintsEnabled, setHintsEnabled] = useState(false);
  const [initialStep, setInitalStep] = useState(0);
  let onExit = () => {
    setStepsEnabled(false);
    // setHintsEnabled(true);
    sessionStorage.setItem('guide','false')
  };
  // let onClose = () => {
  //   setHintsEnabled(false);
  //   sessionStorage.setItem('guide',false)
  // };
  // let toggleSteps = () => {
  //   setStepsEnabled(!stepsEnabled);
  // };

  
  const steps = [
    {
      element: "#navigation",
      intro: "This bar will help you navigate you through the different features & views provided in SMC ",
      // position:"bottom",
    //   tooltipClass:"introjs-tooltip"
    },
    {
      element: "#user_details",
      intro: "On hovering on the button we can get option for user detail and also option to change password if required."
    },
    {
      element: "#technology",
      intro: "This row displays the overall compliance of each category.",
      highlightClass: '.row-style-class'
    },
    // {
    //   element: "#speedometer",
    //   intro: "On clicking the guage of a particular category, you will be directed to CATEGORY page where compliance of every sub-category within that category is displayed."
    // },
    {
      element: "#pp",
      intro: "This displays the overall complaince score(average of the compliance scores of all the integrated technologies ) in the SMC portal across last 7 days."
    },
    {
      element: "#boxes",
      intro: "This displays the compliance of the 4 most important usecases, this can be customized according to your needs."
    },
    {
      element: "#technologies",
      intro: "This are the 4 best compliant technologies out of all the other technolgies integrated on the portal."
    },
    {
      element: "#trend",
      intro: "This displays the Compliance scores of all the integrated technologies in the SMC portal across last 7 days.",
      position:"bottom"
    },
    {
      element: "#Monitoring",
      intro: "This graphs displays the compliance scores of major usecases for monitoring tool across last 7 days."
    },
    {
      element: "#SIR",
      intro: "This displays the security incidents logged,assigned and closed in the last 7 days. "
    }
  ];
  // const hints = [
  //   {
  //     element: '#logout',
  //     hint: 'On clicking this icon you will log out from the SMC portal.',
  //     hintPosition: 'top-left',
  //   },
  // ];
    return(
      <React.Fragment>
        <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
        onComplete={onExit}
        />
        {/* {hintsEnabled?<Hints
        enabled={hintsEnabled}
        hints={hints}
        onClose={onClose}
        />:null} */}
      </React.Fragment>
    )
}

export default Guide;
