import React,{useState,useEffect} from 'react';
import styles from "@Utilities/scrollbar.module.css";
import { adminValuesService, commonService } from '@Services';
import classes from './TechnologyBaseline.module.css';
import {FaCheckCircle} from 'react-icons/fa';
import Button from 'react-bootstrap/Button';

function TechnologyBaseline(props){
    const technologyName = props.techName;
    const [adminBaselineData, setAdminBaselineData] = useState();
    const [payload, setPayload] = useState();
    const [changeFlag, setChangeFlag] = useState(false);
    const [changedField, setChangedField] = useState();
    const [changedFieldParent, setChangedFieldParent] = useState();
    const [changedValue, setChangedValue] = useState();
    const [submissionStatus, setSubmissionStatus] = useState(false);

    useEffect(()=>{
        let adminData = {}
        adminValuesService.getAdminValues(technologyName).then((response)=>{
            const totalLength = Object.keys(response.data).length - 1;
            Object.keys(response.data).map((adminValue,i)=>{
                if(adminValue !== 'time')
                {
                    adminData={
                        ...adminData,
                        [adminValue]: response.data[adminValue]
                    }
                }
                if(totalLength === i)
                {
                    setAdminBaselineData(adminData);
                    setPayload(adminData);
                }
                return 0;
            });
        });
    },[technologyName]);

    function handleInputFieldChange(event){
        if(event.target.value)
        {
            setChangedField(event.target.id);
            setChangedValue(event.target.value);
            setChangeFlag(true);
        }
        else
        {
            setChangeFlag(false);
        }
    }
    function handleSecondaryInputFieldChange(event){
        if(event.target.value)
        {
            setChangedField(event.target.id);
            setChangedValue(event.target.value);
            setChangedFieldParent(event.target.parentElement.id);
            setChangeFlag(true);
        }
        else
        {
            setChangeFlag(false);
        }
    }
    function validateInputFieldChange(){
        if(changeFlag){
            let toEditPayload = payload;
            if(Array.isArray(toEditPayload[changedField])){
                const data = changedValue.split(',');
                toEditPayload[changedField] = data;
            }
            else{
                toEditPayload[changedField] = changedValue;
            }
            setPayload(toEditPayload);
        }
    }
    function validateSecondaryInputFieldChange(){
        if(changeFlag){
            let toEditPayload = payload;
            if(Array.isArray(toEditPayload[changedFieldParent][changedField])){
                const data = changedValue.split(',');
                toEditPayload[changedFieldParent][changedField] = data;
            }
            else{
                toEditPayload[changedFieldParent][changedField] = changedValue;
            }
            setPayload(toEditPayload);
        }
    }
    function handleSubmit(){
        // console.log(payload);
        adminValuesService.setAdminValues(technologyName,payload).then((response)=>{
          // console.log(response);
            if(response.status === 201)
                setSubmissionStatus(true);
        });
    }

    return (
        <div className={styles.scrollbar_smc}>
            {adminBaselineData?
                <div style={{maxWidth: '700px'}}>
                    {Object.keys(adminBaselineData).map((adminValue,i)=>(
                        <div className={classes.inputField} key={i}>
                            <label htmlFor={adminValue}>{commonService.capitalizeletterWith_(adminValue)} :</label>
                            {typeof adminBaselineData[adminValue] === 'object' && !Array.isArray(adminBaselineData[adminValue])?
                            <div id={adminValue}>
                                <div>

                                </div>
                                {Object.keys(adminBaselineData[adminValue]).map((val,j)=>(
                                    <div key={j}>
                                    <label htmlFor={val}>{commonService.capitalizeletterWith_(val)} :</label>
                                    <input
                                        type={typeof adminBaselineData[adminValue][val] === 'number'?"number":"text"}
                                        id={val}
                                        placeholder={adminBaselineData[adminValue][val]}
                                        onChange={handleSecondaryInputFieldChange}
                                        onBlur={validateSecondaryInputFieldChange}
                                        />
                                    </div>
                                ))}
                            </div>
                            :<input
                                type={typeof adminBaselineData[adminValue] === 'number'?"number":"text"}
                                id={adminValue}
                                placeholder={adminBaselineData[adminValue]}
                                onChange={handleInputFieldChange}
                                onBlur={validateInputFieldChange}
                                />}
                        </div>
                    ))}
                    <div className={classes.right}>
                        {submissionStatus?<FaCheckCircle size={35}/>:null}
                        <Button variant='secondary' color='primary' 
                            size='small' onClick={handleSubmit} >
                                    Submit
                        </Button>
                    </div>
                </div>
            :null}
        </div>
    );
}

export default TechnologyBaseline;