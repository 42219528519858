import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Home from './Home';
import LoginHandler from './LoginHandler';
import LogoutHandler from './LogoutHandler';

const Main = (props) => {
    return (
        <Routes>
            <Route 
                path="/login" 
                element={ <LoginHandler />}
            />
            <Route 
                path="/logout" 
                element={ <LogoutHandler />}
            />
            <Route 
                path="/*" 
                element={ <Home />}
            />
        </Routes>
    );
}

export default Main