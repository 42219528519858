import React,{useEffect, useState} from 'react';
// import TabContainer from 'react-bootstrap/TabContainer';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
// import Loading from '@CommonComponents/Loading/Loading';
import { commonService } from '@Services';
import { smcUtility } from '@Utilities';
import InsightTechnologyTabPage from './components/InsightTechnologyTabPage';
import styles from '@Utilities/scrollbar.module.css';
import tabStyles from '@Utilities/tabs.module.css';
import CustomizableInsightPage from './components/CustomizableInsightPage/CustomizableInsightPage';

function InsightTabPage(props){

    const [insightTabs, setInsightTabs] = useState();
    const [customizedInsightTabs, setCustomizedInsightTabs] = useState();
    const [defaultTab,setDefaultTab] = useState();
    useEffect(()=>{
        smcUtility.smcSetting().then((data)=>{
            const insightCatergoryList = data.insight.child;
            const customizedInsightCatergoryList = data.insight.customizableChild;
            let allInsightData = [];
            Object.keys(insightCatergoryList).map((insightCatergory,index)=>{
                const insightCatergoryName = insightCatergoryList[insightCatergory];
                if(insightCatergoryName.show){ 
                    let insightTabTechs = []
                    Object.keys(insightCatergoryName.child).map((insightTechnology,i)=>{
                        const insightTechnologyData = insightCatergoryName.child[insightTechnology];
                        const insightTabTechData = {
                            name: insightTechnology,
                            automatic: insightTechnologyData.auto
                        }
                        insightTabTechs.push(insightTabTechData);
                        return 0;
                    });
                  // console.log(insightCatergory);
                    if(index === 0)
                        setDefaultTab(insightCatergory);
                    const insightTabData={
                        catergoryName: insightCatergory,
                        insightTabTech: insightTabTechs
                    }
                    allInsightData.push(insightTabData);
                }
                return 0;
            });
            setInsightTabs(allInsightData);

            let allCustomizedInsightCatergories = []
            Object.keys(customizedInsightCatergoryList).map((insightCatergory,index)=>{
                const insightCatergoryName = customizedInsightCatergoryList[insightCatergory];
                if(insightCatergoryName.show){ 
                    allCustomizedInsightCatergories.push(insightCatergory);
                }
                return 0;
            });
            setCustomizedInsightTabs(allCustomizedInsightCatergories);
        });
    },[]);

    return (
        <div style={{height:"100%"}} className={styles.scrollbar_smc}>
            {insightTabs && customizedInsightTabs?
                <Tabs
                    id="controlled-tab-example"
                    defaultActiveKey={defaultTab}
                    className="mb-3 border-0"
                >
                    
                    {insightTabs.map( (insightCat,i) => (
                        <Tab 
                            key={i} 
                            eventKey={insightCat.catergoryName} 
                            style={{height:"100%"}} 
                            tabClassName={tabStyles.tabNavLink}
                            title={commonService.capitalizeletterWith_(insightCat.catergoryName)}
                        >
                            <InsightTechnologyTabPage data={insightCat.insightTabTech} />
                        </Tab>
                    ))}
                    {customizedInsightTabs.map( (insightCat,i) => (
                        <Tab 
                            key={i} 
                            eventKey={insightCat} 
                            style={{height:"100%"}} 
                            tabClassName={tabStyles.tabNavLink}
                            title={commonService.capitalizeletterWith_(insightCat)}
                        >
                            
                            <CustomizableInsightPage data={insightCat} />
                        </Tab>
                    ))}
                </Tabs>
            :null}
        </div>
  );
}
export default InsightTabPage;