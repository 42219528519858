import { Routes, Route } from 'react-router-dom';
import UserDetailsPage from './components/UserDetailsPage';

function UserPage(){
    return(
        <Routes>
            <Route path="/user/details" element={ <UserDetailsPage/> } />
        </Routes>
    );
}

export default UserPage;