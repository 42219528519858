import React,{useState} from 'react'
import ReactEcharts from 'echarts-for-react'
import MappingTable from './MappingTable/MappingTable'
import {mapData, appMappingData, deviceMappingData} from './data';

function MindMap(props){

        const [mappingTableModal,setMappingTableModal]=useState(false);
        const [tableHead,setTableHead]=useState();
        const [tableData,setTableData]=useState();
        const [type,setType]=useState();
    

    const openMappingTableModal = (headers, tableData) => {
        setMappingTableModal(true);
        setTableHead(headers);
        setTableData(tableData);
    }
    const closeMappingTableModal = () => {
        setMappingTableModal(false)
    }

    
    const option={
        tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove'
        },
        series:[
            {
                type: 'tree',
                symbol:'circle',
                data: [mapData],
                // layout:'radial',
                top: '2%',
                left: '10%',
                bottom: '2%',
                right: '20%',
                symbolSize: 35,
                itemStyle:{
                    normal: {
                        color: '#2c3e50',
                        borderColor: '#fff',
                        borderWidth: 0,
                        borderType: 'solid',
                    },
                    emphasis: {
                        color: '#ccc',
                        borderColor: 'blue',
                        borderWidth: 1.5,
                        borderType: 'solid',
                    }
                },
                label: {
                    normal: {
                        position: 'top',
                        verticalAlign: 'middle',
                        align: 'center',
                        fontSize:15,
                        color:'#fff',
                        padding: [0, 0, 2.5, 0]
                    },
                    emphasis: {
                        color: '#fff',
                        // borderColor: '#ccc',
                        // borderWidth: 1.5,
                        // borderType: 'solid',
                        textShadow:'3px 18px 10px red'
                    }
                },
                lineStyle:{
                    color: '#ccc',
                    width: 1.5,
                    curveness: .5,
                },
                leaves: {
                    label: {
                        position: 'right',
                        verticalAlign: 'middle',
                        align: 'left'
                    },
                    itemStyle: {
                        color:'red'
                    }
                },
                expandAndCollapse: true,
                animationDuration: 550,
                animationDurationUpdate: 750
            }
        ]
    }
    
    const onChartClick=(param) => {
        // console.log(param.data)
        
        if (param.data.name === 'App') {
            setType('App');
        }
        else if (param.data.name === 'Device') {
            setType('Device');
        }

        if (!param.data['children']) {
            if (type === 'App') {
                openMappingTableModal(
                    ['Description', 'GSK-Source', 'OWASP Classification'],
                    appMappingData[param.data.name]
                );
            }
            else if (type === 'Device') {
                openMappingTableModal(
                    ['Description', 'Source', 'GSK-Source', 'Severity', 'Application'],
                    deviceMappingData[param.data.name]
                );
            }
        }
    }

    return(
        <div style={{width:'100%'}} id="mindMap">
            <ReactEcharts 
                option={option} 
                onEvents={{'click':onChartClick}}
            />
            {mappingTableModal && tableHead && tableData?
            <MappingTable 
                open={mappingTableModal} 
                close={closeMappingTableModal} 
                tableHead = {tableHead}
                tableData = {tableData}
            />
            :null}
        </div>
    )
}
export default MindMap