import { FaArrowUp,FaArrowDown } from 'react-icons/fa';
import classes from './ScoreArrow.module.css';

function ScoreArrow(props) {

    const data = {
        score: '100%',
        subcategory: 'Subcategory Name',
        change: 0,
        ...props
    }

    return (
        <div className={classes.subcategory}>
            <div className={classes.subcatWrapper}>
                <div className={classes.scoreWrapper}>
                    <div className={classes.score}>{data.score}</div>
                    {data.change>0?
                        <FaArrowUp style={{color: '#6ad72d'}}/>
                    :data.change<0?
                        <FaArrowDown style={{color: '#ff2c00'}}/>
                    :null}
                </div>
                <p className={classes.subcatTitle}>{data.subcategory}</p>
            </div>
        </div>
    )
}
export default ScoreArrow