import React,{useState,useEffect} from 'react';
import {smcUtility} from "@Utilities";
import { Link } from 'react-router-dom';
import classes from './NavigationSidebar.module.css';
import { 
    FaHome, 
    FaChartPie, 
    FaCodeBranch,
    FaBoxes,
    FaUsersCog,
    FaBars,
} from 'react-icons/fa';
import {TbReportAnalytics} from 'react-icons/tb';

function NavigationSidebar() {
    const [showInsight, setShowInsight] = useState(false);
    
    useEffect(()=>{
        smcUtility.smcSetting().then((data)=>{
            setShowInsight(data.insight.show);
        });
    },[]);
    return (
        <div className={classes.navigationSidebar}>
            <div id="navigation" className={classes.navigationWrapper}>
                <div>
                    <FaBars />
                </div>
                <div>
                    <Link to={'/'}>
                        <FaHome />
                    </Link>
                    <div className={classes.submenu}>
                        <Link to={'/'}>Dashboard</Link>
                    </div>
                </div>
                <div>
                    <Link to={'/inventory'}>
                        <FaBoxes />
                    </Link>
                    <div className={classes.submenu}>
                        <Link to={'/inventory'}>Inventory</Link>
                    </div>
                </div>
                <div>
                    <Link to={'/admin/baseline'}>
                        <FaUsersCog />
                    </Link>
                    <div className={classes.submenu}>
                        <Link to={'/admin/baseline'}>Baseline</Link>
                        <Link to={'/admin/weightage'}>Weightage</Link>
                    </div>
                </div>
                <div>
                    <Link to={'/smcmap'}>
                        <FaCodeBranch />
                    </Link>
                    <div className={classes.submenu}>
                        <Link to={'/smcmap'}>Mind Map</Link>
                    </div>
                </div>
                {showInsight?
                <div>
                    <Link to={'/insight'}>
                        <FaChartPie />
                    </Link>
                    <div className={classes.submenu}>
                        <Link to={'/insight'}>Insight</Link>
                    </div>
                </div>
                :null}
                <div>
                    <Link to={'/report'}>
                        <TbReportAnalytics />
                    </Link>
                    <div className={classes.submenu}>
                        <Link to={'/report'}>Executive Report</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NavigationSidebar