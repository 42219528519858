import React, { useState } from "react";

import classes from "./Accordion.module.css";

function AccordionItem(props) {
    const [isActive, setIsActive] = useState(false);
 return (
    <div className={classes.accordionItem}>
        <div className={classes.accordionTitle} onClick={() => setIsActive(!isActive)}>
            <div style={{color: 'black'}}>{props.title}</div>
            <div style={{color: 'black'}}>{isActive ? '-' : '+'}</div>
        </div>
        {isActive && <div className={classes.accordionContent}>{props.children}</div>}
    </div>
 );
}

export default AccordionItem;