import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Card from '@CommonComponents/Card/Card';
import ActionBarGraph from './ActionBarGraph/ActionBarGraph';
import ServerSignComp from './ServerSignComp/ServerSignComp';
import AVCoverage from './AVCoverage/AVCoverage';
import CompoundBarGraph from './CompoundBarGraph/CompoundBarGraph';
import CpuUsagePie from './CpuUsagePie/CpuUsagePie';
import MFAcompliance from './MFAcompliance/MFAcompliance';
import ScoreBoxes from './ScoreBoxes/ScoreBoxes';

function RemoteAccessPage(props){
    const chartName = {
        fontSize:"1.3rem",
        padding: "5px 0 5px 10px",
        boxShadow:"0 1px 5px #000",
        position:"relative"
    }

    return (
        <div>
            <Container style={{padding:0}} fluid>
                <Row style={{marginLeft:"0px",marginRight:"5px"}} >
                    <ScoreBoxes 
                        box={[
                            {
                                name:"Remote Handler Compliance",
                                score:"100%",
                                color: "rgb(42, 187, 67)"
                            },
                            {
                                name:"Laptop Encryption",
                                score:"90%",
                                color:"#ffbf00"
                            },
                            {
                                name:"Service Availability",
                                score:"100%",
                                color:"rgb(42, 187, 67)"
                            },
                            {
                                name:"Emergency Change",
                                score:"6%",
                                color:"rgb(230, 60, 60)"
                            }
                        ]}
                    />
                </Row>
                <Row className={"mb-sm-0  mb-md-4"} style={{marginLeft:"0px",marginTop:"12px",marginRight:"5px"}} >
                    
                    <Col md={3}>
                        <Card>
                            <div className={chartName}>
                                <ServerSignComp />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>Remote Host Compliance</h4>
                            </div>      
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <div className={chartName}>
                                <AVCoverage />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>Remote AV Coverage</h4>
                            </div>      
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <div className={chartName}>
                                <CpuUsagePie />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>Firewall CPU Usage</h4>
                            </div>      
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <div className={chartName}>
                                <MFAcompliance />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>VPN MFA Compliance</h4>
                            </div>      
                        </Card>
                    </Col>
                </Row>
                <Row className={"mb-sm-0  mb-md-4"} style={{marginLeft:"0px",marginTop:"12px",marginRight:"5px"}} >
                    <Col md={6}>
                        <Card>
                            <div className={chartName}>
                                <CompoundBarGraph />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>Firewall Signature</h4>
                            </div>      
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <div className={chartName}>
                                <ActionBarGraph />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>Action Taken Against Threats</h4>
                            </div>
                        </Card>      
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default RemoteAccessPage;