import React from "react";
import { userService } from '@Services';
import {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import classes from './LogoutPage.module.css';


function LogoutPage(props) {
    const [loggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate();
    useEffect(()=>{
        userService.logout().then((data)=>{
            setLoggedIn(data);
            props.onLogin(0);
        })
    },[props]);
    return (
        <React.Fragment>
        {!loggedIn?
        <div className={classes.flexContainer}>
            <h1 className={classes.dots}>Logging out<span>.</span><span>.</span><span>.</span></h1>
        </div>:
            navigate('/login')
        }
        </React.Fragment>
  );
}

export default LogoutPage;