// import React from 'react'
import Modal from 'react-bootstrap/Modal';

function ApplicationModal(props){

    return(
            <Modal
            show={props.open}
            onHide={props.close}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable={true}
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Summary
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table>
                        {typeof(props.applications[0]) === 'object' ? 
                            <tr>
                                <th>Application</th>
                                <th>Title</th>
                                <th>GSK Source</th>
                            </tr>
                        :null}
                        {typeof(props.applications[0]) === 'string' ?
                        props.applications.map((app,i)=>(
                            <tr key={i}>
                                <td>{app}</td>
                            </tr>
                        ))
                        :
                        props.applications.map((app,i)=>(
                            <tr key={i}>
                                <td>{app.application}</td>
                                <td>{app.title}</td>
                                <td>{app.gsk_source}</td>
                            </tr>
                        ))
                        }
                    </table>
                </Modal.Body>
            </Modal>
    );
}

export default ApplicationModal