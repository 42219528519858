import {urlConstants} from '@Constants';
import Api from '@Api/SMCApi';
import { userService } from '@Services';
import { logger } from '@Utilities';

export const adminValuesService = {
    getAdminValues,
    setAdminValues,
    checkAdminAvailability,
};
/*
getAdminValues => to get admin values for specific techlogoies, technology name to be passed
setAdminValues => to update admin values for specific techlogoies, technology name and payload to be passed
*/

function logout(){
  let url = window.location.href;
  url = url.split('/')[0];
  if(url.startsWith('http://localhost') || url.startsWith(urlConstants.BASE_URL.split(':')[0]))
  {
    window.location.href = url+'/logout';
  }
}
function checkAdminAvailability(tech) {
  let url=urlConstants.SITE_URL
  const token=userService.getToken();
  url+=urlConstants[tech.toLowerCase()]+urlConstants.ADMIN_URL
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
    if(res.status===200)
        return true;
    else
        return false;
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
      logger.debug(error);
  })
}
function getAdminValues(tech){
  let url=urlConstants.SITE_URL
  const token=userService.getToken();
  url+=urlConstants[tech.toLowerCase()]+urlConstants.ADMIN_URL
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
    if(res.status!==401)
        return res
    else
        logout();
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
      logger.debug(error);
  })
}
function setAdminValues(tech,payload){
  let url=urlConstants.SITE_URL
  const token=userService.getToken();
  url+=urlConstants[tech.toLowerCase()]+urlConstants.ADMIN_URL
  return Api.post(url,payload,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
    if(res.status!==401)
        return res
    else
        logout();
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
      logger.debug(error);
  })
}
