import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Card from '@CommonComponents/Card/Card';
import ScoreBoxes from './ScoreBoxes/ScoreBoxes';
import NumberOfBoTs from './NumberOfBoTs/NumberOfBoTs';
import ActivitySplit from './ActivitySplit/ActivitySplit';


function AutomationPage(props){
    const chartName = {
        fontSize:"1.3rem",
        padding: "5px 0 5px 10px",
        boxShadow:"0 1px 5px #000",
        position:"relative"
    }

    return(
        <div>
            <Container style={{padding:0}} fluid>
                <Row style={{marginLeft:"5px", marginRight:"-2px"}} >
                    <ScoreBoxes 
                        box={[
                            {
                                name:"No. of BoTs",
                                score:"121",
                                color: "rgb(59, 155, 216)" //blue
                            },
                            {
                                name:"Successful Execution",
                                score:"91%",
                                color:"rgb(230, 60, 60)" //green
                            },
                            {
                                name:"Technology Coverage",
                                score:"60%",
                                color:"rgb(42, 187, 67)" //red
                            },
                            {
                                name:"Emergency Changes",
                                score:"6%",
                                color:"rgb(230, 60, 60)" //red
                            }
                        ]}
                    />
                </Row>
                <Row style={{marginLeft:"5px", marginTop:"15px", marginRight:"0px"}} >
                    <Col md={6}>
                        <Card>
                            <div className={chartName}>
                                <NumberOfBoTs />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>No. of BoTs Execution</h4>
                            </div>
                        </Card>   
                    </Col>
                    <Col md={6}>
                        <Card>
                            <div className={chartName}>
                                <ActivitySplit />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>Activity Split</h4>
                            </div>
                        </Card>   
                    </Col>
                </Row>
            </Container>
        </div>  
    );
}

export default AutomationPage;