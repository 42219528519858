import Chart from './Chart/Chart';
import dashboard from '../Dashboard.module.css';
import React from 'react';

function HealthMonitoring() {
    
    return (
        <div 
            className={dashboard.dashboardBoxWrapper}
            id="Monitoring"
        >
            <h4 
                style={{
                    color:"#cacaca", 
                    fontSize:"1em",
                    borderBottom: "1px solid",
                    paddingBottom:"10px"
                }}
            >Monitoring</h4>
            <div>
                <Chart/>
            </div>
        </div>
    )
}
export default HealthMonitoring