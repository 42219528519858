import React,{useState,useEffect} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { commonService, adminValuesService } from '@Services';
import TechnologyBaseline from './components/TechnologyBaseline';
import tabs from '@Utilities/tabs.module.css';
import {smcUtility} from "@Utilities";


function BaselineConfigPage(){
    const [technologies, setTechnologies] = useState();
    
    useEffect(()=>{
        smcUtility.smcSetting().then((data)=>{
            const totalTechnolyCount = data.technologyList.totalActivatedTechnology;
            const technologies = data.technologyList.child;
            let techWithBaseLine = []
            let counter = 0;
            Object.keys(technologies).map((tech)=>{
                if(technologies[tech].show){
                    adminValuesService.checkAdminAvailability(tech).then(res=>{
                        ++counter;
                        if(res)
                        {
                            techWithBaseLine.push(tech);
                        }
                        if(totalTechnolyCount === counter)
                        {
                            setTechnologies(techWithBaseLine);
                        }
                    });
                }
                return 0;
            });
        });
    },[]);
    
    return (
        <div>
            <Tabs
                id="controlled-tab-example"
                className="mb-3 border-0"
            >
                {technologies?
                technologies.map((technology,i)=>(
                    <Tab 
                        key={i} 
                        eventKey={technology} 
                        style={{height:"100%"}} 
                        title={commonService.technologyKnownAs(technology)}
                        tabClassName={tabs.tabNavLink}
                    >
                        <TechnologyBaseline techName={technology}/>
                    </Tab>
                ))
                :null}
            </Tabs>
        </div>
    );
}
export default BaselineConfigPage;