import { dataService, deviceService } from '@Services'

export const reportService={
    fetchCloudData,
}
function fetchCloudData(){

    return Promise.all(
        [
            dataService.fetchTechnologyData('s3-aws',false,[],false,'',1),
            dataService.fetchTechnologyData('ec2-aws',false,[],false,'',1),
            // technology,usecase,type,startDate,endDate,filterBy,systemName,params,page
            deviceService.fetchAllDevices('ec2-aws'),
            deviceService.fetchAllDevices('s3-aws')
        ]
    ).then(res=>{
      // logger.debug(res);
        const s3Data=res[0]['data'];
        const ec2Data=res[1]['data'];
        let ec2Total=0,s3Total=0,awsTotal=0,ec2LowCount=0,ec2MediumCount=0,ec2CriticalCount=0,s3LowCount=0,s3MediumCount=0,s3CriticalCount=0;
        // logger.debug(ec2Data);
        let anyUsecase=Object.keys(ec2Data['usecase_compliance'])[0]
        ec2LowCount=ec2Data['usecase_compliance'][anyUsecase]['device_count']['low']
        ec2MediumCount=ec2Data['usecase_compliance'][anyUsecase]['device_count']['medium']
        ec2CriticalCount=ec2Data['usecase_compliance'][anyUsecase]['device_count']['critical']
        ec2Total=ec2MediumCount+ec2LowCount+ec2CriticalCount;

        anyUsecase=Object.keys(s3Data['usecase_compliance'])[0]
        s3LowCount=s3Data['usecase_compliance'][anyUsecase]['device_count']['low']
        s3MediumCount=s3Data['usecase_compliance'][anyUsecase]['device_count']['medium']
        s3CriticalCount=s3Data['usecase_compliance'][anyUsecase]['device_count']['critical']
        s3Total=s3MediumCount+s3LowCount+s3CriticalCount;

        awsTotal=s3Total+ec2Total;

        const encryption=s3Data['usecase_compliance']['encryption']['compliance_score']
        const encryptionLow=s3Data['usecase_compliance']['encryption']['device_count']['low']
        const storage=s3Data['usecase_compliance']['storage']['compliance_score']
        const storageLow=s3Data['usecase_compliance']['storage']['device_count']['low']
        const monitoring=ec2Data['usecase_compliance']['monitoring']['compliance_score']
        const monitoringLow=ec2Data['usecase_compliance']['monitoring']['device_count']['low']
        /*END Data for scores*/
        const ec2DeviceData=(res[2]);
        const s3DeviceData=(res[3]);
        //START Make Data for bar chart
        let locationMap={}
        ec2DeviceData.map(device=>{
            if(!locationMap.hasOwnProperty(device.location.data)){
                locationMap[device.location.data]={
                    'ec2':0,
                    's3':0
                }
            }
            locationMap[device.location.data]['ec2']=locationMap[device.location.data]['ec2']+1;
            return 0;
        })
        s3DeviceData.map(device=>{
            if(!locationMap.hasOwnProperty(device.location.data)){
                locationMap[device.location.data]={
                    'ec2':0,
                    's3':0
                }
            }
            locationMap[device.location.data]['s3']=locationMap[device.location.data]['s3']+1;
            return 0;
        })/*END Data for location bar chart*/ 
        /*START Make Data for  pie chart*/
        let vpcDetails={}
        let securityGroup={}
        ec2DeviceData.map((device,i)=>{
            if(!vpcDetails.hasOwnProperty(device['vpc_details'].data['vpc_id'])){
                vpcDetails[device['vpc_details'].data['vpc_id']]={
                    value:0,
                    deviceData:[]
                }
            }
            vpcDetails[device['vpc_details'].data['vpc_id']].value=vpcDetails[device['vpc_details'].data['vpc_id']].value+1
            vpcDetails[device['vpc_details'].data['vpc_id']].deviceData.push(device);
            if(!securityGroup.hasOwnProperty(device['security_groups_id'])){
                securityGroup[device['security_groups_id']]={
                    value:0,
                    deviceData:[]
                }
            }
            securityGroup[device['security_groups_id']].value=securityGroup[device['security_groups_id']].value+1;
            securityGroup[device['security_groups_id']].deviceData.push(device);
            return 0;
        })

        /*END data for pie chart*/
      // console.log(ec2DeviceData,s3DeviceData,locationMap,ec2Total,s3Total,ec2Data,s3Data,vpcDetails,securityGroup)
        return {awsTotal,ec2Total,s3Total,encryption,encryptionLow,monitoring,monitoringLow,storage,storageLow,locationMap,vpcDetails,securityGroup};
    })
}
//KPI Dashboard under report page
export function fetchKPIData(techologyName, usecases) {
    let type = "data";
    const dateRange=[JSON.parse(localStorage.getItem('date'))[1],JSON.parse(localStorage.getItem('date'))[2]]
    return Promise.all([
        deviceService.fetchDevices(techologyName, usecases, 'all', dateRange, '')
    ]).then(res => {
    
        var pieInfo = {}
        usecases.split(",").map(usecase => {
            if (usecase === 'unused_rules' || usecase === 'node_logging_disabled') {
                type = "compliance_score"
            }
            var uc = {}
            res[0].map(device=>{
                if(!uc.hasOwnProperty(device[usecase][type])){
                    uc[device[usecase][type]] = {
                        value:0,
                        deviceData:[],
                    }
                }
                uc[device[usecase][type]].value+=1;
                uc[device[usecase][type]].deviceData.push(device)
                return 0;
            })
            pieInfo[usecase] = uc
            return 0;
        })
        return pieInfo
    })
}