import React, { useState, useEffect } from 'react';
import classes from './MainContent.module.css';
import { Navigate } from 'react-router';
import { Routes, Route } from 'react-router-dom';
import { userService } from '@Services';
import Dashboard from '@Containers/Dashboard/Dashboard';
import ChangePassword from '@Containers/ChangePassword/ChangePassword';
import Test from '@Containers/Test/Test.js';
import CategoryPage from '@Containers/CategoryPage/CategoryPage';
import SubcategoryPage from '@Containers/SubcategoryPage/SubcategoryPage';
import TechnologyPage from '@Containers/TechnologyPage/TechnologyPage';
import InsightTabPage from '@Containers/InsightPage/InsightTabPage';
import TechnologyDetailsPage from '@Containers/TechnologyDetailsPage/TechnologyDetailsPage';
import SMCMap from '@Containers/SMCMap/SMCMap';
import AdminPage from '@Containers/AdminPage/AdminPage';
import UserPage from '@Containers/UserPage/UserPage';
import ExecutiveReportPage from '@Containers/ExecutiveReportPage/ExecutiveReportPage';

function MainContent() {
    const [userData, setUserData] = useState();

    useEffect(()=>{
        userService.userProfile().then((userData)=>{
            setUserData(userData)
        })
    },[]);

    return(
        <div className={classes.mainContentInner}>
            {userData?
                <React.Fragment>
                    {userData.password_age>0?
                        <Routes>
                            <Route path="/test" element={<Test/>} />
                            <Route path="/categories" element={<CategoryPage/>} />
                            <Route path="/sub-categories/*" element={<SubcategoryPage/>} />
                            <Route path="/inventory" element={<TechnologyPage/>} />
                            <Route path="/technology-details/*" element={<TechnologyDetailsPage/>} />
                            <Route path="/insight" element={<InsightTabPage/>} />
                            <Route path="/smcmap" element={<SMCMap/>} />
                            <Route path="/admin/*" element={<AdminPage/>} />
                            <Route path="/user" element={<UserPage/>} />
                            <Route path="/report" element={<ExecutiveReportPage/>} />
                            <Route exact path="/" element={<Dashboard/>} />
                        </Routes>
                    :<Navigate to="/changepassword"/>}
                    <Routes>
                        <Route path="/changepassword" element={<ChangePassword/>} />
                    </Routes>
                </React.Fragment>
            :null}
        </div>
    )
}
export default MainContent