import { userService, commonService } from '@Services';
import { urlConstants,dataConstants } from '@Constants';
import { logger } from '@Utilities';
import LZString from 'lz-string'
import Api from '@Api/SMCApi';
export const deviceService = {
    fetchDevices,
    fetchDeviceDetails,
    fetchTechDevices,
    fetchTechDevicesWithLimit,
    getTechDataStartDate,
    getTechComplianceStartDate,
    fetchDevicesInsight,
    deviceCount,
    fetchDevicesWithLimit,
    fetchAllDevices,
};
function logout(){
  let url = window.location.href;
  url = url.split('/')[0];
  if(url.startsWith('http://localhost') || url.startsWith(urlConstants.BASE_URL.split(':')[0]))
  {
    window.location.href = url+'/logout';
  }
}
function deviceCount(technology){
  technology=technology.toLowerCase()
  let url=urlConstants.SITE_URL
  url=url+urlConstants[technology]+urlConstants.USECASE_COMPLIANCE+'/'
// logger.debug(url)
  const token=userService.getToken();
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
    return res.data.usecase_compliance;
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}
function classifyUseCase(complianceScore){
  if (complianceScore>=80&&complianceScore<=100){
    return 'low';
  }
  else if (complianceScore>=70&&complianceScore<80){
    return 'medium';
  }
  else if(complianceScore>=0&&complianceScore<70){
    return 'critical';
  }
  else{
    return 'all';
  }
}
function getTechDataStartDate(technology){
  technology=technology.toLowerCase()
// logger.debug(technology);
  let url=urlConstants.SITE_URL
  url=url+urlConstants[(technology)]
// logger.debug(url);
  const token=userService.getToken();
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
    let date=new Date(res.data[0].time)
    date=commonService.getNowStartDate(date);
    return date;
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}
function getTechComplianceStartDate(technology,store=false){
  technology=technology.toLowerCase()
  let url=urlConstants.SITE_URL
  url=url+urlConstants[technology]+urlConstants.USECASE_COMPLIANCE+'/'
// logger.debug(url)
  const token=userService.getToken();
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
  // logger.debug(res.data.time)
  let date=new Date(res.data.time)
  date=commonService.getNowStartDate(date);
  if(store)
    commonService.storeDate(technology,date)
  localStorage.setItem('techStartDate',JSON.stringify(date))
  return date;
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
      logger.debug(error);
      logout();
    }
    else
      logger.debug(error);
  })
}
function fetchDevices(technology,usecase,type,startDate,endDate,filterBy,systemName,params,page){
  technology = technology.toLowerCase();
  if(!startDate)
  {
  // logger.debug('No Start Date Provided')
    return getTechDataStartDate(technology).then(startDate=>{
      // console.log(startDate)
    // logger.debug('Start Date :',startDate);
    // console.log(commonService.getNextQuarterDate(startDate))
      return getDevices(technology,usecase,type,startDate,commonService.getNextQuarterDate(startDate),filterBy,systemName,params,page);
    })
  }
  return getDevices(technology,usecase,type,startDate,endDate,filterBy,systemName,params,page);
}
function getDevices(technology,usecase,type,startDate,endDate,filterBy,systemName,params,page){
  const currentPageIndex=parseInt(localStorage.getItem('currentPageIndex'));
  const fetchedDevicesTillNow=parseInt(localStorage.getItem('fetchedDevicesTillNow'))
  // const deviceCountOfThisType=parseInt(localStorage.getItem('deviceCountOfThisType'))
  const currentDeviceCount=parseInt(localStorage.getItem('currentDevicesCount'))
  if(type==='all'){
    return fetchTechDevices(technology,usecase,startDate,endDate,filterBy,systemName,params,page).then(res=>{
      if(page==='next'){
      // logger.debug(res.data);
        const updatePageIndex = currentPageIndex + 1;
        const dataLength = res.data.length;
        const updateFetchedDevicesTillNow = fetchedDevicesTillNow + dataLength;
        localStorage.setItem('currentPageIndex',updatePageIndex)
        localStorage.setItem('currentDevicesCount',dataLength)
        localStorage.setItem('fetchedDevicesTillNow',updateFetchedDevicesTillNow)
      // logger.debug(updatePageIndex, dataLength, updateFetchedDevicesTillNow)
      }
      if(page==='prev'){
        localStorage.setItem('currentPageIndex',currentPageIndex-1)
        localStorage.setItem('currentDevicesCount',res.data.length)
        localStorage.setItem('fetchedDevicesTillNow',fetchedDevicesTillNow-currentDeviceCount)
      }
      // logger.debug(res.data,page)
      return res.data
    })
  }
  if(page==='prev'){
    const blockedDevices=JSON.parse(LZString.decompressFromUTF16(localStorage.getItem('blockedDevices')))
    const currentPageIndex=parseInt(localStorage.getItem('currentPageIndex'));
    const fetchedDevicesTillNow=parseInt(localStorage.getItem('fetchedDevicesTillNow'))
    const prevBlock=blockedDevices[currentPageIndex-1]
    localStorage.setItem('currentPageIndex',currentPageIndex-1)
    localStorage.setItem('fetchedDevicesTillNow',fetchedDevicesTillNow-blockedDevices[currentPageIndex].length)
  // logger.debug(blockedDevices[currentPageIndex])
    if(currentPageIndex===1) localStorage.setItem('disablePrev',true)
    return Promise.resolve(prevBlock);
  }
  if(page==='next'){
    const blockedDevices=JSON.parse(LZString.decompressFromUTF16(localStorage.getItem('blockedDevices')))
    const currentPageIndex=parseInt(localStorage.getItem('currentPageIndex'));
    const nextBlock=blockedDevices[currentPageIndex+1]
    const fetchedDevicesTillNow=parseInt(localStorage.getItem('fetchedDevicesTillNow'))

    if(nextBlock.length===10) {
      localStorage.setItem('currentPageIndex',currentPageIndex+1)
      localStorage.setItem('fetchedDevicesTillNow',fetchedDevicesTillNow+nextBlock.length)
      return Promise.resolve(nextBlock)
    }
    if(parseInt(localStorage.getItem('deviceCountOfThisType'))<=fetchedDevicesTillNow+nextBlock.length) {
    // logger.debug(parseInt(localStorage.getItem('deviceCountOfThisType')),fetchedDevicesTillNow+nextBlock.length)
      localStorage.setItem('currentPageIndex',currentPageIndex+1)
      localStorage.setItem('disableNext',true);
      localStorage.setItem('fetchedDevicesTillNow',fetchedDevicesTillNow+nextBlock.length)
      return Promise.resolve(nextBlock)
    }
  }
  return fetchDevicesRecursively(technology,usecase,type,startDate,endDate,filterBy,systemName,params,page,[]).then(res=>{
    let blockedDevices=JSON.parse(LZString.decompressFromUTF16(localStorage.getItem('blockedDevices')))
    const currentPageIndex=parseInt(localStorage.getItem('currentPageIndex'))
    const fetchedDevicesTillNow=parseInt(localStorage.getItem('fetchedDevicesTillNow'))
    let nextBlock=blockedDevices[currentPageIndex+1]
    let prevBlocks=blockedDevices.slice(0,currentPageIndex+1)
    // logger.debug(prevBlocks,nextBlock,res)
    for(let i=0;i<res.length;i++){
      nextBlock.push(res[i]);
      if(nextBlock.length===10){
        prevBlocks.push(nextBlock);nextBlock=[]
      }
      
    }
    if(nextBlock.length>0) prevBlocks.push(nextBlock)
    else prevBlocks.push([])
    blockedDevices=prevBlocks
    localStorage.setItem('blockedDevices',LZString.compressToUTF16(JSON.stringify(blockedDevices)))
    localStorage.setItem('currentPageIndex',(currentPageIndex+1))
    localStorage.setItem('fetchedDevicesTillNow',fetchedDevicesTillNow+blockedDevices[currentPageIndex+1].length)
    // logger.debug(blockedDevices)
    return blockedDevices[currentPageIndex+1];
  })

}
function fetchDevicesRecursively(technology,usecase,type,startDate,endDate,filterBy,systemName,params,page,data){
  return fetchTechDevices(technology,usecase,startDate,endDate,filterBy,systemName,params,page).then(res=>{
    // console.log(res.data)
    if(res.data.length<10){
      localStorage.setItem('disableNext',true)
      // logger.debug(res);
      if(res.status===200)
      { 
        let dData= res.data.filter(device=>classifyUseCase(device[usecase].compliance_score)===type)
        for(let i=0;i<dData.length;i++) data.push(dData[i]);
      }
      // console.log(data)
      return data
    }
    if(res.status===200)
    {
      let devicesData=res.data.filter(device=>classifyUseCase(device[usecase].compliance_score)===type)
      for(let i=0;i<devicesData.length;i++) data.push(devicesData[i]);
    }
    if(data.length>=10) return data;
    return fetchDevicesRecursively(technology,usecase,type,startDate,endDate,filterBy,systemName,params,page,data)
  })
}
function fetchTechDevices(technology,usecase,startDate,endDate,filterBy,systemName,params,page){
  technology=technology.toLowerCase();
  let offset=parseInt(localStorage.getItem('offset'));
  if(page==='next') offset+=10;
  if(page==='prev') offset-=10;
  localStorage.setItem('offset',offset)
  let url=urlConstants.SITE_URL+urlConstants[technology]
  let FILTER_SYSTEM_NAME=commonService.classifySystemName(technology)
  let IMP_FILTER=commonService.classifyIMPFilter(technology)
  url+='?'
  if(usecase)
    url=url+'fields='+IMP_FILTER+usecase
  if(systemName){
    if(filterBy) url=url+'&'+filterBy+'='+systemName
    else url=url+'&'+FILTER_SYSTEM_NAME+'='+systemName
  }
  url+='&offset='+offset+'&'+dataConstants.FILTER_START_DATE+'='+startDate+'&'+
        dataConstants.FILTER_END_DATE+'='+endDate+'&'+params;
  // console.log(url)
// logger.debug(url);
  const token=userService.getToken();
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then((res)=>{
    return res;
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}
function fetchTechDevicesWithLimit(technology,usecase,type,startDate,endDate,filterBy,systemName,params,limit){
  technology=technology.toLowerCase();
  let url=urlConstants.SITE_URL+urlConstants[technology]
  let FILTER_SYSTEM_NAME=commonService.classifySystemName(technology)
  let IMP_FILTER=commonService.classifyIMPFilter(technology)
  url+='?'
  if(usecase)
    url=url+'fields='+IMP_FILTER+usecase
  if(systemName){
    if(filterBy) url=url+'&'+filterBy+'='+systemName
    else url=url+'&'+FILTER_SYSTEM_NAME+'='+systemName
  }
  url+='&'+dataConstants.FILTER_START_DATE+'='+startDate+'&'+
        dataConstants.FILTER_END_DATE+'='+endDate+'&'+params+'&limit='+limit;
// logger.debug(url);
  const token=userService.getToken();
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
    if(usecase&&res.data[0]&&res.data[0].usecase&&res.data[0].usecase.compliance_score) 
      return res.data.filter(device=>classifyUseCase(device[usecase].compliance_score)===type)
    return res.data
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}
function fetchDeviceDetails(technology,startDate,systemName){
  technology=technology.toLowerCase();
  let url=urlConstants.SITE_URL+urlConstants[technology]
  let FILTER_SYSTEM_NAME=commonService.classifyPrimaryKey(technology)
  // let IMP_FILTER=commonService.classifyIMPFilter(technology)
  url=url+'?&'+FILTER_SYSTEM_NAME+'='+systemName
  if(startDate)
  {
    url = url+'&'+dataConstants.FILTER_START_DATE+'='+startDate
  }
  url = url+'&limit=1'
// logger.debug(url);
  const token=userService.getToken();
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
    return res.data
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}
function fetchDevicesInsight(technology,offset,usecase='',startDate='',endDate='',filterBy='',systemName='',params=''){
  if(!startDate) 
  {
    return getTechDataStartDate(technology).then(startDate=>{
      return getDevicesInsight(technology,usecase,startDate,commonService.getNextQuarterDate(startDate),filterBy,systemName,params,offset);
    })
  }
  return getDevicesInsight(technology,usecase,startDate,endDate,filterBy,systemName,params,offset);
}
function getDevicesInsight(technology,usecase,startDate,endDate,filterBy,systemName,params,offset){
  technology=technology.toLowerCase();
// logger.debug(technology);
  let url=urlConstants.SITE_URL+urlConstants[technology]
  let FILTER_SYSTEM_NAME=commonService.classifySystemName(technology)
  let IMP_FILTER=commonService.classifyIMPFilter(technology)
  url+='?'
  if(usecase)
    url=url+'fields='+IMP_FILTER+usecase
  if(systemName){
    if(filterBy) url=url+'&'+filterBy+'='+systemName
    else url=url+'&'+FILTER_SYSTEM_NAME+'='+systemName
  }
  url+='&offset='+offset+'&'+dataConstants.FILTER_START_DATE+'='+startDate+'&'+
        dataConstants.FILTER_END_DATE+'='+endDate+'&'+params;
  logger.debug(url);
  const token=userService.getToken();
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then((res)=>{
    return res;
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}
function fetchDevicesWithLimit(technology,startDate,endDate,limit){
  technology=technology.toLowerCase();
  let url=urlConstants.SITE_URL+urlConstants[technology]
  url+='?'+dataConstants.FILTER_START_DATE+'='+startDate+'&'+
        dataConstants.FILTER_END_DATE+'='+endDate+'&limit='+limit;
// logger.debug(url);
  const token=userService.getToken();
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
    return res.data
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}

function fetchAllDevices(technology){
  return getTechDataStartDate(technology).then(startDate=>{
    return fetchDevicesWithLimit(technology,startDate,commonService.getNextQuarterDate(startDate),999999);
  })
}