import ReactECharts from 'echarts-for-react';


function ScoreGauge2(props)
{
    const name = props.category ?? "ANC";
    const value = props.value ?? null;
    const width = window.screen.width;
    const options = {
        series: [
            {
                type: 'gauge',
                startAngle: 180,
                endAngle: 0,
                center: ['50%', '45%'],
                radius: '90%',
                min: 0,
                max: 100,
                splitNumber: 10,
                axisLine: {
                    lineStyle: {
                        width: 6,
                        color: [
                            [0.33, '#FF6E76'],
                            [0.66, '#FDDD60'],
                            [100, '#7CFFB2']
                        ]
                    }
                },
                pointer: {
                    icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                    length: '20%',
                    width: 10,
                    offsetCenter: [0, '-50%'],
                    itemStyle: {
                        color: 'auto'
                    }
                },
                axisTick: {
                    length: 12,
                    lineStyle: {
                        color: 'auto',
                        width: 2
                    }
                },
                splitLine: {
                    length: 20,
                    lineStyle: {
                        color: 'auto',
                        width: 5
                    }
                },
                axisLabel: {
                    color: '#464646',
                    fontSize: 20,
                    distance: -60,
                    rotate: 'tangential',
                    show: false

                    // formatter: function (value) {
                    //     if (value === 90) {
                    //         return 'Compliance';
                    //     } else if (value === 50) {
                    //         return 'Risky';
                    //     } else if (value === 10) {
                    //         return 'Non Compliance';
                    //     }
                    //     return '';
                    // }
                },
                title: {
                    offsetCenter: [0, '-10%'],
                    fontSize: 20,
                    show: false
                },
                detail: {
                    fontSize: 15,
                    offsetCenter: [0, '-5%'],
                    valueAnimation: true,
                    color: 'auto',
                    formatter: function (value) {
                        return name+': '+value;
                    }
                },
                data: [
                    {
                        value: value,
                        name: name
                    }
                ]
            }
        ]
    };
    return (
        <div>
            {width>1600?
            <ReactECharts 
                option={options}
                style={{
                    width:"100%",
                    height: "500%",
                }} 
            />
            :width>1366?
            <ReactECharts 
                option={options}
                style={{
                    width:"100%",
                    height: "400%",
                }} 
            />
            :<ReactECharts 
                option={options}
                style={{
                    width:"100%",
                    height: "300%",
                }} 
            />}
        </div>
    )
}

export default ScoreGauge2
