import AutomationPage from "./components/AutomationPage/AutomationPage";
import ComplianceMonitoringPage from "./components/ComplianceMonitoringPage/ComplianceMonitoringPage";
import SMAPage from "./components/SMAPage/SMAPage";
import RemoteAccessPage from "./components/RemoteAccessPage/RemoteAccessPage";


function CustomizableInsightPage(props){
    const data = props.data ?? '';
    return (
        <div>
            {data==='compliance_monitoring'?
                <ComplianceMonitoringPage/>
            :data==='sma'?
                <SMAPage/>
            :data==='automation'?
                <AutomationPage/>
            :data==='remote_access'?
                <RemoteAccessPage/>
            :null}
        </div>
    );
}

export default CustomizableInsightPage;