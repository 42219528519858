import React from 'react';
import ReactEcharts from 'echarts-for-react';


const options = {
    xAxis: {
        type: 'category',
        data: ['W1', 'W2', 'W3', 'W4', 'W5']
    },
    yAxis: {
        type: 'value'
        //no. of bots
    },
    grid: {
        top:20,
        left:40,
        right:30,
        bottom:25
    },
    textStyle: {
        color:"#fff"
    },
    series: [{
        data: [10,5,8,4,21],
        type: 'line'
    }]
}

function NumberOfIncidents() {
    return (
        <ReactEcharts
            option={options}
            style={{height:250,width:'100%'}} 
        />
    )
}
export default NumberOfIncidents;