import classes from './Loading.module.css';
import SyncLoader from "react-spinners/SyncLoader";
import ScaleLoader from "react-spinners/ScaleLoader";

export function Loading(props) {
  return (
    <div className={classes.loading}>
      <SyncLoader color={'#36D7B7'} loading={true} size={props.size ?? 15} />
    </div>
  );
}

export function LoadingFull() {
  return (
    <div className={classes.loadingCoverWrapper}>
        <div className={classes.loaderPosition}>
            <ScaleLoader color={'#36D7B7'} loading={true} size={45} />
        </div>
    </div>
  );
}

export function LoadingShimmer(props) {
  let width = props.width ?? '100%';
  let height = props.height ?? '100%'
  return <div 
          className={classes.loadingShimmer} 
          style={{
            width: width,
            height: height,
            ...props.styles
          }}></div>
}