export const dataConstants = {
  DATA_REQUEST: 'DATA_FETCH_REQUEST',
  DATA_SUCCESS: 'DATA_FETCH_SUCCESS',
  DATA_FAILURE: 'DATA_FETCH_FAILURE',
  WEIGHTAGE_SET_REQUEST: 'WEIGHTAGE_SET_REQUEST',
  WEIGHTAGE_SET_SUCCESS: 'WEIGHTAGE_SET_SUCCESS',
  WEIGHTAGE_SET_FAILURE: 'WEIGHTAGE_SET_FAILURE',
  WEIGHTAGE_GET_REQUEST: 'WEIGHTAGE_GET_REQUEST',
  WEIGHTAGE_GET_SUCCESS: 'WEIGHTAGE_GET_SUCCESS',
  WEIGHTAGE_GET_FAILURE: 'WEIGHTAGE_GET_FAILURE',
  USECASE_DATA_REQUEST:'USECASE_DATA_REQUEST',
  USECASE_DATA_SUCCESS:'USECASE_DATA_SUCCESS',
  USECASE_DATA_FAILURE:'USECASE_DATA_FAILURE',
  FILTER_START_DATE:'startDate',
  FILTER_END_DATE:'endDate',
  FILTER_SYSTEM_NAME:'system_name',
  FILTER_NODE_NAME:'nodename',
  FILTER_INCIDENT_NAME:'incident_id',
  FILTER_LOCATION:'location',
  FILTER_INSTANCE_NAME:'instance_name',
  FILTER_BUCKET_NAME:'bucket_name',
  FILTER_ENGINEER_NAME:'engineerName',
  FILTER_CMDB_NAME:'name',
  locationList:["0"],
  
  infraMap:{
    "Antivirus":['antivirus-symantec',"Mcafee ENS","Trend Micro","Microsoft",'av-mcafee'],
    
  },
  technologyWiseDeviceType:{
    'load-barracuda':'Device', 
  },
  technologyCategorization:{
    'loadbalancer-barracuda':['load_balancer','system'], 
    // 'av-carbonblack':['antivirus','system'], 
    // 'load-f5':['load_balancer','system'], 
    'av-mcafee':['antivirus','system'], 
    // 'av-microsoft':['antivirus','system'], 
    // 'encryption-microsoft':['encryption','system'], 
    // 'av-symantec':['antivirus','system'], 
    // 'antivirus-microsoft': ['Antivirus','system'], 
    's3-aws': ['cloud_security','system'], 
    'ec2-aws': ['cloud_security','system'],
    'firewall-skyboxsecurity': ['firewall','system'],
    // 'ticketing-servicenow': ['ticketing','system'],
    'monitoring-solarwinds': ['monitoring','system'],
    // 'cmdb-servicenow': ['inventory','system'],
    'panorama-paloaltonetworks': ['firewall','system'],
    'firewallanalyzer-firemon': ['firewall','system'],
    'vulnerabilitymanagement-tenable':['vuln_mgmt','system'],
    'secureinternetgateway-zscaler': ['proxy', 'system'],
    'waf-cloudflare': ['waf', 'system'],
  },
  technologyKnownAs:{
    's3-aws': 'S3 AWS',
    'ec2-aws': 'EC2 AWS',
    'firewall-skyboxsecurity': 'FWA Skybox',
    'antivirus-microsoft': 'AV Microsoft',
    'monitoring-solarwinds': 'Solarwinds',
    // 'ticketing-servicenow': 'Service Now',
    'cmdb-servicenow': 'CMDB',
    'av-mcafee': 'AV McAfee',
    'panorama-paloaltonetworks': 'FW Panorama',
    'loadbalancer-barracuda': 'LB Barracuda',
    'firewallanalyzer-firemon': 'FWA Firemon',
    'vulnerabilitymanagement-tenable':'Vuln.Mg. Tenable',
    'incidents-servicenow': 'Incidents',
    'secureinternetgateway-zscaler': 'ZIA ZScaler',
    'waf-cloudflare': 'WAF Cloudflare',
  },
  technologyPrimaryKey:{
    'ec2-aws':'instance_name',
    's3-aws':'bucket_name',
    'ticketing-servicenow':'engineer_name',
    'dlpdiscover-symantec':'incident_id',
    'dlpendpoint-symantec':'incident_id',
    'people-people':'username',
    'dlp-symantec':'ip_address',
    'av-mcafee':'nodename',
    'cmdb-servicenow':'name',
    'vpn-pulsesecure':'user_name',
    'umbrella-cisco':'organization_id',
    'centralmanagement-fireeye':'alert_id',
    'nsm-mcafee':'domain_name' ,
    'hosts-crowdstrike':'hostname',
    'secureinternetgateway-zscaler': 'system_name',
    'waf-cloudflare': 'account_id',
  },
  downloadUsecase:{
    "av-mcafee": ["signature_version", "agent_version", "product_version"],
    "dlp-mcafee": ["dlp_product_version"],
    "hosts-crowdstrike": ["agent_version"],
    "sccmhosts-microsoft": ["client_version"],
    "hosts-zscaler": ["client_version"],
    "bitlockerencryption-microsoft": ["agent_version"],
  },
  deviceCountMap:{
    'cmdb-servicenow':'object_count',
    'ticketing-servicenow':"engineer's_count"
  },
  impUseCaseDetails:{
    'state': 'Instance Count',
    'encryption': 'Device Encrypted',
    'cpu_options': 'Overall CPU',
    'storage': 'Storage Count',
    'antispyware_signature_age': 'Signature Compliance',
    'signature_version': 'Signature Compliance',
    'unused_rules': 'Unused Rules',
    'managed_state': 'Endpoint Coverage',
    'static_severity': 'Static Severity',
    'risk_factor': 'Risk Factor',
  },
  impFilterMap:{
    'firewallanalyzer-firemon':',time,system_name,ip_address,regulatory_compliance,unauthorized_changes,disabled_rules,redundant_rules,shadowed_rules,unused_rules,total_rules,',
    // 'loadbalancer-f5':',ntp_service,memory_used,available_disk_space,nat_enabled,node_logging_disabled,snat_enabled,total_nodes,total_memory,total_disk_space,time,system_name,ip_address,',
    's3-aws':',time,bucket_name,ip_address,',
    'ec2-aws':',time,instance_name,instance_id,ip_address,',
    // 'ticketing-servicenow':',work_resolution_time,total_resolution_time,number_of_tickets_assigned,time,engineer_name,ip_address,',
    // 'dlpdiscover-symantec':',time,incident_id,ip_address,',
    // 'dlpendpoint-symantec':',time,incident_id,ip_address,',
    'people-people':',time,username,',
    // 'dlp-symantec':',time,ip_address,',
    // 'cmdb-servicenow':',time,name,ip_address,',
    // 'firewallmanagement-checkpoint':',time,system_name,ip_address,total_rules,',
    'av-mcafee':',time,nodename,ip_address,',
    'panorama-paloaltonetworks':',time,system_name,ip_address,total_rules,',
    // 'vpn-pulsesecure':',time,user_name,ip_address,login_node,events,agent_type,',
    'firewall-skyboxsecurity':',time,system_name,ip_address,total_access_rules,',
    // 'umbrella-cisco':',time,total_users,organization_id,',
    // 'centralmanagement-fireeye':',time,alert_name,alert_id,attack_time,',
    // 'nsm-mcafee':',time,domain_name,id,',
    // 'hosts-crowdstrike': ',time,hostname,',
    // 'antivirus-microsoft': ',time,system_name,antivirus_signature_age,antispyware_signature_age,',
    'monitoring-solarwinds': ',time,system_name,ip_address,node_status,node_availability,',
    'vulnerabilitymanagement-tenable':',time,system_name,ip_address,',
    'loadbalancer-barracuda':',time,system_name,ip_address,',
    'waf-cloudflare': ',time,account_id,account_name,total_rules,',
  },
  usecaseNameMap : {
    PCI: 'PCI',

  },
  shorthandErrorMap: {
    'av': 'AV',
    'os': 'OS',
    'ip': 'IP',
    'aws': 'AWS',
    'samr': 'SAMR',
    'cpu': 'CPU',
    'ec2': 'EC2',
    's3': 'S3',
    'sma': 'SMA',
    'iam': 'IAM',
    'hw': 'HW',
    'rtp': 'RTP',
    'cmdb': 'CMDB',
    'zt': 'ZT',
    'sw': 'SW',
    'ap': 'AP',
    'oas': 'OAS',
    'sla': 'SLA',
    'url': 'URL',
    'urls': 'URLs'
  },
  descriptionMap:{
    "device_health":"Device Health Compliance",
    "AWS Location":"Checks data from the admin module and check if locations are in the designated area", 
    "attack surface": "Checks data from the admin module and check if locations are in the designated area",
    "encryption_state": "Checks whether encryption is available or not",
    "lifecycle":"Checks if lifecycle is disabled or enabled",
    "replication":"Checks if replication is enabled or disabled",
    "versioning":"Checks if versioning is enabled or disabled",
    "policy": "Checks whether policy is allowed or not, if the policy is available then it will check for the version of policy",
    "storage":"Checks whether the size is not above the mentioned size from the admin value",
    "CPU Options": "Checks with the minimum number of cores an instance should have from the admin module",
    "monitoring": "Checks if monitoring is disabled or enabled",
    "security_details": "Checks if any unauthorised security group is added to the instance or not",
    "state": "Checks for the state whether it is running or stopped",
    "vpc_details": "Checks whether a matching VPC id is there in the admin center",
    "critical configuration":"Firewalls number of critical configurations. Like Interface details",
    "Old Version":"Checking the version from vendor site and compare the existing Device version.",
    "Threat Signature Compliance":"Check the date of last update in system info",
    "risky_rules":"Firewall rules with app and service set to any.",
    "unused_rules":"Firewall Rules which are not in used.",
    "Firewall availability":"Unknown and known shutdown or unavailability of firewall (Using show system info)",
    "hi_status":"Firewall logs forwarded",
    "logging_enabled":"Firewall logs forwarded",
    "banner_enabled":"Banner enabled",
    "Unsecured Administrator Access":"To check if only secure protocols are enabled.",
    "untagged_rules":"Firewall are tagged and description is given about why and by whom Ticket created.",
    "Threat profile Enabled":"Policy configured with Threat profile ( Applied on policy )",
    "App ID Configuration Enabled":"Policy configured with App ID",
    "Critical System Issues":"Check all critical system issue",
    "High Risk Applications":"All aps with risk 5 and 4 are high risk, 3and 2 are medium and 1 is low.",
  },
};