import React from "react";
import {MapData} from './jsonData/world-110m';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker
} from "react-simple-maps";

// const geoUrl = "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";
const geoUrl = MapData;

const markers = [
  { markerOffset: -5, name: "Asia Pacific (Singapore)", coordinates: [ 103.8198, 1.3521] },
  { markerOffset: 12, name: "US East (N. Virginia)", coordinates: [ -76.2423, 37.2639] },
  { markerOffset: -5, name: "US East (Ohio)", coordinates: [ -82.9071, 40.4173] },
  // { markerOffset: 12, name: "Asia Pacific SouthEast-1a", coordinates: [ -88.0167, 15.4761] },
  // { markerOffset: -5, name: "Asia Pacific SouthEast-1", coordinates: [ -54.5281, -20.4348 ] },
  // { markerOffset: -5, name: "US East-2", coordinates: [ -81.8223, 26.7123] },
];

const ReactMap = () => {
  return (
    <div>
        <ComposableMap>
            <ZoomableGroup zoom={1.5}>
                <Geographies geography={geoUrl}>
                    {({ geographies }) =>
                    geographies.map(geo => (
                        <Geography key={geo.rsmKey} geography={geo} />
                    ))
                    }
                </Geographies>
                {markers.map(({ name, coordinates, markerOffset }) => (
                    <Marker key={name} coordinates={coordinates}>
                        <circle r={3} fill="#F00" stroke="#fff" strokeWidth={.5} />
                        <text
                            textAnchor="middle"
                            y={markerOffset}
                            style={{ fill: "#fff" }}
                            fontSize=".7rem"
                        >
                        {name}
                        </text>
                    </Marker>
                ))}
            </ZoomableGroup>
        </ComposableMap>
    </div>
  );
};

export default ReactMap;