// import React from 'react';
import ReactEcharts from 'echarts-for-react';

function ScanningCompliance(props){

    const appList = {
        'Zap':{
            'Yes': [
                'Adm Products',
                'AIML Pipeline',
                'AMADAS Server',
                'Comet Packaging',
                'CS Space',
                'EffortTracking',
            ],
            'No': [
                'Harp NG',
                'SSF Interfaces Java Dev',
                'Crowd Comfort1',
                'R&DIT EMT API POC'
            ]
        },
        'Sonar':{
            'Yes': [
                'Adm Products',
                'AIML Pipeline',
                'AMADAS Server',
                'Comet Packaging',
            ],
            'No': [
                'CS Space',
                'EffortTracking',
                'Harp NG',
                'SSF Interfaces Java Dev',
                'Crowd Comfort1',
                'R&DIT EMT API POC'
            ]
        },
        'Blackduck':{
            'Yes': [
                'Adm Products',
                'AIML Pipeline',
                'AMADAS Server',
                'Comet Packaging',
                'CS Space'
            ],
            'No': [
                'EffortTracking',
                'Harp NG',
                'SSF Interfaces Java Dev',
                'Crowd Comfort1',
                'R&DIT EMT API POC'
            ]
        }
    }

    const option = {
        xAxis: {
            type: 'category',
            data: ['Zap', 'Sonar', 'Blackduck'],
            axisLabel:{
                textStyle: {
                    color: '#ffffff'
                }
            },
            axisLine:{
                lineStyle:{
                        color:'white'
                }
            }
        },
        yAxis: {
            type: 'value'
        },
        textStyle: {
            color:"#fff"
        },
        tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}%'
        },
        
        series: [
            {
                name: 'Yes',
                type: 'bar',
                stack: 'first',
                label: {
                    show: true,
                    position: 'inside',
                    formatter: '{c}%'
                },
                data: [
                    {
                        value: 60,
                        itemStyle: {
                            color: 'rgb(42, 187, 67)'
                        }
                    },
                    { 
                        value: 40,
                        itemStyle: {
                            color: 'rgb(42, 187, 67)'
                        }
                    }, 
                    {
                        value: 50,
                        itemStyle: {
                            color: 'rgb(42, 187, 67)'
                        }
                    }
                ],
            },
        {
            name: 'No',
            type: 'bar',
            stack: 'first',
            data: [
                {
                    value: 40,
                    itemStyle: {
                        color: '#e63c3c'
                    }
                },
                { 
                    value: 60,
                    itemStyle: {
                        color: '#e63c3c'
                    }
                }, 
                {
                    value: 50,
                    itemStyle: {
                        color: '#e63c3c'
                    }
                }
            ],
        }
        ]
    };
               

    const onChartClick=(params)=>{
        props.openApplicationList(appList[params.name][params.seriesName]);
    }

    return(
        <ReactEcharts 
            style={{height:250,width:'100%'}} 
            option={option} 
            onEvents={{'click':onChartClick}}
        />
    )
}
export default ScanningCompliance