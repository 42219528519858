import React, {useState} from 'react';
import ApplicationModal from './ApplicationModal/ApplicationModal';
import MFACompliance from './MFACompliance/MFACompliance';
import ScanningCompliance from './ScanningCompliance/ScanningCompliance';
import VulnerabilitySummary from './VulnerabilitySummary/VulnerabilitySummary';
import MindMap from './MindMap/MindMap';
import SDElements from './SDElements/SDElements';
import Card from '@CommonComponents/Card/Card';
import {Container, Row, Col} from 'react-bootstrap';

function ComplianceMonitoringPage(props){
    const [applicationModal, setApplicationModal] = useState(false);
    const [applicationsList, setApplicationsList] = useState();
    const chartName = {
        fontSize:"1.3rem",
        padding: "5px 0 5px 10px",
        boxShadow:"0 1px 5px #000",
        position:"relative"
    }

    function openApplicationModal(applications){
        setApplicationModal(true);
        setApplicationsList(applications);
    }
    function closeApplicationModal(){
        setApplicationModal(false);
    }

    return (
        <React.Fragment>
            <Container style={{padding:0}} fluid>
                <Row style={{marginLeft:'0px',marginBottom:"15px",marginRight:'5px'}} >
                    <Col md={3}>
                        <Card>
                            <div className={chartName}>
                                <MFACompliance openApplicationList={openApplicationModal} />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>MFA Compliance</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <div className={chartName}>
                                <SDElements openApplicationList={openApplicationModal} />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>SD Elements</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <div className={chartName}>
                                <ScanningCompliance openApplicationList={openApplicationModal} />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>Scanning Compliance</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <div className={chartName}>
                                <VulnerabilitySummary openApplicationList={openApplicationModal} />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>Vulnerability Summary</h4>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row style={{marginLeft:'0px',marginBottom:"15px",marginRight:'5px'}} >
                    <Col md={12}>
                        <Card>
                            <div className={chartName}>
                                <MindMap />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>Business Risk Review</h4>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {applicationModal && applicationsList?
                <ApplicationModal
                    open={applicationModal} 
                    close={closeApplicationModal} 
                    applications={applicationsList}
                />
            :null}
        </React.Fragment>
    );
}


export default ComplianceMonitoringPage;