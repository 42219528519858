import React from "react";
import {Container, Row, Col} from 'react-bootstrap';
import ScoreGauge from '@CommonComponents/ScoreGauge/ScoreGauge';
import ScoreArrow from '@CommonComponents/ScoreArrow/ScoreArrow';
import SubcategoryGauge from './SubcategoryGauge/SubcategoryGauge';
import UsecaseRow from './UsecaseRow/UsecaseRow';
import { dataService,commonService } from '@Services';
import { useEffect, useState } from 'react';
import LoadingFull from '@CommonComponents/Loading/LoadingFull';
import styles from '@Utilities/scrollbar.module.css';
import { useNavigate } from "react-router-dom";

function SubcategoryPage(props) {

    const navigate = useNavigate();
    const [catName, setCatName] = useState();
    const [subCatName, setSubCatName] = useState();
    const [catScore, setCatScore] = useState();
    const [subCatScore, setSubCatScore] = useState();
    const [subCatScoreChange, setSubCatScoreChange] = useState();
    const [useCaseData, setUseCaseData] = useState();

    function showCategories() {
        navigate('/categories');
    }
    useEffect(()=>{
        let startDate = commonService.getNowStartDate();
        let curURL = window.location.href;
        curURL = curURL.split("/");
        const subCat = curURL[curURL.length-1].replaceAll("%20"," ");
        setSubCatName(subCat);
        dataService.fetchDataWithDate(startDate).then((categoryData)=>{
          // console.log(categoryData);
            Object.keys(categoryData.Technology).map((catdata)=>{
                if(categoryData.Technology[catdata].sub_categories.hasOwnProperty(subCat))
                {
                    setCatName(catdata.split(' ')[1]);
                    setCatScore(categoryData.Technology[catdata].score);
                    setSubCatScore(categoryData.Technology[catdata].sub_categories[subCat]);
                    setSubCatScoreChange(categoryData.Technology[catdata].changes[subCat]);
                }
                return 0;
            })
        });
        dataService.fetchSubCategoryData(subCat,'',startDate,false).then((res)=>{
            let techUseCases = [];
            let c=-1;
            const technologyLen = Object.keys(res).length - 1;
            Object.keys(res).map((techs,index)=>{
                const techScr = parseInt(res[techs].score);
                let useCases = [];
                dataService.latestTechCompliance(techs).then((response,j)=>{
                    c++;
                    const data = response.data.usecase_compliance;
                    const usecaseLen = Object.keys(res[techs].usecases).length - 1;
                    Object.keys(res[techs].usecases).map((usecase,i)=>{
                        const usecaseDeviceCount = data[usecase].device_count;
                        const useCaseData = {
                            technologyName: techs,
                            useCaseName: usecase,
                            useCaseDeviceData: usecaseDeviceCount
                        }
                        useCases.push(useCaseData);
                        if(technologyLen === c && usecaseLen === i)
                        {
                          // console.log(techUseCases);
                            setUseCaseData(techUseCases);
                        }
                        return 0;
                    });
                })
                const techUseCase = {
                    techName: techs,
                    techScore: techScr,
                    useCaseData: useCases
                }
                techUseCases.push(techUseCase);
                return 0;
            });
        });
    },[]);
    return (
        <Container fluid>
            <Row 
                style={{
                    width:"100%", 
                    height:"100%",
                }} 
                
            >
                <Col 
                    md={2}
                    style={{
                        borderRight: "1px dashed #ffffff33",
                        padding:0,
                        marginLeft:'-5px',
                    }}
                >
                    {catName && catScore && subCatName && subCatScore?
                    <React.Fragment>
                    <div onClick={showCategories}>
                        <ScoreGauge 
                            value={parseInt(catScore)} 
                            disableText={false}
                            category={catName}
                            style={{
                                width: 210
                            }}
                        />
                    </div>
                    <ScoreArrow 
                        score={subCatScore+"%"} 
                        subcategory={subCatName}
                        change={subCatScoreChange} 
                    /> 
                    </React.Fragment>:null}
                </Col>
                <Col 
                    md={10}
                    className={styles.scrollbar_smc} 
                    style={{
                        height: "100%",
                        overflowY:"auto"
                    }}
                >
                    {/* Map API data into this row. */}
                    {useCaseData?
                        <React.Fragment>
                            {useCaseData.map( (useCase,index) =>(
                                <Row 
                                     
                                    key={index} 
                                    style={{
                                        height:"fit-content", 
                                        overflow:"hidden",
                                        borderBottom: "1px dashed #ffffff44",
                                        marginTop:"20px"
                                    }}
                                >
                                    <Col md={3} style={{borderRight:"1px dashed #ffffff44"}}>
                                        <div style={{marginTop: "50px"}}>
                                            <SubcategoryGauge score={useCase.techScore} name={useCase.techName} />
                                        </div>
                                    </Col>
                                    <Col md={9}>
                                        <UsecaseRow data={useCase.useCaseData} technologyName={useCase.techName}/>
                                    </Col>
                                </Row>
                            ))}
                        </React.Fragment>
                    :<LoadingFull />}
                </Col>
            </Row>
        </Container>
    )
}
export default SubcategoryPage