import React,{useState} from 'react';
import styles from "@Utilities/scrollbar.module.css";
import { smcAdminService } from '@Services';
import classes from './technology.module.css';
import Button from 'react-bootstrap/Button';
import {FaCheckCircle} from 'react-icons/fa';

function TechnologyWeightagePage(props){
    const data = props.data;
    const [payload, setPayload] = useState(data);
    const [validationRequiredFlag, setValidationRequiredFlag] = useState(false);
    const [changedField, setChangedField] = useState();
    const [changedValue, setChangedValue] = useState();
    const [validationFailed, setValidationFailed] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState(false);
    
    function handleInputFieldChange(event){
      // console.log(event.target.id, event.target.value);
        if(event.target.value)
        {
            setChangedField(event.target.id);
            setChangedValue(event.target.value);
            setValidationRequiredFlag(true);
        }
        else
        {
            setValidationRequiredFlag(false);
        }
    }
    function validateInputFieldChange(event){
        // console.log(validationRequiredFlag, changedField, changedValue);
        if(validationRequiredFlag){
            let toEditPayload = payload;
            toEditPayload[changedField] = parseInt(changedValue);
          // console.log(toEditPayload);
            let totalScore = 0;
            Object.keys(toEditPayload).map((tech)=>{
                const score = toEditPayload[tech];
                totalScore+=score;
                return 0;
            });
            if(totalScore===100)
            {
                setPayload(toEditPayload);
                setValidationFailed(false);
            }
            else
            {
                alert("Please Make Sure, Total Score Should be Equal to 100!!!");
                setPayload(toEditPayload);
                setValidationFailed(true);
            }
            setValidationRequiredFlag(false);
        }
    }
    function handleSubmit(){
        if(validationFailed)
        {
            alert("Total Score Should be Equal to 100, to procceed with Submit!!!");
        }
        else
        {
            const submitPayload={
                tech_weightage: payload
            }
          // console.log(submitPayload);
            smcAdminService.setWeightage('tech',submitPayload).then((response)=>{
              // console.log(response);
                if(response.status === 201)
                    setSubmissionStatus(true);
            });
        }
    }
    
    return(
        <div className={styles.scrollbar_smc}>
           {data?
            <React.Fragment>
                <div style={{maxWidth: '700px'}}>
                    {Object.keys(data).map((technologies, i)=>(
                        <div className={classes.inputField} key={i}>
                            <label htmlFor={technologies}>{technologies} :</label>
                            <input
                            type="number"
                            id={technologies}
                            placeholder={data[technologies]}
                            onChange={handleInputFieldChange}
                            onBlur={validateInputFieldChange}
                            />
                        </div>
                    ))}
                    <div className={classes.right}>
                        {submissionStatus?<FaCheckCircle size={35}/>:null}
                        <Button variant='secondary' color='primary' 
                            size='small' onClick={handleSubmit} >
                                    Submit
                        </Button>
                    </div>
                </div>
            </React.Fragment>
            :null}
        </div>
    );
}

export default TechnologyWeightagePage;