export const logger = {
  debug,
  clear,
  warn,
  error,
};
function error(msg){
  console.error('%c'+msg, 'font-size:1em');
}
function warn(msg){
  console.warn('%c'+msg, 'font-size:1em');
}
function debug(msg){
  console.log('%c'+msg, 'color:cyan;font-size:1em');
}
function clear(){
  console.clear();
}