import ReactECharts from 'echarts-for-react';

function Chart(props) {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  let last7Days = [];
  let xAxisData = [];
  let seriesData = [ 0, 0, 0, 0, 0, 0, 0 ];
  if(props.data)
  {
    const propsData = props.data;
    for (let i=6; i > -1; i--) {
        let date = new Date();
        date.setUTCDate(date.getUTCDate() - i);
        last7Days.push(date);
        xAxisData.push(days[date.getDay()]);
    }
    for (const [ , value] of propsData.entries()) {
      const valueDate = new Date(value.time);
      const valueComplianceScore = value.score;
      for (const [j, storedDate] of last7Days.entries()) {
        if(storedDate.getUTCDate() === valueDate.getUTCDate())
        {
          seriesData[j] = valueComplianceScore.toFixed(2);
        }
      }
    }
  }
    const options = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Storage'],
          textStyle: {
            color: '#cacaca',
            fontFamily: 'monospace',
            fontSize: '12'
          },
          show: false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '15%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData,
          axisLine: {
            lineStyle: {
                color:'white'
            }
          }
        },
        yAxis: {
          type: 'value',
          max: 100,
          axisLine: {
            lineStyle: {
                color:'white'
            }
          }
        },
        series: [
          {
            name: 'Overall Compliance',
            type: 'line',
            data: seriesData
          }
        ]
    };

    return (
        <ReactECharts 
            option={options}
            style={{width:"100%", height:"100%"}}
        />
    )
}
export default Chart