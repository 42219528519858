import Modal from 'react-bootstrap/Modal';

const UpgradeSuggestion = (props) =>{
    return(
        <Modal
            show={props.status}
            onHide={() => props.setStatus(false)}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable={true}
            >
            <Modal.Header style={{backgroundColor: "#fff"}} closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Feature Not Available
                </Modal.Title>
            </Modal.Header>
            <Modal.Body  style={{backgroundColor: "#fff"}}>
                Please Upgrade to SMC Standard Version, to get access to Device Level Drill Down, Search or Many other exciting Offerings.
            </Modal.Body>
        </Modal>
    );
}

export default UpgradeSuggestion;