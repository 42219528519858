import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/dist/echarts.js';


function TrenologyTrendChart(props) {
    const techName = props.name ?? "Technology";
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let xAxisData = [];
    let complianceData = [];
    if(props.data)
    {
        const propsData = props.data;
        // console.log(props.data);
        for (const [ , value] of propsData.entries()) {
            // console.log(value);
            const date = new Date(value.time);
            xAxisData.push(days[date.getDay()]);
            complianceData.push(value.technology_compliance);
        }
        xAxisData = xAxisData.reverse();
        complianceData = complianceData.reverse();
        // console.log(xAxisData);
    }
    const options = {
        color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
        textStyle: {
            color: "#fff"  
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        toolbox: {
        },
        grid: {
            left: '1%',
            right: '4%',
            bottom: '3%',
            top: '10%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: xAxisData
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            {
                name: techName,
                type: 'line',
                stack: 'someStack',
                smooth: true,
                lineStyle: {
                    width: 0
                },
                showSymbol: false,
                areaStyle: {
                    opacity: 0.8,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: 'rgba(128, 255, 165)'
                    }, {
                        offset: 1,
                        color: 'rgba(1, 191, 236)'
                    }])
                },
                emphasis: {
                    focus: 'series'
                },
                data: complianceData
            }
        ]
    };

    return (
        <ReactECharts 
            option={options}
            style={{width:"100%", height:"100%"}}
        />
    )
}
export default TrenologyTrendChart