import React,{useEffect, useState} from 'react';
import {Loading} from '@CommonComponents/Loading/Loading';
import FunnelChart from '@CommonComponents/ECharts/FunnelChart/FunnelChart';
import PieRoseTypeChart from '@CommonComponents/ECharts/PieChart/PieRoseTypeChart';
import DoughnutChart from '@CommonComponents/ECharts/DoughnutChart/DoughnutChart';
import BarChart from '@CommonComponents/ECharts/BarChart/BarChart';
import Card from '@CommonComponents/Card/Card';
import { annotateService, commonService } from '@Services';
import { Container, Row, Col } from 'react-bootstrap';
import UpgradeSuggestion from '@CommonComponents/UpgradeSuggestion/UpgradeSuggestion';


function InsightTechnology(props){
    const [techAnnotationData, setTechAnnotationData] = useState();
    const [openUpgradePOP, setOpenUpgradePOP] = useState(false);
    const technologyName = props.techName;

    useEffect(()=>{
        annotateService.getTechStartDate(technologyName).then((startDate)=>{
            annotateService.annotate(technologyName,startDate).then((res)=>{
                let annotateData = []
                Object.keys(res).map((columnName,index)=>{
                    if(columnName !== "time")
                    {
                        const data={
                            name: columnName,
                            data: res[columnName]
                        }
                        annotateData.push(data);
                    }
                    return 0;
                });
                let techData = []
                const chunkSize = 4;
                for (let i = 0; i < annotateData.length; i += chunkSize) {
                    const chunk = annotateData.slice(i, i + chunkSize);
                    techData.push(chunk)
                }
                setTechAnnotationData(techData);
            });
        });
    },[technologyName]);

    function handleChartClick(){
        setOpenUpgradePOP(true);
    }

    return (
        <div>
             {techAnnotationData?
                <Container fluid>
                    {techAnnotationData.map( (tech,i) => (
                        <Row key={i} style={{ width: "100%", marginBottom: "1%" }}>
                        {tech.map((usecase,ind) => (
                            <Col lg={3} key={ind}>
                                <Card style={{height:'20px'}}>
                                    {ind%4===0?<FunnelChart center={['50%','50%']} handleClick={handleChartClick} name={usecase.name} data={usecase.data}/>
                                    :ind%4===1?<PieRoseTypeChart center={['50%','50%']} handleClick={handleChartClick} name={usecase.name} data={usecase.data}/>
                                    :ind%4===2?<DoughnutChart center={['50%','50%']} handleClick={handleChartClick} name={usecase.name} data={usecase.data}/>
                                    :<BarChart center={['50%','50%']} handleClick={handleChartClick} name={usecase.name} data={usecase.data}/>}
                                </Card>
                            </Col>))
                        }
                        </Row>
                    ))}
                </Container>
            :<Loading/>}
            <UpgradeSuggestion status={openUpgradePOP} setStatus={setOpenUpgradePOP}/>
        </div>
  );
}
export default InsightTechnology;