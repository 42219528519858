import ReactECharts from 'echarts-for-react';
import { commonService } from '@Services';

function DoughnutChart(props) {

    const name = props.name ? commonService.capitalizeletterWith_(props.name) : "ANC"
    let data = null
    const dummyData = [
        {value: 1048, name: 'A'},
        {value: 735, name: 'B'},
        {value: 580, name: 'C'},
        {value: 484, name: 'D'},
        {value: 300, name: 'E'}
    ]
    if(props.data)
    {
        const propsData = props.data;
        const propName = props.name;
        let arrangedData = [];
        for (const [ , value] of propsData.entries()) {
            const chartDatam = {
                name: String(value[propName]),
                value: value.device_count
            }
            arrangedData.push(chartDatam);
        }
        data = arrangedData;
    }

    const options = {
        tooltip: {
            trigger: 'item'
        },
        series: [
            {
                name: name,
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '40',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: data ?? dummyData
            }
        ]
    };
    function handleChartClick(){
        props.handleClick();
    }
    return (
        <div>
            <ReactECharts 
                option={options}
                style={{
                    width:"100%",
                    height:"250px"
                }} 
                onEvents={{'click':handleChartClick}}
            />
            <p 
                style={{
                    textAlign:"center", 
                    fontSize:".8em"
            }}>{name}</p>
        </div>
    )
}



export default DoughnutChart