import React, { useState, useEffect } from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { smcAdminService } from '@Services';
import TechnologyWeightagePage from "./components/TechnologyWeightagePage";
import styles from '@Utilities/scrollbar.module.css';
import LoadingFull from '@CommonComponents/Loading/LoadingFull';
import SubcatWeightagePage from "./components/SubcatWeightagePage";
import UsecaseWeightagePage from "./components/UsecaseWeightagePage";
import tabStyles from '@Utilities/tabs.module.css';

function WeightagePage(){
    const [techTabData, setTechTabData] = useState();
    const [subcatTabData, setSubcatTabData] = useState();
    const [usecaseTabData, setUsecaseTabData] = useState();
    useEffect(()=>{
        smcAdminService.getWeightage('tech').then((response)=>{
            setTechTabData(response.data.tech_weightage);
        });
        smcAdminService.getWeightage('subcat').then((response)=>{
            setSubcatTabData(response.data.subcat_weightage);
        });
        smcAdminService.getWeightage('usecase').then((response)=>{
            setUsecaseTabData(response.data.usecase_weightage);
        });
    },[]);
    return (
        <div style={{height:"100%"}} className={styles.scrollbar_smc}>
            {techTabData && subcatTabData && usecaseTabData ?
            <Tabs
                id="controlled-tab-example"
                defaultActiveKey="tech"
                className="mb-3 border-0"
            >
                <Tab 
                    eventKey="tech" 
                    style={{height:"100%"}} 
                    title="Technology Weightage"
                    tabClassName={tabStyles.tabNavLink}
                >
                    <TechnologyWeightagePage data={techTabData}/>
                </Tab>
                <Tab 
                    eventKey="subcat" 
                    style={{height:"100%"}} 
                    title="Sub Category Weightage"
                    tabClassName={tabStyles.tabNavLink}
                >
                    <SubcatWeightagePage data={subcatTabData}/>
                </Tab>
                <Tab 
                    eventKey="usecase" 
                    style={{height:"100%"}} 
                    title="Usecase Weightage"
                    tabClassName={tabStyles.tabNavLink}
                >
                    <UsecaseWeightagePage data={usecaseTabData}/>
                </Tab>
            </Tabs>
            :<LoadingFull/>}
        </div>
    );
}

export default WeightagePage;