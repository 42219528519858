import React from 'react';
import {TiTickOutline} from 'react-icons/ti';
import {CgClose} from 'react-icons/cg';
import {BsCloud} from 'react-icons/bs';
import classes from './ScoreBoxes/ScoreBoxes.module.css';
import {Col} from 'react-bootstrap';

function Scores(props){

    return(
        <React.Fragment>
            <Col md={3} style={{marginBottom:"10px"}}>
                <div style={{width:"105%",height:"80%",float:"left",position:"relative",marginRight:"2%",display:'flex',justifyContent: 'space-between',backgroundColor:'#3b9bd8',padding:10,textShadow:'1px 1px 3px black'}}>
                <BsCloud className={classes.crossTickIcon} style={{fontSize:"80px",color:'rgba(255,255,255,0.4)'}}></BsCloud>
                <div style={{position:"absolute", right:'3%',fontSize:22}}>
                    <div style={{display: 'flex',justifyContent: 'space-between'}}>
                        <div>EC2 &nbsp;</div>
                        <div>67</div>
                    </div>
                    <div style={{display: 'flex',justifyContent: 'space-between',marginTop:10}}>
                        <div>S3 &nbsp;</div>
                        <div>67</div>
                    </div>
                </div>  
                </div>
            </Col>
            <Col md={3} style={{marginBottom:"10px"}}>
                <div style={{width:"105%",height:"80%", display:'flex',justifyContent:'space-between',backgroundColor:props.encryptionScore<70?'#e63c3c':'rgba(42, 187, 67, 1)',padding:10,textShadow:'1px 1px 3px black'}}>
                    <div style={{marginLeft:'1%'}}>
                        {props.encryptionScore<70?
                        <CgClose className={classes.crossTickIcon} style={{fontSize:80,color:'rgba(255,255,255,0.4)'}}></CgClose>:
                        <TiTickOutline className={classes.crossTickIcon} style={{fontSize:80,color:'rgba(255,255,255,0.4)'}}></TiTickOutline>}
                    </div>
                <div style={{marginRight:'1%'}}>
                    <div className={classes.techName}>Encryption</div>
                    <div style={{display:'flex'}}>
                        {/* {console.log(props.encryptionScore)} */}
                        <p style={{fontSize:15,color:'white',lineHeight:"45px",marginBottom:0, marginRight:10}}>({props.encryptionLow})</p>
                        <p className={classes.score}>{props.encryptionScore}%</p>
                    </div>
                    {/* <p style={{fontSize:20,color:'white',marginTop:-5}}>Passed</p> */}
                </div>
            </div>
            </Col>
            <Col md={3}>
            <div style={{width:"105%",height:"74%",float:"left",display:'flex',marginRight:"2%",justifyContent:'space-between',backgroundColor:props.storageScore<70?'#e63c3c':'rgba(42, 187, 67, 1)',padding:10,textShadow:'1px 1px 3px black'}}>
                    <div style={{marginLeft:'1%'}}>
                        {props.storageScore<70?
                        <CgClose className={classes.crossTickIcon} style={{fontSize:80,color:'rgba(255,255,255,0.4)'}}></CgClose>:
                        <TiTickOutline className={classes.crossTickIcon} style={{fontSize:80,color:'rgba(255,255,255,0.4)'}}></TiTickOutline>}
                    </div>
                    <div style={{marginRight:'1%'}}>
                        <div className={classes.techName}>Storage</div>
                        <div style={{display:'flex'}}>
                        <p style={{fontSize:15,color:'white',lineHeight:"45px",marginBottom:8, marginRight:10}}>({props.storageLow})</p>
                        <p className={classes.score}>{props.storageScore}%</p>
                        </div>
                        {/* <p style={{fontSize:20,color:'white',marginTop:-5}}>Failed</p> */}
                    </div>
            </div>
            </Col>
            <Col md={3}>
            <div style={{width:"100%",height:"74%",display:'flex',justifyContent:'space-between',backgroundColor:props.monitoringScore<70?'#e63c3c':'rgba(42, 187, 67, 1)',padding:10,textShadow:'1px 1px 3px black'}}>
                    <div style={{marginLeft:'1%'}}>
                    {props.monitoringScore<70?
                        <CgClose className={classes.crossTickIcon} style={{fontSize:80,color:'rgba(255,255,255,0.4)'}}></CgClose>:
                        <TiTickOutline className={classes.crossTickIcon} style={{fontSize:80,color:'rgba(255,255,255,0.4)'}}></TiTickOutline>}
                </div>
                <div style={{marginRight:'1%'}}>
                    <div className={classes.techName}>Monitoring</div>
                    <div style={{display:'flex'}}>
                        <p style={{fontSize:15,color:'white',lineHeight:"45px",marginBottom:8, marginRight:10}}>({props.monitoringLow})</p>
                        <p className={classes.score}>{props.monitoringScore}%</p>
                        </div>
                </div> 
            </div>
            </Col>
        </React.Fragment>
    )
}
export default Scores;