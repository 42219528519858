import React from 'react';
import NavigationSidebar from './LayoutGroup/NavigationSidebar/NavigationSidebar';
import Header from './LayoutGroup/Header/Header';
import MainContent from './LayoutGroup/MainContent/MainContent';
import classes from './MainWrapper.module.css';

const MainWrapper = () => {

        return (
            <div className={classes.mainWrapper}>
                <NavigationSidebar />

                <div className={classes.mainContentWrapper}>

                    <Header />

                    <div className={classes.mainContentOuter}>
                        <MainContent />
                    </div>
                </div>

            </div>
        )
}
export default MainWrapper