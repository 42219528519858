import React from 'react';
import {MdCheck, MdClose} from 'react-icons/md';
import classes from './ScoreBoxes.module.css';
import {Col} from 'react-bootstrap';

function ScoreBoxes(props){
    return(
        <React.Fragment>
            <Col md={3}>
            <div 
                className={classes.box}
                style={{
                    width:"105%",
                    backgroundColor:props.box[0].color,
                }}
            >
                <div className={classes.techName}>
                    {props.box[0].name}
                </div>
                <div className={classes.crossTickIcon}>
                    {props.box[0].score<70?
                    <MdClose style={{fontSize:80,color:'rgba(255,255,255,0.4)'}}></MdClose>:
                    <MdCheck style={{fontSize:80,color:'rgba(255,255,255,0.4)'}}></MdCheck>}
                </div>
                <p className={classes.score}>
                    {props.box[0].score}
                </p>
            </div>
            </Col>
            <Col md={3}>
            <div 
                className={classes.box}
                style={{
                    width:"105%",
                    backgroundColor:props.box[1].color,
                }}
            >
                <div className={classes.techName}>
                    {props.box[1].name}
                </div>
                <div className={classes.crossTickIcon}>
                    {props.box[1].score<70?
                    <MdClose style={{fontSize:80,color:'rgba(255,255,255,0.4)'}}></MdClose>:
                    <MdCheck style={{fontSize:80,color:'rgba(255,255,255,0.4)'}}></MdCheck>}
                </div>
                <p className={classes.score} >
                    {props.box[1].score}
                </p>
            </div>
            </Col>

            <Col md={3}>
            <div 
                className={classes.box}
                style={{
                    width:"105%",
                    backgroundColor:props.box[2].color,
                }}
            >
                <div className={classes.techName}>
                    {props.box[2].name}
                </div>
                <div className={classes.crossTickIcon}>
                    {props.box[2].score<70?
                    <MdClose style={{fontSize:80,color:'rgba(255,255,255,0.4)'}}></MdClose>:
                    <MdCheck style={{fontSize:80,color:'rgba(255,255,255,0.4)'}}></MdCheck>}
                </div>
                <p className={classes.score}>
                    {props.box[2].score}
                </p>
            </div>
            </Col>
            <Col md={3}>
            <div 
                className={classes.box}
                style={{
                    width:"100%",
                    backgroundColor:props.box[3].color,
                }}
            >
                <div className={classes.techName}>
                    {props.box[3].name}
                </div>
                <div className={classes.crossTickIcon}>
                    {props.box[3].score<70?
                    <MdClose style={{fontSize:80,color:'rgba(255,255,255,0.4)'}}></MdClose>:
                    <MdCheck style={{fontSize:80,color:'rgba(255,255,255,0.4)'}}></MdCheck>}
                </div>
                <p className={classes.score}>
                    {props.box[3].score}
                </p>
            </div>
            </Col>
        </React.Fragment>
    );
}
export default ScoreBoxes;