// import React from 'react';
import ReactEcharts from 'echarts-for-react';

function VulnerabilitySummary(props){

    const appList = {
        data: [
            {
                application: 'ADM Products',
                title: 'Cross-Site scripting',
                gsk_source: 'Sonarqube'
            },
            {
                application: 'ADM Products',
                title: 'Broken Authentication and Session Management',
                gsk_source: 'Sonarqube'
            },
            {
                application: 'ADM Products',
                title: 'SQL Injection',
                gsk_source: 'Sonarqube'
            },
            {
                application: 'AMADAS Server',
                title: 'Cross-Site scripting',
                gsk_source: 'Sonarqube'
            },
            {
                application: 'AMADAS Server',
                title: 'Broken Authentication and Session Management',
                gsk_source: 'Sonarqube'
            },
            {
                application: 'AMADAS Server',
                title: 'SQL Injection',
                gsk_source: 'Sonarqube'
            },
            {
                application: 'AIML Pipeline',
                title: 'Cross-Site scripting',
                gsk_source: 'Sonarqube'
            },
            {
                application: 'AIML Pipeline',
                title: 'Broken Authentication and Session Management',
                gsk_source: 'Sonarqube'
            },
            {
                application: 'AIML Pipeline',
                title: 'SQL Injection',
                gsk_source: 'Sonarqube'
            },
            {
                application: 'ADM Products',
                title: 'Cross-Site scripting',
                gsk_source: 'Black Duck'
            },
            {
                application: 'ADM Products',
                title: 'Broken Authentication and Session Management',
                gsk_source: 'Black Duck'
            },
            {
                application: 'ADM Products',
                title: 'SQL Injection',
                gsk_source: 'Black Duck'
            },
            {
                application: 'AMADAS Server',
                title: 'Cross-Site scripting',
                gsk_source: 'Black Duck'
            },
            {
                application: 'AMADAS Server',
                title: 'Broken Authentication and Session Management',
                gsk_source: 'Black Duck'
            },
            {
                application: 'AMADAS Server',
                title: 'SQL Injection',
                gsk_source: 'Black Duck'
            },
        ]
    }

    const option = {
        xAxis: {
            type: 'category',
            data: ['Critical', 'High', 'Medium', 'Low'],
            axisLabel:{
                textStyle: {
                    color: '#ffffff'
                }
            },
            axisLine:{
                lineStyle:{
                        color:'white'
                }
            }
        },
        yAxis: {
            type: 'value'
        },
        textStyle: {
            color:"#fff"
        },
        tooltip: {
            trigger: 'item',
        },
        label: {
            show: false,
            position: 'inner',
            textStyle: {
                color:"#000"
            }
        },
        series: [{
            data: [
                {
                    value: 0,
                    itemStyle: {
                        color: '#e63c3c'
                    }
                }, 
                {
                    value: 0,
                    itemStyle: {
                        color: 'rgb(59, 155, 216)'
                    }
                }, 
                { 
                    value: 0,
                    itemStyle: {
                        color: '#ffbf00'
                    }
                }, 
                {
                    value: 15,
                    itemStyle: {
                        color: 'rgb(42, 187, 67)'
                    }
                }
            ],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
            }
        }]
    };
      

    const onChartClick=(params)=>{
        props.openApplicationList(appList.data);
    }

    return(
        <ReactEcharts 
            style={{height:250,width:'100%'}} 
            option={option} 
            onEvents={{'click':onChartClick}}
        />
    )
}
export default VulnerabilitySummary