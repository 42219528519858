import React,{useEffect, useState} from 'react';
import styles from "@Utilities/scrollbar.module.css";
import { smcAdminService } from '@Services';
import Accordion from '@CommonComponents/Accordion/Accordion';
import AccordionItem from '@CommonComponents/Accordion/AccordionItem';

function UseCaseMapPage(){
    const [useCaseMapData, setUseCaseMapData] = useState();
    useEffect(()=>{
        smcAdminService.getUseCaseMap().then((response)=>{
            setUseCaseMapData(response.data.subcat_usecase_map);
        });
    },[]);
    return(
        <div className={styles.scrollbar_smc}>
           {useCaseMapData?
            <React.Fragment>
                <div style={{maxWidth: '700px'}}>
                <Accordion>
                    {Object.keys(useCaseMapData).map((usecaseSubCategory, i)=>(
                        <AccordionItem title={usecaseSubCategory}>
                            <div style={{maxWidth: '500px'}}>
                                <Accordion>
                                    {Object.keys(useCaseMapData[usecaseSubCategory]).map((techName, j)=>(
                                        <AccordionItem title={techName}>
                                            {useCaseMapData[usecaseSubCategory][techName].map((usecase, k)=>(
                                                <div>
                                                    {usecase}
                                                </div>
                                            ))}
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                            </div>
                        </AccordionItem>
                    ))}
                </Accordion>
                </div>
            </React.Fragment>
            :null}
        </div>
    );
}
export default UseCaseMapPage;