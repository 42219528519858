import React from 'react';
import { Row, Col } from 'react-bootstrap';
import dashboard from '../../Dashboard.module.css';
import ScoreGauge from '@CommonComponents/ScoreGauge/ScoreGauge2';
import { dataService,commonService } from '@Services';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { LoadingShimmer } from '@CommonComponents/Loading/Loading';


function Technology() {
    const [riskScore, setRiskScore] = useState(0);
    const [reliabilityScore, setReliabilityScore] = useState(0);
    const [efficiencyScore, setEfficiencyScore] = useState(0);
    const navigate = useNavigate();
    useEffect(()=>{
        // commonService.avalibleTechnologies();
        let startDate = commonService.getNowStartDate();
        dataService.fetchDataWithDate(startDate,false).then((data)=>{
            if(data.Technology["Operational Efficiency"])
                setEfficiencyScore(parseInt(data.Technology["Operational Efficiency"].score));
            if(data.Technology["Operational Reliability"])
                setReliabilityScore(parseInt(data.Technology["Operational Reliability"].score));
            if(data.Technology["Operational Risk"])
                setRiskScore(parseInt(data.Technology["Operational Risk"].score));
        }); 
        console.log('hey3')
    },[]);
    function showCategories() {
        navigate('/categories');
    }
    return (
        <div
            className={dashboard.dashboardBoxWrapper}
            style={{height: "70%"}}
        >
            <h5 style={{color:"#cacaca"}}>Technology</h5>
            <div id="technology">
                <Row style={{ height: "100%", marginBottom: "1%", cursor: "pointer" }}>
                    {riskScore && reliabilityScore && efficiencyScore?
                    <React.Fragment>
                        {/* <Col sm={4} style={{height:"100%"}} onClick={showCategories}> */}
                        <Col lg={4} style={{height:"auto !important", minHeight: "100%"}} onClick={showCategories}>
                            <ScoreGauge value={riskScore} category="Risk" />
                        </Col>
                        <Col lg={4} style={{height:"auto !important", minHeight: "100%"}} onClick={showCategories}>
                            <ScoreGauge value={reliabilityScore} category="Reliability" />
                        </Col>
                        <Col lg={4} style={{height:"auto !important", minHeight: "100%"}} onClick={showCategories}>
                            <ScoreGauge value={efficiencyScore} category="Efficiency" />
                        </Col>
                    </React.Fragment>
                    :<LoadingShimmer/>}
                </Row>
            </div>
        </div>
    );
}
export default Technology