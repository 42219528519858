import ReactECharts from 'echarts-for-react';
import { commonService } from '@Services';

function FunnelChart(props) {
    const name = props.name ? commonService.capitalizeletterWith_(props.name) : "ANC"
    let data = null
    const dummyData = [
        {value: 60, name: 'A'},
        {value: 40, name: 'B'},
        {value: 20, name: 'C'},
        {value: 80, name: 'D'},
        {value: 100, name: 'E'}
    ]
    if(props.data)
    {
        const propsData = props.data;
        const propName = props.name;
        let arrangedData = [];
        for (const [ , value] of propsData.entries()) {
            const chartDatam = {
                name: String(value[propName]),
                value: value.device_count
            }
            arrangedData.push(chartDatam);
        }
        data = arrangedData;
    }

    const options = {
        tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c}"
        },
        series: [
            {
                name: name,
                type:'funnel',
                left: '10%',
                top: 60,
                //x2: 80,
                bottom: 60,
                width: '80%',
                // height: {totalHeight} - y - y2,
                sort: 'descending',
                gap: 2,
                label: {
                    show: true,
                    position: 'inside'
                },
                labelLine: {
                    length: 10,
                    lineStyle: {
                        width: 1,
                        type: 'solid'
                    }
                },
                itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 1
                },
                emphasis: {
                    label: {
                        fontSize: 20
                    }
                },
                data: data ?? dummyData
            }
        ]
    };
    function handleChartClick(){
        props.handleClick();
    }
    return (
        <div>
            <ReactECharts 
                option={options}
                style={{
                    width:"100%",
                    height:"250px"
                }} 
                onEvents={{'click':handleChartClick}}
            />
            <p 
                style={{
                    textAlign:"center", 
                    fontSize:".8em"
                }}>
                    {name}
            </p>
        </div>
    )
}



export default FunnelChart