import React,{useState,useEffect} from 'react';
import {Container, Row} from 'react-bootstrap';
import { reportService } from '@Services';
import Scores from './Scores/Scores';
import Charts from './Charts/Charts';
import Map from './Map/Map';

function AWSPage(props){
  // console.log("It's AWS Page");
    const [data, setData] = useState();
    

    useEffect(()=>{
        reportService.fetchCloudData().then(res=>{
          // console.log(res);
            setData(res);
            // this.setState({data:res})
        })
    },[]);

    return(
        <div>
            {data?
            <Container style={{padding:0}} fluid>
                <Row  style={{marginLeft:"0px", marginRight: '5px',marginBottom: '-20px'}} >
                    <Scores 
                        awsTotal={data.awsTotal}
                        ec2Total={data.ec2Total}
                        s3Total={data.s3Total}
                        monitoringScore={data.monitoring}
                        monitoringLow={data.monitoringLow}
                        storageScore={data.storage}
                        storageLow={data.storageLow}
                        encryptionScore={data.encryption}
                        encryptionLow={data.encryptionLow}
                    />
                </Row>
                <Row style={{marginLeft:"0px", marginRight: '5px', marginTop: '2px'}} >
                    <Charts barData={data.locationMap} pieData1={data.vpcDetails} pieData2={data.securityGroup}></Charts>
                </Row>
                <Row style={{marginLeft:"-5px", marginRight: '15px'}} >
                    <div style={{width:'100%',marginTop:10,height:400}}>
                        <Map data={data.locationMap}></Map>
                    </div>
                </Row>
            </Container>
            :null}
        </div>
    );
}

export default AWSPage;