import ReactECharts from 'echarts-for-react';
import { commonService } from '@Services';

function SubcategoryGauge(props) {

    const name = props.name ?? "Subcat Name"
    const score = props.score ?? 0

    const options = {
        tooltip: {
            formatter: '{a} <br/>{b} : {c}%'
        },
        series: [{
            name: 'Score',
            type: 'gauge',
            radius: '80%',
            progress: {
                show: true
            },
            axisTick: { show:false },
            splitLine: { show:false },
            axisLabel: { show:false },
            detail: {
                valueAnimation: true,
                formatter: '{value}',
                fontSize: 18,
                color: '#fff'
            },
            data: [{
                value: score,
                name: 'SCORE',
                title: {show:false},
                itemStyle: {
                    color: commonService.colorCodeBasedOnScore(score)
                }
            }]
        }]
    };

    return (
        <div>
        <ReactECharts 
            option={options}
            style={{
                width:"100%",
                height:"170px"
            }} 
        />
        <p 
            style={{
                textAlign:"center", 
                fontSize:".9em"
        }}>{commonService.technologyKnownAs(name)}</p>
        </div>
    )
}



export default SubcategoryGauge