// import React from 'react';
import ReactEcharts from 'echarts-for-react';

function PieChart(props){

  const chartData = []
  const center = props.center;
  const option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
          show: false,
          top: 'top'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          label: {
              show: false,
              position: 'inner',
              formatter: '{b}: {d}%'
          },
          radius: '80%',
          data: chartData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          center: center
        }
      ]
  };
    
    Object.keys(props.data).map(key=>{
      chartData.push({value:props.data[key].value,name:key,data:props.data[key].deviceData})
      option.series[0].center=props.center;
      return 0;
    })
    
    return(
            <ReactEcharts renderer={'svg'} option={option} style={{height:370}}></ReactEcharts>
    )
    
}
export default PieChart;