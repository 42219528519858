export const deviceConstants = {
    DEVICE_DATA_REQUEST: 'DEVICE_DATA_FETCH_REQUEST',
    DEVICE_DATA_SUCCESS: 'DEVICE_DATA_FETCH_SUCCESS',
    DEVICE_DATA_FAILURE: 'DEVICE_DATA_FETCH_FAILURE',
    DEVICE_DATA_CLEAR: 'DEVICE_DATA_CLEAR',
};

export const systemNameConstants = {
    'ec2-aws':'instance_id',
    's3-aws': 'bucket_name',
    // 'ticketing-servicenow': 'engineer_name',
    // 'dlpdiscover-symantec': 'incident_id',
    // 'dlpendpoint-symantec': 'incident_id',
    'people-people': 'username',
    // 'dlp-symantec': 'ip_address',
    'av-mcafee': 'nodename',
    // 'cmdb-serviceNow': 'name',
    // 'hips-mcafee': 'system_name',
    'monitoring-solarwinds': 'system_name',
    'firewallanalyzer-firemon': 'system_name',
    // 'antivirus-symantec': 'system_name',
    // 'loadbalancer-f5': 'system_name',
    // 'vpn-pulsesecure': 'user_name',
    // 'bluecoatproxy-symantec': 'system_name',
    // 'bitlockerencryption-microsoft': 'system_name',
    // 'secureinternetgateway-zscaler': 'system_name',
    'panorama-paloaltonetworks': 'system_name',
    // 'dlp-mcAfee': 'system_name',
    'firewall-skyboxsecurity': 'system_name',
    // 'firewallmanagement-checkpoint': 'system_name',
    'vulnerabilitymanagement-tenable':'system_name',
    'loadbalancer-barracuda':'system_name',
    'waf-cloudflare': 'account_name',
};

export const primaryKeyConstants = {
    'ec2-aws':'instance_id',
    's3-aws': 'bucket_name',
    // 'ticketing-servicenow': 'engineer_name',
    // 'dlpdiscover-symantec': 'incident_id',
    // 'dlpendpoint-symantec': 'incident_id',
    'people-people': 'username',
    // 'dlp-symantec': 'ip_address',
    'av-mcafee': 'nodename',
    // 'cmdb-serviceNow': 'name',
    // 'hips-mcafee': 'system_name',
    'monitoring-solarwinds': 'system_name',
    'firewallanalyzer-firemon': 'system_name',
    // 'antivirus-symantec': 'system_name',
    // 'loadbalancer-f5': 'system_name',
    // 'vpn-pulsesecure': 'user_name',
    // 'bluecoatproxy-symantec': 'system_name',
    // 'bitlockerencryption-microsoft': 'system_name',
    // 'secureinternetgateway-zscaler': 'system_name',
    'panorama-paloaltonetworks': 'system_name',
    // 'dlp-mcafee': 'system_name',
    'firewall-skyboxsecurity': 'system_name',
    // 'firewallmanagement-checkpoint': 'system_name',
    'vulnerabilitymanagement-tenable':'system_name',
    'loadbalancer-barracuda':'system_name',
    'waf-cloudflare': 'account_id',
};