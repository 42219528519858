import {Container} from 'react-bootstrap';
import CategoryRow from './CategoryRow/CategoryRow';
import { dataService,commonService } from '@Services';
import React, { useEffect, useState } from 'react';

function CategoryPage() {
	const [riskScore, setRiskScore] = useState();
    const [reliabilityScore, setReliabilityScore] = useState();
    const [efficiencyScore, setEfficiencyScore] = useState();
	const [riskData, setRiskData] = useState(null);
	const [reliabilityData, setReliabilityData] = useState(null);
	const [efficiencyData, setEfficiencyData] = useState(null);
    
    useEffect(()=>{
		let startDate = commonService.getNowStartDate();
        dataService.fetchDataWithDate(startDate,false).then((data)=>{
          	let riskData = [];
			let reliabilityData = [];
			let efficiencyData = [];
            if(data.Technology["Operational Efficiency"])
            {    
                setEfficiencyScore(parseInt(data.Technology["Operational Efficiency"].score));
				let efficiencyChangeData = []
				Object.keys(data.Technology["Operational Efficiency"]["changes"]).map((usecase,index)=>{
					const usecaseName = usecase;
					const changePer = data.Technology["Operational Efficiency"]["changes"][usecase];
					let result = {
						change: changePer,
						subcategory : usecaseName
					}
					efficiencyChangeData.push(result);
					return 0;
				})
				Object.keys(data.Technology["Operational Efficiency"]["sub_categories"]).map((usecase,index)=>{
					const usecaseName = usecase;
					const scorePer = data.Technology["Operational Efficiency"]["sub_categories"][usecase]+" %";
					let changeSr = 0;
					for (const [ , value] of efficiencyChangeData.entries()) {
						if(value.subcategory === usecaseName)
						{
							changeSr = value.change;
						}
					}
					let result = {
						score: scorePer,
						subcategory : usecaseName,
						change: changeSr
					}
					efficiencyData.push(result)
					return 0;
				})
				setEfficiencyData(efficiencyData);
            }
            if(data.Technology["Operational Reliability"])
			{
                setReliabilityScore(parseInt(data.Technology["Operational Reliability"].score));
				let reliabilityChangeData = []
				Object.keys(data.Technology["Operational Reliability"]["changes"]).map((usecase,index)=>{
					const usecaseName = usecase;
					const changePer = data.Technology["Operational Reliability"]["changes"][usecase];
					let result = {
						change: changePer,
						subcategory : usecaseName
					}
					reliabilityChangeData.push(result);
					return 0;
				})
				Object.keys(data.Technology["Operational Reliability"]["sub_categories"]).map((usecase,index)=>{
					const usecaseName = usecase;
					const scorePer = data.Technology["Operational Reliability"]["sub_categories"][usecase]+" %";
					let changeSr = 0;
					for (const [ , value] of reliabilityChangeData.entries()) {
						if(value.subcategory === usecaseName)
						{
							changeSr = value.change;
						}
					}
					let result = {
						score: scorePer,
						subcategory : usecaseName,
						change: changeSr
					}
					reliabilityData.push(result);
					return 0;
				})
				setReliabilityData(reliabilityData);
			}
            if(data.Technology["Operational Risk"])
			{
                setRiskScore(parseInt(data.Technology["Operational Risk"].score));
				let riskChangeData = []
				Object.keys(data.Technology["Operational Risk"]["changes"]).map((usecase,index)=>{
					const usecaseName = usecase;
					const changePer = data.Technology["Operational Risk"]["changes"][usecase];
					let result = {
						change: changePer,
						subcategory : usecaseName
					}
					riskChangeData.push(result);
					return 0;
				})
				Object.keys(data.Technology["Operational Risk"]["sub_categories"]).map((usecase,index)=>{
					const usecaseName = usecase;
					const scorePer = data.Technology["Operational Risk"]["sub_categories"][usecase]+" %";
					let changeSr = 0;
					for (const [ , value] of riskChangeData.entries()) {
						if(value.subcategory === usecaseName)
						{
							changeSr = value.change;
						}
					}
					let result = {
						score: scorePer,
						subcategory : usecaseName,
						change: changeSr
					}
					riskData.push(result);
					return 0;
				})
				setRiskData(riskData)
			}
        }); 
    },[]);
    return (
        <Container 
			fluid 
			style={{
				display:"grid", 
				alignContent:"flex-start", 
				position:"relative"
			}}
		>
			<React.Fragment>
				{riskData?
				<div style={{paddingBottom: "30px"}}>
					<CategoryRow 
						score={riskScore} 
						data={riskData} 
						category="Risk"
					/>
				</div>
				:null}
				{reliabilityData?
				<div style={{paddingBottom: "30px"}}>
					<CategoryRow 
						score={reliabilityScore} 
						data={reliabilityData} 
						category="Reliability"
					/>
				</div>
				:null}
				{efficiencyData?
				<div style={{paddingBottom: "30px"}}>
					<CategoryRow 
						score={efficiencyScore} 
						data={efficiencyData} 
						category="Efficiency"
					/>
				</div>
				:null}
			</React.Fragment>
			
        </Container>
    )
}
export default CategoryPage