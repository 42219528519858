import ReactECharts from 'echarts-for-react';
import { commonService } from '@Services';

function BarChart(props) {

    const name = props.name ? commonService.capitalizeletterWith_(props.name) : "ANC"
    let xAxisData = [];
    let yAxisData = [];
    if(props.data)
    {
        const propsData = props.data;
        const propName = props.name;
        for (const [ , value] of propsData.entries()) {
            xAxisData.push(String(value[propName]));
            yAxisData.push(value.device_count);   
        }
    }


    const options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {            
                type: 'shadow'        
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: xAxisData,
                axisTick: {
                    alignWithLabel: true
                }
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            {
                name: '',
                type: 'bar',
                barWidth: '60%',
                data: yAxisData
            }
        ]
    };
    function handleChartClick(){
        props.handleClick();
    }
    return (
        <div>
            <ReactECharts 
                option={options}
                style={{
                    width:"100%",
                    height:"250px"
                }} 
                onEvents={{'click':handleChartClick}}
            />
            <p 
                style={{
                    textAlign:"center", 
                    fontSize:".8em"
            }}>{name}</p>
        </div>
    )
}



export default BarChart