import classes from './ProgressBars.module.css';
import ProgressBar from 'react-bootstrap/ProgressBar'
import {commonService} from '@Services';
import React from 'react';

function ProgressBars(props) {
    return (
        <div className={classes.progressBarWrapper}>
            {props.data.map((technology,index)=>(
                <React.Fragment key={index}>
                {technology.score !== -1?
                <div className={classes.progressBars}>
                    <div className={classes.textGroup}>
                        <span
                            style={{
                                fontSize:'.7rem'
                            }}
                        >{commonService.technologyKnownAs(technology.name)}</span>
                        <span
                            style={{
                                fontSize:'1.5rem'
                            }}
                        >{technology.score}%</span>
                    </div>
                    <ProgressBar variant={technology.score>69?technology.score>79?"success":"warning":"danger"} now={technology.score} />
                </div>
                :<div className={classes.progressBars}>
                    <div className={classes.textGroup}>
                        <span
                            style={{
                                fontSize:'.7rem'
                            }}
                        >{commonService.technologyKnownAs(technology.name)}</span>
                        <span
                            style={{
                                fontSize:'1.5rem'
                            }}
                        >N/A</span>
                    </div>
                    <ProgressBar variant="danger" now={0} />
                </div>}
                </React.Fragment>
            ))}
        </div>
    )
}
export default ProgressBars