import React from "react"
import ReactSpeedometer from "react-d3-speedometer"

function ScoreGauge(props) {

    const options = {
        width: props?.style?.width ?? 250,
        height: props?.style?.height ?? 160
    }

    return (
        <React.Fragment>
            {props.disableText?<ReactSpeedometer
            value={props.value}
            needleColor="steelblue"
            needleTransitionDuration={4000}
            needleTransition="easeElastic"
            textColor="#cacaca"
            width={options.width}
            height={options.height}
            maxValue={100}
            forceRender={true}
            labelFontSize={'0'}
        />:
        <ReactSpeedometer
            value={props.value}
            needleColor="steelblue"
            needleTransitionDuration={4000}
            needleTransition="easeElastic"
            currentValueText={props.category+": #{value}"}
            currentValuePlaceholderStyle={'#{value}'}
            valueTextFontSize={"16"}
            valueTextFontWeight={"10"}
            textColor="#cacaca"
            width={options.width}
            height={options.height}
            maxValue={100}
            forceRender={true}
            labelFontSize={"0"}
        />}
        </React.Fragment>
    )
}



export default ScoreGauge