export const mapData = {
    "name": "Business Unit",
    "children": [
        {
            "name": "Business Process 1",
            "children": [
                {
                    "name": "AIML Pipeline",
                    "children": [
                        {
                            "name": "App",
                            "children":[
                                {"name": "python:S4829"},
                                {"name": "python:S4823"}
                            ]
                        },
                        {
                            "name": "Device",
                            "children":[
                                {"name": "gbwp321898101"},
                                {"name": "gbwp321898102"},
                                {"name": "gbwp321898103"},
                                {"name": "gbwp321898104"},
                            ]
                        }            
                    ]
                },
                {
                    "name": "Comet Packaging",
                    "children": [
                        {
                            "name": "App",
                            "children":[
                                {"name": "csharpsquid:S2092"},
                                {"name": "csharpsquid:S2255"},
                                {"name": "csharpsquid:S4834"}
                            ]
                        },
                        {
                            "name": "Device",
                            "children":[
                                {"name": "gbwp321898105"},
                                {"name": "gbwp321898106"},
                                {"name": "gbwp321898107"},
                                {"name": "gbwp321898108"},
                            ]
                        }            
                    ]
                }        
            ]
        },
        {
            "name": "Business Process 2",
            "children": [
                {
                    "name": "SSF-Interface--JAVA-Dev-report",
                    "children": [
                        {
                            "name": "App",
                            "children":[
                                {"name": "java:S2077"},
                                {"name": "java:S4823"},
                                {"name": "java:S4790"},
                                {"name": "java:S4787"}
                            ]
                        },
                        {
                            "name": "Device",
                            "children":[
                                {"name": "gbwp321898109"},
                                {"name": "gbwp321898110"},
                                {"name": "gbwp321898111"},
                                {"name": "gbwp321898112"},
                                {"name": "gbwp321898113"},
                                {"name": "gbwp321898114"}
                            ]
                        }            
                    ]
                },
                {
                    "name": "ADM Products",
                    "children": [
                        {
                            "name": "Device",
                            "children":[
                                {"name": "gbwp321898115"},
                                {"name": "gbwp321898116"},
                                {"name": "gbwp321898117"}
                            ]
                        }
                    ]
                }     
            ]
        }

    ]
}

// {
//     "name": "",
//     "children": [

//     ]
// }

export const appMappingData = {
    'csharpsquid:S2092': [['csharpsquid:S2092-Make sure creating this cookie without setting the \'Secure\' property is safe here.', 'Sonarqube','Broken Authentication and Session Management']],
    'csharpsquid:S2255': [['csharpsquid:S2255-Make sure that this cookie is written safely.', 'Sonarqube', 'Insecure Deserialization']],
    'csharpsquid:S4834': [
        ['csharpsquid:S4834-Make sure that permissions are controlled safely here.' ,'Sonarqube', 'Broken Authentication and Session Management'],
        ['csharpsquid:S4834-Make sure that permissions are controlled safely here.', 'Sonarqube', 'Broken Authentication and Session Management']
    ],
    'python:S4829': [
        ['python:S4829-Make sure that reading the standard input is safe here.', 'Sonarqube', 'Security Misconfiguration'],
        ['python:S4829-Make sure that reading the standard input is safe here.', 'Sonarqube',  'Security Misconfiguration'],
        ['python:S4829-Make sure that reading the standard input is safe here.', 'Sonarqube', 'Security Misconfiguration']
    ],
    'python:S4823': [['python:S4823-Make sure that command line arguments are used safely here.', 'Sonarqube', 'Security Misconfiguration']],
    'java:S2077': [
        ['java:S2077-Ensure that string concatenation is required and safe for this SQL query.', 'Sonarqube', 'SQL Injection'],
        ['java:S2077-Ensure that string concatenation is required and safe for this SQL query.', 'Sonarqube', 'SQL Injection'],
        ['java:S2077-Ensure that string concatenation is required and safe for this SQL query.', 'Sonarqube', 'SQL Injection'],
        ['java:S2077-Ensure that string concatenation is required and safe for this SQL query.', 'Sonarqube', 'SQL Injection'],
        ['java:S2077-Ensure that string concatenation is required and safe for this SQL query.', 'Sonarqube', 'SQL Injection']
    ],
    'java:S4823': [['java:S4823-Make sure that command line arguments are used safely here.', 'Sonarqube', 'Security Misconfiguration']],
    'java:S4790': [['java:S4790-Make sure that hashing data is safe here.', 'Sonarqube', 'Security Misconfiguration']],

    'java:S4787': [
        ['java:S4787-Make sure that encrypting data is safe here.', 'Sonarqube', 'Sensitive Data Exposure'],
        ['java:S4787-Make sure that encrypting data is safe here.', 'Sonarqube', 'Sensitive Data Exposure']
    ]


}

export const deviceMappingData = {
    'gbwp321898101': [
        ['Package buffer overflow vulnerability on oracle 10g release 2', 'Qualys', 'Qualys', 'Severe', 'AIML Pipeline'],
        ['NetBIOS Name Accessible', 'Fortify', 'Fortify', 'Low', 'AIML Pipeline'],
        ['Microsoft Window Remote Desktop Protocol server Man-in-the-middle attack', 'Nessus',	'Nessus', 'High', 'AIML Pipeline'],
        ['Antivirus Agent is missing', 'Antivirus', 'Symantec', 'Critical', 'AIML Pipeline'],
        ['RDP Port is enabled on the firewall', 'Firewall', 'Palo Alto', 'Critical', 'AIML Pipeline']
    ],
    'gbwp321898102': [
        ['Microsoft MSDTC and COM+ remote code execution vulnerability', 'Qualys', 'Qualys', 'Low', 'AIML', 'Pipeline'],
        ['EOL/Obsolete Operaing System: Microsoft Windows XP Detected', 'Nessus', 'Nessus', 'Medium', 'AIML', 'Pipeline'],
        ['SMB Signing Disabled', 'Qualys', 'Qualys', 'Low', 'AIML', 'Pipeline'],
        ['AV Signature is not updated', 'Antivirus', 'Symantec', 'Critical', 'AIML', 'Pipeline']
    ],
    'gbwp321898103': [
        ['Multiple Microsoft Windows vulnerability', 'Nessus', 'Nessus', 'Low', 'AIML', 'Pipeline'],
        ['ICMP Time stamp request', 'Fortify', 'Fortify', 'High', 'AIML', 'Pipeline'],
        ['Microsoft Windors SMB NULL Session Authentication', 'Qualys', 'Qualys', 'Medium', 'AIML', 'Pipeline'],
        ['RDP Port is enabled on the firewall	Firewall', 'Palo', 'Alto', 'Critical', 'AIML', 'Pipeline']
    ],
    'gbwp321898104': [
        ['Microsoft  SMB remote code execution vulnerability', 'Nessus', 'Nessus', 'High', 'AIML Pipeline'],
        ['ICMP based TCP reset denial of service vulnerability', 'Fortify', 'Fortify', 'Severe', 'AIML Pipeline'],
        ['Windows NetBIOS/SMB remote host information disclosure', 'Qualys', 'Qualys', 'Medium', 'AIML Pipeline'],
        ['Terminal Service encrytion level is Medium or Low', 'Nessus', 'Nessus', 'Low', 'AIML Pipeline'],
        ['Java Debug wire protocol remote code execution vulnerability', 'Nessus', 'Nessus', 'Medium', 'AIML Pipeline'],
        ['DDoS Protection is not enabled on WAF', 'WAF', 'Radware', 'Critical', 'AIML Pipeline']
    ],
    'gbwp321898105': [
        ['Java Debug wire protocol remote code execution vulnerability', 'Qualys', 'Qualys', 'Low', 'Comet Packaging'],
        ['Antivirus Agent is missing', 'Antivirus', 'Symantec', 'Critical', 'Comet Packaging'],
        ['RDP Port is enabled on the firewall', 'Firewall', 'Palo Alto', 'Critical', 'Comet Packaging'],
        ['Oracle Java SE critical Patch update', 'Nessus', 'Nessus', 'Medium', 'Comet Packaging']
    ],
    'gbwp321898106': [
        ['AV Signature is not updated', 'Antivirus', 'Symantec', 'Critical', 'Comet Packaging'],
        ['DDoS Protection is not enabled on WAF', 'WAF', 'Radware', 'Critical', 'Comet Packaging']
    ],
    'gbwp321898107': [
        ['Orace Database Server Java VM Remote Code Execution vulnerability', 'Fortify', 'Fortify', 'High', 'Comet Packaging'],
        ['Oracle sql92_security parameter disabled','Qualys', 'Qualys', 'Severe', 'Comet Packaging']
    ],
    'gbwp321898108': [
        ['Data at rest is not protected', 'DLP', 'DLP', 'Critical', 'Comet Packaging'],
        ['DDoS Protection is not enabled on WAF', 'WAF', 'Radware', 'Critical', 'Comet Packaging']
    ],
    'gbwp321898109': [
        ['Oracle Java SE critical Patch update', 'Nessus', 'Nessus', 'High', 'SSF-Interface-Java-Dev-report'],
        ['Orace Database Server Java VM Remote Code Execution vulnerability', 'Nessus', 'Nessus', 'Severe', 'SSF-Interface-Java-Dev-report'],
        ['Oracle sql92_security parameter disabled', 'Fortify', 'Fortify', 'Severe', 'SSF-Interface-Java-Dev-report']
    ],
    'gbwp321898110': [
        ['NetBIOS Name Accessible', 'Fortify', 'Fortify', 'Medium', 'SSF-Interface-Java-Dev-report'],
        ['AV Signature is not updated', 'Antivirus', 'Symantec', 'Critical', 'SSF-Interface-Java-Dev-report']
    ],
    'gbwp321898111': [
        ['EOL/Obsolete Operaing System: Microsoft Windows XP Detected', 'Fortify', 'Fortify', 'High', 'SSF-Interface-Java-Dev-report'],
        ['RDP Port is enabled on the firewall', 'Firewall', 'Palo Alto', 'Critical', 'SSF-Interface-Java-Dev-report']
    ],
    'gbwp321898112': [
        ['Package buffer overflow vulnerability on oracle 10g release 2', 'Fortify', 'Fortify', 'Low', 'SSF-Interface-Java-Dev-report'],
        ['ICMP Time stamp request', 'Nessus', 'Nessus', 'Severe', 'SSF-Interface-Java-Dev-report']
    ],
    'gbwp321898113': [
        ['Microsoft MSDTC and COM+ remote code execution vulnerability', 'Nessus', 'Nessus', 'Low', 'SSF-Interface-Java-Dev-report'],
        ['ICMP based TCP reset denial of service vulnerability', 'Qualys', 'Qualys', 'High', 'SSF-Interface-Java-Dev-report']
    ],
    'gbwp321898114': [
        ['Multiple Microsoft Windows vulnerability', 'Fortify', 'Fortify', 'High', 'SSF-Interface-Java-Dev-report'],
        ['Microsoft  SMB remote code execution vulnerability', 'Nessus', 'Nessus', 'Low', 'SSF-Interface-Java-Dev-report']
    ],
    'gbwp321898115': [],
    'gbwp321898116': [],
    'gbwp321898117': [],
}
















export const mapData2 = {
    "name": "GSK Pharmaceutical",
    "children": [
        {
            "name": "GSKCH011",
            "children": [
                {
                    "name": "Comet Packaging",
                    "children": [
                        {
                            "name": "csharpsquid:S2092", 
                            "children":[
                                {"name": "gbwp321898101"}
                            ]
                        },
                        {
                            "name": "csharpsquid:S2255", 
                            "children":[
                                {"name": "gbwp321898102"}
                            ]
                        },
                        {
                            "name": "csharpsquid:S4834", 
                            "children":[
                                {"name": "gbwp321898103"}
                            ]
                        },
                        {
                            "name": "csharpsquid:S4834", 
                            "children":[
                                {"name": "gbwp321898104"}
                            ]
                        },
                    ]
                },
                {
                    "name": "AIML Pipeline",
                    "children": [
                        {
                            "name": "python:S4829", 
                            "children":[
                                {"name":"gbwp321898105"}, 
                                {"name":"gbwp321898106"},
                                {"name":"gbwp321898107"}
                            ]
                        },
                        {
                            "name": "python:S4823", 
                            "children":[
                                {"name": "gbwp321898108"}
                            ]
                        },
                    ]
                }
            ]
        },
        {
            "name": "GSKCH013",
            "children": [
                {
                    "name": "SSF",
                    "children": [
                        {
                            "name": "java:S2077", 
                            "children":[
                                {"name":"gbwp321898109"}, 
                                {"name":"gbwp321898110"},
                                {"name":"gbwp321898111"},
                                {"name":"gbwp321898112"},
                                {"name":"gbwp321898113"}
                            ]
                        },
                        {
                            "name": "java:S4823", 
                            "children":[
                                {"name":"gbwp321898114"},
                            ]
                        },
                        {
                            "name": "java:S4790", 
                            "children":[
                                {"name":"gbwp321898115"},
                            ]
                        },
                        {
                            "name": "java:S4787", 
                            "children":[
                                {"name":"gbwp321898116"},
                                {"name":"gbwp321898117"},
                            ]
                        },
                    ]
                }
            ]
        }
    ]
}