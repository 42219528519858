import React from 'react';
import { useEffect, useState } from 'react';
import classes from './ThreeCasesTechnology.module.css';
import ScoreBoxes from './Technology/ScoreBoxes/ScoreBoxes';
import Technology from './Technology/Technology';
import { dataService,commonService,deviceService } from '@Services';
import { smcUtility } from '@Utilities';


function ThreeCasesTechnology(){

    const [scoreData, setScoreData] = useState();
    useEffect(()=>{
        smcUtility.smcSetting().then((data)=>{
            let techList = data.topUsecaseList;
            let techScore = [];
            let count = 0
            Object.keys(techList).map((tech)=>{
                deviceService.getTechDataStartDate(tech).then((startDate)=>{
                    dataService.fetchTechnologyData(tech,true,techList[tech],false,startDate).then((useCaseData)=>{
                        useCaseData.data.map((d)=>{
                            count++;
                            const name = commonService.impUseCaseDetails(d.name);
                            const score = parseInt(d.score) + "%";
                            const color = commonService.colorCodeBasedOnScore(d.score);
                            const techData = {
                                score: score,
                                title: name,
                                color: color
                            }
                            techScore.push(techData)
                            if(count === 4)
                            {
                                setScoreData(techScore);
                            }
                            return 0;
                        })
                    })
                })
                return 0;
            })
        });
    },[]);
    
    return(
        <React.Fragment>
            <Technology />
            <div id="boxes" className={classes.scoreBoxesWrapper}>
                {scoreData? scoreData.map((data, index) => (
                    <div key={index}>
                        <ScoreBoxes data={data} />
                    </div>
                ))
                :
                // <LoadingShimmer />
                [1,2,3,4].map((data,index) => (
                    <div id="boxes" key={index}>
                        <ScoreBoxes data={{
                            score: '-1',
                            title: 'Loading'
                        }} />
                    </div>
                ))

                }
            </div>
        </React.Fragment>
    );
}

export default ThreeCasesTechnology;