import classes from './SessionReminder.module.css';
import {userService} from '@Services';
import { useNavigate } from "react-router-dom";

function SessionReminder() {
    const navigate = useNavigate();

    const setReminder = () => {
        setTimeout(() => {
            document.getElementById("reminderBox").style.display = "block";
        }, 3200000)
    }

    const resetReminder = () => {
        document.getElementById("reminderBox").style.display = "none";
        userService.refreshToken().then(res => {
            if (res) {
                setReminder();
            }
            else {
                logout();
            }
        })
    }

    const logout = () => {
        navigate('/logout');
    }

    //Run the timer for the first time.
    setReminder();


    return (
        <div id="reminderBox" className={classes.backdrop}>
            <div className={classes.sessionReminder}>
                <p>Do you want to continue this session?</p>
                <button onClick={resetReminder}>Yes</button>
                <button onClick={logout}>No</button>
            </div>
        </div>
    )
}
export default SessionReminder