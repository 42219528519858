import classes from './Loading.module.css';
import ScaleLoader from "react-spinners/ScaleLoader";

function LoadingFull() {
  return (
    <div className={classes.loadingCoverWrapper}>
        <div className={classes.loaderPosition}>
            <ScaleLoader color={'#36D7B7'} loading={true} size={45} />
        </div>
    </div>
  );
}

export default LoadingFull;