import { dataService } from '@Services';
import React,{useEffect, useState} from 'react';
import { smcUtility } from '@Utilities';
import classes from './TechnologyPage.module.css';
import { LoadingFull } from '@CommonComponents/Loading/Loading';
import TechCard from './components/TechCard';
import styles from "@Utilities/scrollbar.module.css";

function TechnologyPage(props) {
    const [technologyData, setTechnologyData] = useState();
    useEffect(()=>{
        smcUtility.smcSetting().then((data)=>{
            let techList = data.technologyList.child;
            let allTechData = [];
            const totalActivatedTechnology = data.technologyList.totalActivatedTechnology;
            let counter = 0;
            Object.keys(techList).map((tech)=>{
                if(tech && techList[tech].show){ 
                    console.log(tech)
                    dataService.latestTechCompliance(tech).then((techCompData)=>{
                        ++counter;
                        const data = {
                            technologyName: techCompData.data.technology_name,
                            technologyCompliance: techCompData.data.technology_compliance,
                        }
                        allTechData.push(data);
                        if(counter === totalActivatedTechnology)
                        {
                            setTechnologyData(allTechData);
                        }
                    });
                }
                return 0;
            });
            
        });
    },[]);
    return (
        <div className={styles.scrollbar_smc}>
            {technologyData?
                <div className={classes.gridContainer}>
                    {technologyData.map( (tech,i) => (
                        <div className={classes.gridItem} key={i}>
                            <TechCard
                            techName={tech.technologyName}
                            complianceScore={tech.technologyCompliance}
                            />
                        </div>
                    ))}
                </div>
            :<LoadingFull/>}
        </div>
  );
}

export default TechnologyPage;