import React from 'react';
import ReactEcharts from 'echarts-for-react';


const options = {
    xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    },
    yAxis: {
        type: 'value'
        //no. of bots
    },
    grid: {
        top:20,
        left:40,
        right:30,
        bottom:25
    },
    textStyle: {
        color:"#fff"
    },
    series: [{
        data: [10,5,8,4,21,18,9],
        type: 'line'
    }]
}

function NumberOfBoTs() {
    return (
        <React.Fragment>
            <ReactEcharts
                option={options}
            />
        </React.Fragment>
    )
}
export default NumberOfBoTs;