import Api from '@Api/SMCApi'
import { urlConstants } from '@Constants';
import { logger } from '@Utilities';
export const userService = {
    login,
    logout,
    changePassword,
    getToken,
    verifyToken,
    refreshToken,
    checkOldPassword,
    getUserName,
    checkAdmin,
    userProfile,
    checkFirstLogin,
    updateSkipTour,
};
function pushlogout(){
  let url = window.location.href;
  url = url.split('/')[0];
  if(url.startsWith('http://localhost') || url.startsWith(urlConstants.BASE_URL.split(':')[0]))
  {
    window.location.href = url+'/logout';
  }
}
function checkFirstLogin(){
    let token = getToken();
    const accessToken = token;
    token=token?token.split('.')[1]:'';
    const userid = JSON.parse(atob(token?token:'', 'base64').toString('ascii')).user_id;
    const url = urlConstants.SITE_URL + urlConstants.AUTH_PROFILE_URL + userid
    return Api.get(url,{headers: {Authorization : `Bearer ${accessToken}`}}).then(res=>{
      let data=res.data.skip_tour;
      return data;
    }).catch(err =>{
      if(String(err).includes('401'))
        {
            logger.debug(err);
            pushlogout();
        }
        else
            logger.debug(err);
    });
}
function updateSkipTour(){
    let token = getToken();
    const accessToken = token;
    token=token?token.split('.')[1]:'';
    const userid = JSON.parse(atob(token?token:'', 'base64').toString('ascii')).user_id;
    const url = urlConstants.SITE_URL + urlConstants.AUTH_PROFILE_URL + userid + '/'
    const payload = {"skip_tour": true}
    return Api.patch(url,payload,{headers: {Authorization : `Bearer ${accessToken}`}}).then(res=>{
      return res;
    }).catch(err =>{
      if(String(err).includes('401'))
        {
            logger.debug(err);
            pushlogout();
        }
        else
            logger.debug(err);
    });
}
function login(username, password) {
    const payload = {username:window.btoa(username), password:window.btoa(password) };
  // logger.debug(payload);
    const url=urlConstants.SITE_URL+urlConstants.AUTH_LOGIN_URL
    return Api.post(url,payload).then(response => {
          // logger.debug(response)
            if (response.status!==200) {
            // logger.debug("REJECTED RESPONSE",response.statusText);
                return Promise.reject(response);
            }
            let userData={user: username}
            let token=response.data;
              return {...userData,...token};
        }).then(user => {
          // logger.debug(user);
            if (user && user.user && user.access && user.refresh) {
                sessionStorage.setItem('isLoggedIn', 1);
                sessionStorage.setItem('user', user.user);
                sessionStorage.setItem('access', user.access);
                sessionStorage.setItem('refresh', user.refresh);
                sessionStorage.setItem('guide', 'true');
            }
            return user;
        });
}
function logout() {
  const refreshToken = sessionStorage.getItem('refresh')
  const payload ={
    "refresh": refreshToken
  }
  const url=urlConstants.SITE_URL+urlConstants.AUTH_LOGOUT_URL
  const token=getToken();
  return Api.post(url,payload,{headers: {Authorization : `Bearer ${token}`}}).then(response => {
    if(response.status === 200)
    {
      localStorage.clear();
      sessionStorage.clear();
      return true;
    }
    else
      return false;
  })
}
function changePassword(oldPassword, newPassword) {
    const payload = {"old_password":window.btoa(oldPassword), "new_password":window.btoa(newPassword) };
  // logger.debug(payload);
    const url=urlConstants.SITE_URL+urlConstants.AUTH_PASSWORD_CHANGE_URL
    const token=getToken();
    return Api.put(url,payload,{headers: {Authorization : `Bearer ${token}`}}).then(response => {
      // logger.debug(response.data);
        return response;
    }).catch(err=>{
      if(String(err).includes('401'))
      {
          logger.debug(err);
          pushlogout();
      }
      else
          logger.debug(err);
    });
}
function checkOldPassword(username,password){
  const payload = {username:window.btoa(username), password:window.btoa(password) };
  const url=urlConstants.SITE_URL+urlConstants.AUTH_LOGIN_URL
  return Api.post(url,payload).then(response => {
      if (response.status!==200) {
          return false;
      }
      return true;
  });
}
function getToken(){
  return sessionStorage.getItem('access')
}
function verifyToken(token){
  if(token){
    const url=urlConstants.SITE_URL+urlConstants.AUTH_TOKEN_VERIFY_URL;
    return Api.post(url,{token: token}).then(response => {
        if (response.status!==200) {
            return false;
        }
        if(token!==response.data.token){
          logout()
        }
    }).catch(err =>{
      if(String(err).includes('401'))
      {
          logger.debug(err);
          pushlogout();
      }
      else
          logger.debug(err);
    });
  }
  return false;
}
function refreshToken(){
    const url=urlConstants.SITE_URL+urlConstants.AUTH_TOKEN_REFRESH_URL;
    const token = sessionStorage.getItem('refresh');
    return Api.post(url,{refresh: token}).then(response => {
      if (!response.status===200) {
          return false;
      }
      const accessToken=response.data.access;
      sessionStorage.setItem('access', accessToken);
      return true;
    }).catch(err =>{
      if(String(err).includes('401'))
      {
          logger.error('ERROR HAPPENED')
          logger.error(err);
          pushlogout();
      }
      else
          logger.error(err);
    });
}
function getUserName(){
  let token = getToken();
  const accessToken = token;
  token=token?token.split('.')[1]:'';
  const userid = JSON.parse(atob(token?token:'', 'base64').toString('ascii')).user_id;
  const url = urlConstants.SITE_URL + urlConstants.AUTH_PROFILE_URL + userid
  return Api.get(url,{headers: {Authorization : `Bearer ${accessToken}`}}).then(res=>{
    let data=res.data.first_name;
    return data;
  }).catch(err =>{
    if(String(err).includes('401'))
      {
          logger.debug(err);
          pushlogout();
      }
      else
          logger.debug(err);
  });
}
function checkAdmin(){
  let token = getToken();
  const accessToken = token;
  token=token?token.split('.')[1]:'';
  const userid = JSON.parse(atob(token?token:'', 'base64').toString('ascii')).user_id;
  const url = urlConstants.SITE_URL + urlConstants.AUTH_PROFILE_URL + userid
  return Api.get(url,{headers: {Authorization : `Bearer ${accessToken}`}}).then(res=>{
    let data=res.data.is_superuser;
    return data;
  }).catch(err =>{
    if(String(err).includes('401'))
      {
          logger.debug(err);
          pushlogout();
      }
      else
          logger.debug(err);
  });
}
function userProfile(){
  let token = getToken();
  const accessToken = token;
  token=token?token.split('.')[1]:'';
  const userid = JSON.parse(atob(token?token:'', 'base64').toString('ascii')).user_id;
  const url = urlConstants.SITE_URL + urlConstants.AUTH_PROFILE_URL + userid
  return Api.get(url,{headers: {Authorization : `Bearer ${accessToken}`}}).then(res=>{
    return res.data;
  }).catch(err =>{
    if(String(err).includes('401'))
      {
          logger.debug(err);
          pushlogout();
      }
      else
          logger.debug(err);
  });
}