import classes from './HeaderNavigation.module.css';
import { FaUserCircle, FaRegBell, FaShareSquare } from 'react-icons/fa';
import {useEffect, useState} from 'react';
import { useNavigate, Link } from "react-router-dom";
import { userService } from '@Services';
import { Tooltip as ReactTooltip } from 'react-tooltip';


function HeaderNavigation() {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('SMC User');
    useEffect(()=>{
        userService.getUserName().then((name)=>{
            setUserName(name);
        });
    },[]);
    function logoutHandler() {
        navigate("/logout");
    }
    return (
        <div id="user" className={classes.headerNavigation}>
            <div className={classes.userGreeting}>Hello, {userName}</div>
            <div className={classes.userActions}>
                <div className={classes.userOnlineStatus}>
                    <FaUserCircle id="user_details"/>
                    <div className={classes.submenu}>
                        <Link to={'/user/details'}>User Details</Link>
                        <Link to={'/changepassword'}>Change Password</Link>
                    </div>
                </div>
                <div><FaRegBell /></div>
                <div><FaShareSquare data-for='happyFace' data-tip='click to logout' onClick={logoutHandler}/></div>
            </div>
            <ReactTooltip id='happyFace' place='bottom' textColor='#FFFFFF' backgroundColor='#303d5099' effect='float' />  
        </div>
    )
}
export default HeaderNavigation