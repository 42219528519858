import ReactECharts from 'echarts-for-react';
import {options} from './options';

function Chart() {
    return (
        <ReactECharts 
            option={options}
            style={{width:"100%", height:"100%"}}
        />
    )
}
export default Chart