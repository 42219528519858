import React, { useState, useRef } from 'react';
import classes from './ChangePassword.module.css';
import {FaCheckCircle} from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { userService } from '@Services';
import { useNavigate } from "react-router-dom";

function ChangePassword() {
    const navigate = useNavigate();
    const [submissionStatus, setSubmissionStatus] = useState(false);
    const oldpasswordInputRef = useRef();
    const newpasswordInputRef = useRef();
    const confirmpasswordInputRef = useRef();

    function handleSubmit(event){
        event.preventDefault();
        const enteredCurrentPassword = oldpasswordInputRef.current.value;
        const enteredNewPassword = newpasswordInputRef.current.value;
        const enteredConfirmPassword = confirmpasswordInputRef.current.value;
        if(enteredNewPassword === enteredConfirmPassword){
            userService.changePassword(enteredCurrentPassword, enteredNewPassword).then((response)=>{
                if (response.status===200) {
                    setSubmissionStatus(true);
                    navigate("/logout");
                }
                else{
                    setSubmissionStatus(false);
                }
            });
        }
        else{
            alert('New Password and Confirm Password are not same');
        }
    }

    return (
        <React.Fragment>
            <div className={classes.wrapper}>
                <div className={classes.inputField}>
                    <label htmlFor="oldPassword" style={{fontSize:'inherit'}}>Old Password :</label>
                    <input
                    type="password"
                    id="oldPassword"
                    placeholder="Your Old Password"
                    size="30"
                    autocomplete="off"
                    ref={oldpasswordInputRef}
                    />
                </div>
                <div className={classes.inputField}>
                    <label htmlFor="newPassword" style={{fontSize:'inherit'}}>New Password :</label>
                    <input
                    type="password"
                    id="newPassword"
                    placeholder="Your New Password"
                    size="30"
                    autocomplete="off"
                    ref={newpasswordInputRef}
                    />
                </div>
                <div className={classes.inputField}>
                    <label htmlFor="confirmPassword" style={{fontSize:'inherit'}}>Confirm Password :</label>
                    <input
                    type="password"
                    id="confirmPassword"
                    placeholder="Confirm Your Password"
                    size="30"
                    autocomplete="off"
                    ref={confirmpasswordInputRef}
                    />
                </div>
                <div className={classes.right}>
                    {submissionStatus?<FaCheckCircle size={35}/>:null}
                    <Button variant='secondary' color='primary' 
                        size='small' onClick={handleSubmit} >
                            Submit
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ChangePassword