import React from "react";

import classes from "./Accordion.module.css";

function Accordion(props) {
 return (
    <div className={classes.accordion}>
        {props.children}
    </div>
 );
}

export default Accordion;