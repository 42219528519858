import {useEffect} from 'react';

import {Container, Row, Col} from 'react-bootstrap';
function Test() {

    useEffect(() => {
      // console.log("Hello")

    }, [])

    const handleClick = () => {
        alert("Clicked!!!!!")
    }

    return (
        <div>
            <Container fluid>
                <Row>
                    <Col md={4}>
                        <button onClick={handleClick}>Hello</button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Test