import React, { useState, useEffect } from 'react';
import { userService } from '@Services';

function UserDetailsPage(){
    
    const [userData, setUserData] = useState();

    useEffect(()=>{
        userService.userProfile().then((userData)=>{
            setUserData(userData)
        })
    },[]);

    return(
        <div>
            {userData?
            <div>
                <div style={{display: 'flex'}}>
                    <h3>User ID :</h3>
                    <h3>{userData.username}</h3>
                </div>
                <div style={{display: 'flex'}}>
                    <h3>First Name :</h3>
                    <h3>{userData.first_name}</h3>
                </div>
                <div style={{display: 'flex'}}>
                    <h3>Last Name :</h3>
                    <h3>{userData.last_name}</h3>
                </div>
                <div style={{display: 'flex'}}>
                    <h3>Email :</h3>
                    <h3>{userData.email}</h3>
                </div>
                <div style={{display: 'flex'}}>
                    <h3>User Type :</h3>
                    <h3>{userData.is_superuser?"Admin User":"Standard User"}</h3>
                </div>
            </div>
            :null}
        </div>
    );
}

export default UserDetailsPage;