import {userService} from '@Services';
import {urlConstants } from '@Constants';
import Api from '@Api/SMCApi';
import { logger } from '@Utilities';
export const smcAdminService = {
    getWeightage,
    setWeightage,
    getUseCaseMap,
    setUseCaseMap,
};
function logout(){
  let url = window.location.href;
  url = url.split('/')[0];
  if(url.startsWith('http://localhost') || url.startsWith(urlConstants.BASE_URL.split(':')[0]))
  {
    window.location.href = url+'/logout';
  }
}
function getWeightage(weightageType){
  const url=urlConstants.SITE_URL+'/'+urlConstants.ADMIN_URL+weightageType+'-weightage/';
  const token=userService.getToken();
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then((res)=>{
    return res;
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}
function setWeightage(weightageType,payload){
  const url=urlConstants.SITE_URL+'/'+urlConstants.ADMIN_URL+weightageType+'-weightage/';
  const token=userService.getToken();
  return Api.post(url,payload,{headers: {Authorization : `Bearer ${token}`}}).then((res)=>{
    return res;
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}
function getUseCaseMap(){
  const url=urlConstants.SITE_URL+'/'+urlConstants.ADMIN_URL+'subcategory-map/';
  const token=userService.getToken();
// logger.debug(url);
  return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then((res)=>{
    return res;
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}
function setUseCaseMap(payload){
  const url=urlConstants.SITE_URL+'/'+urlConstants.ADMIN_URL+'subcategory-map/';
  const token=userService.getToken();
// logger.debug(url);
  return Api.post(url,payload,{headers: {Authorization : `Bearer ${token}`}}).then((res)=>{
    return res;
  }).catch((error)=>{
    if(String(error).includes('401'))
    {
        logger.debug(error);
        logout();
    }
    else
        logger.debug(error);
  })
}