import ScoreGauge from '@CommonComponents/ScoreGauge/ScoreGauge';
import React from 'react';
import {Row, Col} from 'react-bootstrap';
import ScoreArrow from '@CommonComponents/ScoreArrow/ScoreArrow';
import { useNavigate } from "react-router-dom";
import classes from './CategoryRow.module.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';

function CategoryRow(props) {
    const navigate = useNavigate();
    // const disableClick = props.disableClick;
    function showSubCategories(subcategory) {
        const url = "/sub-categories/"+subcategory
      // console.log(url);
      navigate(url);
    }
    return (
        <Row>
            <Col md={2} style={{borderRight:'1px dashed rgba(255, 255, 255, 0.2)'}}>
                <ScoreGauge 
                    value={props.score} 
                    category={props.category}
                    style={{ width: 200 }} 
                />
            </Col>
            <Col md={10} style={{display:"grid", alignItems:"center"}}>
                <div className={classes.scoreFlexWrap}>
                    <React.Fragment>
                        {props.data.map((data, i) => (
                            <div 
                                key={i} 
                                style={{position:"relative"}}
                                data-for='category'
                                data-tip=  {`Click me to get usecase details under ${data.subcategory}`}
                                onClick={()=>showSubCategories(data.subcategory)}>
                                <ScoreArrow 
                                    score={data.score} 
                                    subcategory={data.subcategory} 
                                    change={data.change}
                                    key={i}
                                />
                                <div className={classes.separator}></div>
                            </div>
                        ))}
                    </React.Fragment>
                </div>
                <ReactTooltip id='category' place='bottom' textColor='#FFFFFF' backgroundColor='#303d5099' effect='float' />
            </Col>
        </Row>
    )
}
export default CategoryRow