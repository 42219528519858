// import React from 'react';
import Modal from 'react-bootstrap/Modal';


function MappingTable(props){
    return(
        <div>
            <Modal
            show={props.open}
            onHide={props.close}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable={true} /*className={classes.dialog}*/
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Summary
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table> 
                        {/* <TableHead> */}
                            <tr>
                                {props.tableHead.map((head, i) => (
                                    <th key={i}>{head}</th>
                                ))}
                            </tr>
                        {/* </TableHead> */}

                        {/* <TableBody> */}
                            {props.tableData.map((row, i)=>(
                                <tr key={i}>
                                    {row.map((cell, j) => (
                                        <td key={j}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                        {/* </TableBody> */}
                    </table>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default MappingTable