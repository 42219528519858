import React from 'react';
// import TabContainer from 'react-bootstrap/TabContainer';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { commonService } from '@Services';
import InsightTechnology from './InsightTechnology';
import tabStyles from '@Utilities/tabs.module.css';
import CustomizableTechnologyInsightPage from './CustomizableTechnologyInsightPage/CustomizableTechnologyInsightPage';

function InsightTechnologyTabPage(props){

    return (
        <div style={{height:"100%"}}>
            {props.data?
                <Tabs
                    id="controlled-tab-example"
                    defaultActiveKey={props.data[0].name}
                    className="mb-3 border-0"
                >
                    {props.data.map( (techData,i) => (
                        <Tab 
                            key={i} 
                            eventKey={techData.name} 
                            title={commonService.capitalizeletterWithDash(techData.name)}
                            tabClassName={tabStyles.tabNavLink}
                        >
                            {techData.automatic?
                                <InsightTechnology techName={techData.name} style={{height:"100%"}}/>
                            :<CustomizableTechnologyInsightPage techName={techData.name} /> }
                        </Tab>
                    ))}
                </Tabs>
            :null}
        </div>
  );
}
export default InsightTechnologyTabPage;