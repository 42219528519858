import React, {useEffect} from "react";
import { dataService } from '@Services';
import { useNavigate } from "react-router-dom";


const ExecutiveReportPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        dataService.getExecutiveSummary();
        navigate(-1);
    },[])
    return(
        <React.Fragment/>
    );
}

export default ExecutiveReportPage;