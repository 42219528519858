import React from 'react';
import { useEffect, useState } from 'react';
import classes from './Dashboard.module.css';
import { Container, Row, Col } from 'react-bootstrap';
import SecurityIncidents from './SecurityIncidents/SecurityIncidents';
import TechnologyTrend from './TechnologyTrend/TechnologyTrend';
import BoxGroup from './BoxGroup/BoxGroup';
import { smcUtility } from '@Utilities';
import HealthMonitoring from './HealthMonitoring/HealthMonitoring';
import ThreeCasesTechnology from './ThreeCasesTechnology/ThreeCasesTechnology';
import { userService } from '@Services';
import Guide from '@Containers/Guide/Guide';
import Modal from 'react-bootstrap/Modal';
// import TwoCasesTechnology from './TwoCasesTechnology/TwoCasesTechnology';
// import OneCasesTechnology from './OneCasesTechnology/OneCasesTechnology';

function Dashboard() {
    const [showIncident, setShowIncident] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDemo, setShowDemo] = useState(false);

    useEffect(()=>{
        smcUtility.smcSetting().then((data)=>{
            setShowIncident(data.incident.show);
        });
        userService.checkFirstLogin().then((status)=>{
            setShowModal(!status);
        });
    },[]);
    
    function skipTour(){
        userService.updateSkipTour().then((res)=>{
            if(res.status === 200)
            {
                setShowDemo(false);
                setShowModal(false);
            }
        });
    }

    return (
        <Container className={classes.dashboardWrapper} fluid>
            <div>
                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable={true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Would you like a walkthrough?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{padding:"2px"}}>
                            <button 
                                style={{border:"solid ",borderWidth:"2px",padding:"2px", textAlign: "center",fontSize: "13px",}}
                                onClick={()=>{setShowDemo(true);setShowModal(false)}}
                            >
                                Yes
                            </button>
                            <button 
                                style={{border:"solid ",borderWidth:"2px",padding:"2px", textAlign: "center",fontSize: "13px",}}
                                onClick={skipTour}
                            >
                                No
                            </button>
                            <button 
                            style={{border:"solid ",borderWidth:"2px",padding:"2px", textAlign: "center",fontSize: "13px",margin:"5px"}}
                            onClick={()=>{setShowDemo(false);setShowModal(false)}}
                            >
                                Remind Me Later
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            <Row style={{ height: "52%", marginBottom: "1%" }}>
                <Col md={8} style={colStyle}>
                    <ThreeCasesTechnology/>
                    {/* <TwoCasesTechnology/> */}
                    {/* <OneCasesTechnology/> */}
                </Col>
                <Col md={4} style={colStyle}>
                    <BoxGroup />
                </Col>
            </Row>
            <Row  style={{ height: "45%" }}>
                <Col md={showIncident?4:8} style={colStyle}>
                    <TechnologyTrend />
                </Col>
                <Col md={4} style={colStyle}>
                    <HealthMonitoring />
                </Col>
                {showIncident?
                <Col md={4} style={colStyle}>
                    <SecurityIncidents />
                </Col>
                :null}
            </Row>
            {showDemo?<Guide />:null}
        </Container>
    )
}

const colStyle = {
    height: "100%",
    padding: "0"
}

export default Dashboard