import React from 'react';
import MainWrapper from '../MainWrapper/MainWrapper';
import {Navigate} from 'react-router-dom';

const Home = (props) => {

    const isLoggedIn = parseInt(sessionStorage.getItem('isLoggedIn'));

    return (
        <React.Fragment>
            {isLoggedIn===1
                ? <MainWrapper />
                : <Navigate to="/login" />}
        </React.Fragment>
    );
}

export default Home
