import React from 'react'
import ReactMap from './ReactMap/ReactMap';
import Card from '@CommonComponents/Card/Card';
import {Container, Row} from 'react-bootstrap';

function Map(props){

    const chartName = {
        fontSize:"1.3rem",
        padding: "5px 0 5px 10px",
        boxShadow:"0 1px 5px #000",
        position:"relative"
    }
  // console.log(props.data);
    return (
        <div>
            <Container style={{padding:0}} fluid>
                <Row style={{marginBottom:"15px",marginLeft:"5px",marginTop:"20px"}}>
                    <Card>
                        <div className={chartName}>
                            <ReactMap/>
                            <h4 style={{textAlign: 'center'}}>Location wise Instances</h4>
                        </div>
                    </Card>
                </Row>
            </Container>
        </div>
    );
}

export default Map;