import { urlConstants } from "@Constants"
import { userService, commonService } from "@Services"
import Api from "@Api/SMCApi"
import { logger } from '@Utilities';


export const annotateService={
    annotate, annotateWithWhereClause, getTechStartDate
}

function logout(){
    let url = window.location.href;
    url = url.split('/')[0];
    if(url.startsWith('http://localhost') || url.startsWith(urlConstants.BASE_URL.split(':')[0]))
    {
        window.location.href = url+'/logout';
    }
}

function annotateWithWhereClause(technology, params, columnName, startDate){
    let url=urlConstants.SITE_URL
    url=url+urlConstants[technology]+'annotate?'
    url=url+'columnName='+columnName+'&whereClause='+params+'&startDate='+startDate
  // logger.debug(url)
    const token=userService.getToken();
    return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
        if(res.status!==401)
            return res.data
        else
            logout();
    }).catch((error)=>{
        if(String(error).includes('401'))
        {
            logger.debug(error);
            logout();
        }
        else
            logger.debug(error);
      })
}
function annotate(technology,startDate,usecase=''){
        return fetchAnotate(technology,startDate,usecase)
}
function fetchAnotate(technology,startDate,usecase){
    technology=technology.toLowerCase()
    let endDate=commonService.getNextQuarterDate(startDate);
    let url=urlConstants.SITE_URL
    url=url+urlConstants[technology]+'annotate?'
    if(usecase)
        url=url+'columnName='+usecase+'&startDate='+startDate+'&endDate='+endDate
    else
        url=url+'startDate='+startDate+'&endDate='+endDate
  // logger.debug(url)
    const token=userService.getToken();
    return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
        if(res.status!==401)
            return res.data
        else
            logout();
    }).catch((error)=>{
        if(String(error).includes('401'))
        {
            logger.debug(error);
            logout();
        }
        else
            logger.debug(error);
      })
}
function getTechStartDate(technology){
    technology=technology.toLowerCase()
    let url=urlConstants.SITE_URL
    url=url+urlConstants[technology]+'?limit=1'
    const token=userService.getToken();
    return Api.get(url,{headers: {Authorization : `Bearer ${token}`}}).then(res=>{
        if(res.status!==401)
        {
            let date=new Date(res.data[0].time)
            date=getNowStartDate(date);
            logger.debug(date)
            localStorage.removeItem('techStartDate')
            localStorage.setItem('techStartDate',JSON.stringify(date))
            return date;
        }
        else
            logout();
    }).catch((error)=>{
        if(String(error).includes('401'))
        {
            logger.debug(error);
            logout();
        }
        else
            logger.debug(error);
      })
}
function getNowStartDate(date=''){
  // logger.debug(date)
    let dd = new Date().getUTCDate();
    let mm = new Date().getUTCMonth() + 1;
    let yyyy = new Date().getUTCFullYear();
    let h = new Date().getUTCHours();
    if(date){
        dd = date.getUTCDate();
        mm = date.getUTCMonth() + 1;
        yyyy = date.getUTCFullYear();
        h = date.getUTCHours();
        let m = date.getUTCMinutes();
        let s = date.getUTCSeconds();

        if (mm < 10) mm = '0' + mm
        if (dd < 10) dd = '0' + dd
        if(h<10) h='0'+h;
        if(m<10) m='0'+m;
        if(s<10) s='0'+s;
        date=yyyy+'-'+mm+'-'+dd+' '+h+':'+m+':'+s
        return date;
    }
    if (mm < 10) mm = '0' + mm
    if (dd < 10) dd = '0' + dd
    if(h<10) h='0'+h;
    date=yyyy+'-'+mm+'-'+dd+' 00:00:00'
    switch(true){
        case h >= 0 && h < 6: date=yyyy+'-'+mm+'-'+dd+' 00:00:00';break;
        case h >= 6 && h < 12: date=yyyy+'-'+mm+'-'+dd+' 06:00:00';break;
        case h >= 12 && h < 18: date=yyyy+'-'+mm+'-'+dd+' 12:00:00';break;
        case h >= 18 && h < 24: date=yyyy+'-'+mm+'-'+dd+' 18:00:00';break;
        default: date=yyyy+'-'+mm+'-'+dd+' 00:00:00'; break;
    }
    return date;
}