import React,{useState} from 'react';
import styles from "@Utilities/scrollbar.module.css";
import Accordion from '@CommonComponents/Accordion/Accordion';
import AccordionItem from '@CommonComponents/Accordion/AccordionItem';
import classes from './usecase.module.css';
import { smcAdminService } from '@Services';
import Button from 'react-bootstrap/Button';
import {FaCheckCircle} from 'react-icons/fa';

function SubcatWeightagePage(props){
    const data = props.data;
    const [payload, setPayload] = useState(data);
    const [validationRequiredFlag, setValidationRequiredFlag] = useState(false);
    const [changedFieldParent, setChangedFieldParent] = useState();
    const [changedField, setChangedField] = useState();
    const [changedValue, setChangedValue] = useState();
    const [validationFailed, setValidationFailed] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState(false);

    function handleInputFieldChange(event){
        if(event.target.value)
        {
            setChangedFieldParent(event.target.parentElement.id);
            setChangedField(event.target.id);
            setChangedValue(event.target.value);
            setValidationRequiredFlag(true);
        }
        else
        {
            setValidationRequiredFlag(false);
        }
    }
    function validateInputFieldChange(event){
        // console.log(validationRequiredFlag, changedField, changedValue);
        if(validationRequiredFlag){
            let toEditPayload = payload;
            toEditPayload[changedFieldParent][changedField] = parseInt(changedValue);
          // console.log(toEditPayload);
            let totalScore = 0;
            Object.keys(toEditPayload[changedFieldParent]).map((usecase)=>{
                const score = toEditPayload[changedFieldParent][usecase];
                totalScore+=score;
                return 0;
            });
            if(totalScore===100)
            {
                setPayload(toEditPayload);
                setValidationFailed(false);
            }
            else
            {
                alert("Please Make Sure, Total Score Should be Equal to 100!!!");
                setPayload(toEditPayload);
                setValidationFailed(true);
            }
            setValidationRequiredFlag(false);
        }
    }
    function handleSubmit(){
        if(validationFailed)
        {
            alert("Total Score Should be Equal to 100, to procceed with Submit!!!");
        }
        else
        {
            const submitPayload={
                subcat_weightage: payload
            }
          // console.log(submitPayload);
            smcAdminService.setWeightage('subcat',submitPayload).then((response)=>{
              // console.log(response);
                if(response.status === 201)
                    setSubmissionStatus(true);
            });
        }
    }

    return(
        <div className={styles.scrollbar_smc}>
           {data?
            <React.Fragment>
                <div style={{maxWidth: '700px'}}>
                <Accordion>
                    {Object.keys(data).map((categories, i)=>(
                        <AccordionItem title={categories} key={i}>
                            <div style={{maxWidth: '600px'}}>
                            {Object.keys(data[categories]).map((subcategory, j)=>(
                                <div className={classes.inputField} key={j} id={categories}>
                                <label style={{color: 'black'}} htmlFor={subcategory}>{subcategory} :</label>
                                <input
                                type="number"
                                id={subcategory}
                                placeholder={data[categories][subcategory]}
                                onChange={handleInputFieldChange}
                                onBlur={validateInputFieldChange}
                                />
                                </div>
                            ))}
                            </div>
                        </AccordionItem>
                    ))}
                </Accordion>
                <div className={classes.right}>
                        {submissionStatus?<FaCheckCircle size={35}/>:null}
                        <Button variant='secondary' color='primary' 
                            size='small' onClick={handleSubmit} >
                                    Submit
                        </Button>
                    </div>
                </div>
            </React.Fragment>
            :null}
        </div>
    );
}

export default SubcatWeightagePage;