import { deviceService, commonService } from '@Services';

export const csvService = {
    makeCsv,
    technologyCSV
};
function technologyCSV(technology,totalDeviceCount,filename){
    deviceService.getTechDataStartDate(technology).then((startDate)=>{
        deviceService.fetchDevicesWithLimit(technology,startDate,commonService.getNextQuarterDate(startDate),totalDeviceCount).then((response)=>{
            // console.log(data);
            let items = []
            const lng = response.length - 1;
            for (const [index, value] of response.entries()) {
                // items.push(<Element key={index} />)
                let data = {}
                // logger.debug(value);
                const length = Object.keys(value).length - 1;
                Object.keys(value).map((fieldKey,i)=>{
                    // logger.debug(value[fieldKey]);
                    if(value[fieldKey] && typeof value[fieldKey] === 'object' && !Array.isArray(value[fieldKey]))
                    {
                        if(value[fieldKey].hasOwnProperty('compliance_score'))
                        {
                            const complianceScore = value[fieldKey]['compliance_score'];
                            let complianceStatus = 'Non-Compliant';
                            if(complianceScore && parseInt(complianceScore)>79)
                            {
                                complianceStatus = 'Compliant';
                            }
                            else if(complianceScore && parseInt(complianceScore)>69)
                            {
                                complianceStatus = 'Risky';
                            }
                            const complianceScoreFieldName = fieldKey + '_compliance_score';
                            const complianceStatusFieldName = fieldKey + '_compliance_status';
                            data = {
                                ...data,
                                [fieldKey]: value[fieldKey]['data'],
                                [complianceScoreFieldName]: parseInt(complianceScore),
                                [complianceStatusFieldName]: complianceStatus
                            }
                        }
                        else
                        {
                            data = {
                                ...data,
                                [fieldKey]: value[fieldKey]
                            }
                        }
                    }
                    else
                    {
                        data = {
                            ...data,
                            [fieldKey]: value[fieldKey]
                        }
                    }
                    if(length === i)
                    {
                        items.push(data);
                        if(lng === index)
                            makeCsv(items,filename);
                    }
                    return 0;
                })   
            }
        })
    });
}
function makeCsv(data,filename){
    let csvContent = "data:text/csv;charset=utf-8,";
    let header=Object.keys(data[0]);
    header.map(h=>csvContent+=h+',')
    csvContent+='\r\n'
    data.map(device=>{
        let row="";
        header.map(key=>{
            try {
                row+=JSON.stringify(device[key]).split(',').join(' ')+','
            } catch (error) {
                row+=','
            }   
            return 0;
        })
        csvContent+=row+'\r\n';
        return 0;
    })
    // logger.debug(csvContent)
    let encodedUri = encodeURI(csvContent);
    let link=document.createElement('a')
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename+'.csv');
    link.click();
    link.remove();
}
