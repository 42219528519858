import React from 'react';
import Card from '@CommonComponents/Card/Card';
import {Container, Row, Col} from 'react-bootstrap';
import NumberOfIncidents from './NumberOfIncidents/NumberOfIncidents';
import EventsCollected from './EventsCollected/EventsCollected';
import SourceOfEvents from './SourceOfEvents/SourceOfEvents';
import ErroniousReports from './ErroniousReports/ErroniousReports';

function SMAPage(props){
    const chartName = {
        fontSize:"1.3rem",
        padding: "5px 0 5px 10px",
        boxShadow:"0 1px 5px #000",
        position:"relative"
    }

    return (
        <React.Fragment>
            <h3 style={{color:"#7ed8f3"}}>Security Monitoring and Analytics</h3>
            <Container style={{padding:0}} fluid>
                <Row style={{marginLeft:"0px",marginBottom:"15px",marginRight:'5px'}} >
                    <Col md={3}>
                        <Card>
                            <div className={chartName}>
                                <NumberOfIncidents />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>No. of Incidents</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <div className={chartName}>
                                <EventsCollected />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>Events Collected</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <div className={chartName}>
                                <SourceOfEvents />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>Source Of Events</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <div className={chartName}>
                                <ErroniousReports />
                                <h4 style={{textAlign: 'center', paddingBottom: '10px', fontSize: '1em'}}>No. of Erronious Reports</h4>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default SMAPage;