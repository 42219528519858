import React,{useEffect, useState} from 'react';
import { userService } from '@Services';
import { Routes, Route } from 'react-router-dom';
import UseCaseMapPage from './components/UseCaseMapPage/UseCaseMapPage';
import WeightagePage from './components/WeightagePage/WeightagePage';
import BaselineConfigPage from './components/BaselineConfigPage/BaselineConfigPage';


function AdminPage(){
    const [adminStatus, setAdminStatus] = useState(false);
    useEffect(()=>{
        userService.checkAdmin().then((response)=>{
            setAdminStatus(response);
        });
    },[]);
    return(
        <React.Fragment>
            {adminStatus?
            <Routes>
                <Route path="/usecasemap" element={<UseCaseMapPage/> } />
                <Route path="/weightage" element={<WeightagePage/> } />
                <Route path="/baseline" element={<BaselineConfigPage/> } />
            </Routes>
            :<div>Sorry !!! You don't have Admin Priviledge</div>}
        </React.Fragment>
    );
}

export default AdminPage;