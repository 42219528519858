// import React from 'react';
import ReactEcharts from 'echarts-for-react';

function CpuUsagePie(props){

    const option = {
        tooltip: {
            trigger: 'item',
            formatter: '{b}: {d}%'
        },
        legend: {
            orient: 'vertical',
            show: false,
            left: 0,
            top:10,
            textStyle: {
                color: '#fff'
            },
            data: ['0-39%', '40-69%', '70-100%']
        },
        series: [
            {
                type: 'pie',
                label: {
                    show: true,
                    position: 'inner',
                    formatter: '{b}: {d}%',
                },
                data: [
                    {
                        value: 70, 
                        name: '0-39%',
                        itemStyle: {
                            color: 'rgb(42, 187, 67)'
                        }
                    },
                    {
                        value: 25,
                        name: '40-69%',
                        itemStyle: {
                            color: '#ffbf00'
                        }
                    },
                    {
                        value: 5,
                        name: '70-100%',
                        itemStyle: {
                            color: '#e63c3c'
                        }
                    }
                ]
            }
        ]
    };

    return(
        <ReactEcharts style={{height:250,width:'100%'}} option={option} />
    )
}
export default CpuUsagePie