import { dataConstants, systemNameConstants, primaryKeyConstants } from '@Constants'
import { smcUtility } from '@Utilities';
import LZString from 'lz-string';
export const commonService={
    arrowCheck,
    colorByScore,capitalizeletterWith_,
    classifyDeviceCount,classifySystemName,
    getDay,capitalizeletterWithDash,
    getLastStartDate,getNowStartDate,getNextQuarterDate,
    descriptionInfo,classifyDeviceType,
    classifyIMPFilter,technologyKnownAs,
    storeDate,capitalizeSentence,
    tempStartDate:getNowStartDate(new Date()),
    avalibleTechnologies,classifyTechnology,
    impUseCaseDetails,classifyPrimaryKey,
    colorCodeBasedOnScore,colorCodeBasedOnType
}
function colorCodeBasedOnScore(score){
    try{
        if (parseInt(score) < 70) return '#C02D2D'
        else if (parseInt(score) <= 80) return '#FFBF00'
        else return '#1EB41E'
    }
    catch{
        return '#1EB41E'
    }
}
function colorCodeBasedOnType(type){
    try{
        type = type.toLowerCase();
        if (type === 'critical') return '#C02D2D'
        else if (type === 'medium') return '#ffbf00'
        else return '#1EB41E'
    }
    catch{
        return '#1EB41E'
    }
}
function technologyKnownAs(technology){
    technology = technology.toLowerCase();
    if(dataConstants.technologyKnownAs[technology]) return dataConstants.technologyKnownAs[technology];
    return capitalizeletterWithDash(technology)
}
function impUseCaseDetails(usecase) {
    if(dataConstants.impUseCaseDetails[usecase])
    {
        return dataConstants.impUseCaseDetails[usecase];
    }
    return capitalizeletterWith_(usecase);
}
function avalibleTechnologies() {
    smcUtility.smcSetting().then((data)=>{
        let allTech = [];
        let techList = data.technologyList.child;
        Object.keys(techList).map(tech=>{
            if(techList[tech].show) allTech.push(tech)
            return 0;
        }) 
        return Promise.resolve(allTech);
    });
}
function classifyDeviceType (name) {
    name = name.toLowerCase();
    if(dataConstants.technologyWiseDeviceType[name]) return capitalizeletterWith_(dataConstants.technologyWiseDeviceType[name]);
    return "Device";
}
function classifyTechnology (name) {
    name = name.toLowerCase();
    if(dataConstants.technologyCategorization[name]) return capitalizeletterWith_(dataConstants.technologyCategorization[name][0]);
    return "Security";
}
function capitalizeletterWithDash (name) {
    if(dataConstants.usecaseNameMap[name]) return dataConstants.usecaseNameMap[name];
    let str='';
    name = name.toLowerCase();
    name = name.replaceAll("-"," ");
    name = name.split(" ");
    name.map(string=>{
        if(string)
        {
            if(dataConstants.shorthandErrorMap[string])
            {
                str+=dataConstants.shorthandErrorMap[string]+' ';   
            }
            else{
                str+=string.charAt(0).toUpperCase() + string.slice(1)+' ';
            }
        }
        return 0;
    })
    return str;
}
function capitalizeletterWith_ (name) {
    if(dataConstants.usecaseNameMap[name]) return dataConstants.usecaseNameMap[name];
    let str='';
    name = name.toLowerCase();
    name = name.replaceAll("_"," ");
    name = name.split(" ");
    name.map(string=>{
        if(string)
        {
            if(dataConstants.shorthandErrorMap[string])
            {
                str+=dataConstants.shorthandErrorMap[string]+' ';   
            }
            else{
            str+=string.charAt(0).toUpperCase() + string.slice(1)+' ';
            }
        }
        return 0;
    })
    return str;
}
function capitalizeSentence (name) {
    let str='';
    name = name.toLowerCase();
    name = name.split(" ")
    name.map(string=>{
        if(dataConstants.shorthandErrorMap[string])
        {
            str+=dataConstants.shorthandErrorMap[string]+' ';   
        }
        else{
        str+=string.charAt(0).toUpperCase() + string.slice(1)+' ';
        }
        return 0;
    })
    return str;
}
function arrowCheck(scoreA,scoreB, classes){
    if(scoreA>scoreB){
        return <div className={classes.arrowDown}>?</div>;
    };
    return <div className={classes.arrowUp}>?</div>;
}
function colorByScore(score){
    if(score>=80) return 'green'
    if(score>=50) return 'yellow'
    return 'red'
}
function descriptionInfo(string){
    string=string.toLowerCase()
    if(dataConstants.descriptionMap[string]) return dataConstants.descriptionMap[string]
    return capitalizeletterWith_(string);
}
function classifySystemName(techName){
    techName=techName.toLowerCase()
    if(systemNameConstants[techName])
        return systemNameConstants[techName]
    return 'system_name'
}
function classifyPrimaryKey(techName){
    techName=techName.toLowerCase()
    if(primaryKeyConstants[techName])
        return primaryKeyConstants[techName]
    return 'system_name'
}
function classifyDeviceCount(techName){
    if(dataConstants.deviceCountMap[(techName).toLowerCase()])
        return dataConstants.deviceCountMap[(techName).toLowerCase()]
    return 'device_count'
}
function classifyIMPFilter(techName){
    if(dataConstants.impFilterMap[(techName).toLowerCase()])
        return dataConstants.impFilterMap[(techName).toLowerCase()]
    return ',time,system_name,ip_address,'
  }
function getDay(time){
    const sDay=['Mon','Tue','Wed','Thu','Fri','Sat','Sun']
    const day=new Date(time).getDay();
    return sDay[day-1];
}
function getLastStartDate(date){
    date=new Date(date)
    date.setTime(date.getTime()-6*60*60*1000)
    let dd=date.getDate(),mm=date.getMonth()+1,yy=date.getFullYear();
    let hh=date.getHours()
    dd=dd<10?'0'+dd:dd;
    mm=mm<10?'0'+mm:mm;
    hh=hh<10?'0'+hh:hh;
    date=yy+'-'+mm+'-'+dd+' '+hh+':00:00'
    return date;
}
function getNowStartDate(date=''){
  // logger.debug(date);
    let dd = new Date().getUTCDate();
    let mm = new Date().getUTCMonth() + 1;
    let yyyy = new Date().getUTCFullYear();
    let h = new Date().getUTCHours();
    if(date){
        dd = date.getUTCDate();
        mm = date.getMonth() + 1;
        yyyy = date.getFullYear();
        h = date.getUTCHours();
    }
    if (mm < 10) mm = '0' + mm
    if (dd < 10) dd = '0' + dd
    if(h<10) h='0'+h;
    date=yyyy+'-'+mm+'-'+dd+' 00:00:00'
    switch(true){
        case h >= 0 && h < 6: date=yyyy+'-'+mm+'-'+dd+' 00:00:00';break;
        case h >= 6 && h < 12: date=yyyy+'-'+mm+'-'+dd+' 06:00:00';break;
        case h >= 12 && h < 18: date=yyyy+'-'+mm+'-'+dd+' 12:00:00';break;
        case h >= 18 && h < 24: date=yyyy+'-'+mm+'-'+dd+' 18:00:00';break;
        default: date=yyyy+'-'+mm+'-'+dd+' 00:00:00'; break;
    }
    return date;
}
function getNextQuarterDate(date){
    date=new Date(date)
    date.setTime(date.getTime()+6*60*60*1000)
    let dd=date.getDate(),mm=date.getMonth()+1,yy=date.getFullYear();
    let hh=date.getHours()
    dd=dd<10?'0'+dd:dd;
    mm=mm<10?'0'+mm:mm;
    hh=hh<10?'0'+hh:hh;
    date=yy+'-'+mm+'-'+dd+' '+hh+':00:00'
    return date;
}
function storeDate(technology,date){
    let storedDates=JSON.parse(LZString.decompressFromUTF16(localStorage.getItem('storedDates')))
    storedDates[technology]=date
    localStorage.setItem('storedDates',LZString.compressToUTF16(JSON.stringify(storedDates)))
}