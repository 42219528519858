import React from 'react';
import LocationWiseBarChart from './LocationWiseBarChart/LocationWiseBarChart';
import PieChart from './PieChart/Piechart';
import Card from '@CommonComponents/Card/Card';
import {Col} from 'react-bootstrap';

function Chart(props) {
    const chartName = {
        fontSize:"1.3rem",
        padding: "5px 0 5px 10px",
        boxShadow:"0 1px 5px #000",
        position:"relative"
    }

    return(
        <React.Fragment>
        
            <Col md={4}>
                <Card>
                    <div className={chartName}>
                        <LocationWiseBarChart data={props.barData}/>
                        <h4 style={{textAlign: 'center', fontSize: '1em'}}>Location wise Instances</h4>
                    </div>
                </Card>
            </Col>
            <Col md={4}>
                <Card>
                    <div className={chartName}>
                        <PieChart data={props.pieData1} center={['50%','50%']} title='VPC Groups'/>
                        <h4 style={{textAlign: 'center', fontSize: '1em'}}>VPC Groups</h4>
                    </div>
                </Card>
            </Col>
            <Col md={4}>
                <Card>
                    <div className={chartName}>
                        <PieChart data={props.pieData2} center={['50%','50%']} title='Security Groups'/>
                        <h4 style={{textAlign: 'center', fontSize: '1em'}}>Security Groups</h4>
                    </div>
                </Card>
            </Col>
            
        </React.Fragment>
    );
}
export default Chart;