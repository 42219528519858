import {useEffect,useState} from 'react';
import { dataService,commonService } from '@Services';
import MindMap from './MindMap';
import {LoadingFull} from '@CommonComponents/Loading/Loading';
import styles from '@Utilities/scrollbar.module.css';

function SMCMap() {
    const [smcMapData, setSmcMapData] = useState();
    
    useEffect(() => {
        let startDate = commonService.getNowStartDate();
        let smcMapData = {
            name: "Technology"
        }
        let smcChildData = [];
        dataService.fetchDataWithDate(startDate,false).then((data)=>{
                const tech = "Technology";
                const jLenghth = Object.keys(data[tech]).length - 1;
                Object.keys(data[tech]).map((category,j)=>{
                    let categoryData={
                        name: category,
                        value: data[tech][category]['score']
                    }
                    let categoryChild = [];
                    const pLenghth = Object.keys(data[tech][category].sub_categories).length - 1;
                    Object.keys(data[tech][category].sub_categories).map((sub_cat,p)=>{
                        let subCategoryData={
                            name: sub_cat,
                            value: data[tech][category].sub_categories[sub_cat]
                        }
                        let subCategoryChild = [];
                        dataService.fetchSubCategoryData(sub_cat,'',startDate,false).then((res)=>{
                            const qLenghth = Object.keys(res).length - 1;
                            Object.keys(res).map((technology_name,q)=>{
                                let technologyData={
                                    name: technology_name,
                                    value: res[technology_name].score
                                }
                                let technologyChild=[]
                                const mLength = Object.keys(res[technology_name].usecases).length - 1;
                                Object.keys(res[technology_name].usecases).map((usecase,m)=>{
                                    let useCaseData={
                                        name: usecase,
                                        value: res[technology_name].usecases[usecase]
                                    }
                                    technologyChild.push(useCaseData);
                                    if((mLength === m) && (q === qLenghth) && (p === pLenghth) && (j === jLenghth))
                                    {
                                        setSmcMapData(smcMapData);
                                    }
                                    return 0;
                                });
                                technologyData = {
                                    ...technologyData,
                                    children: technologyChild
                                }
                                subCategoryChild.push(technologyData);
                                return 0;
                            });
                        });
                        subCategoryData={
                            ...subCategoryData,
                            children: subCategoryChild
                        }
                        categoryChild.push(subCategoryData);
                        return 0;
                    });
                    categoryData={
                        ...categoryData,
                        children: categoryChild
                    }
                    smcChildData.push(categoryData);
                    return 0;
                });
                // techData = {
                //     ...techData,
                //     children: techChild
                // }
                // smcChildData.push(techData);
            // });
        });
        smcMapData = {
            ...smcMapData,
            children: smcChildData
        }
    }, [])

    return (
        <div className={styles.scrollbar_smc} style={{overflowX:"none"}}>
            {smcMapData ? 
                <MindMap data={smcMapData}/>
            :<LoadingFull />}
        </div>
    )
}
export default SMCMap