// import React from 'react';
import ReactEcharts from 'echarts-for-react';

function MFACompliance(props){

    const appList = {
        'Compliance': [
            'Adm Products',
            'AIML Pipeline',
            'CS Space',
            'EffortTracking'
        ],
        'Non-Compliance': [
            'AMADAS Server',
            'Comet Packaging',
            'Harp NG',
            'SSF Interfaces Java Dev',
            'Crowd Comfort1',
            'R&DIT EMT API POC'
        ]
    }

    const option = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 0,
            show: false,
            top:10,
            textStyle: {
                color: '#fff'
            },
        },
        series: [
            {
                name: 'Applications',
                type: 'pie',
                label: {
                    show: true,
                    position: 'inner',
                    formatter: '{d}%'
                },
                radius: '80%',
                data: [
                    {
                        value: 6, 
                        name: 'Non-Compliance',
                        itemStyle: {
                            color: '#e63c3c'
                        }
                    },
                    {
                        value: 4, 
                        name: 'Compliance',
                        itemStyle: {
                            color: 'rgb(42, 187, 67)'
                        }
                    },
                ],
            }
        ]
    };

    const onChartClick=(params)=>{
        props.openApplicationList(appList[params.data.name]);
    }

    return(
        <ReactEcharts 
            style={{height:250,width:'100%'}} 
            option={option} 
            onEvents={{'click':onChartClick}}
        />
    )
}
export default MFACompliance