import React from 'react';
import Chart from './Chart/Chart';

import dashboard from '../Dashboard.module.css';

function SecurityIncidents() {
    return (
        <div 
            className={dashboard.dashboardBoxWrapper}
            id = "SIR"
        >
            <h4 style={{color:"#cacaca", fontSize:"1em", borderBottom: "1px solid", paddingBottom:"10px"}}>Security Incidents</h4>
            <div>
                <Chart />
            </div>
        </div>
    )
}
export default SecurityIncidents