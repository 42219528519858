import ReactECharts from 'echarts-for-react';
import {commonService} from '@Services';

function DeviceChart(props) {
    const name = props.name ?? '';
    const data = props.data ?? null;
    const techName = props.tech;

    let chartData = []
    if(data.critical > 0)
    {
        const compData={
            name: 'Non-Compliance',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
                focus: 'series'
            },
            color: commonService.colorCodeBasedOnType('critical'),
            data: [data.critical]
        }
        chartData.push(compData);
    }
    if(data.medium > 0)
    {
        const compData={
            name: 'Risky',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            color: commonService.colorCodeBasedOnType('medium'),
            data: [data.medium]
        }
        chartData.push(compData);
    }
    if(data.low > 0)
    {
        const compData={
            name: 'Compliance',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            color: commonService.colorCodeBasedOnType('low'),
            data: [data.low]
        }
        chartData.push(compData);
    }

    const options = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          top: 'top',
          textStyle: {
            color: '#cacaca',
            fontFamily: 'monospace',
            fontSize: '10'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: [commonService.classifyDeviceType(techName)]
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: chartData
    };

    function onChartClick(){
      props.handleClick();
    }
    return (
        <div style={{width: '250px', justifyContent: "center"}}>
            <center>
            <ReactECharts 
                option={options}
                style={{
                    textAlign:"center",
                    width:"130px",
                    height:"250px"
                }} 
                onEvents={{'click':onChartClick}}
            />
            <p 
                style={{
                    textAlign:"center", 
                    fontSize:".8em",
                    color:"#cacaca"
            }}>{commonService.capitalizeletterWith_(name)}</p>
            </center>
        </div>
    )
}



export default DeviceChart