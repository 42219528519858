import React from 'react';
import LoginPage from '../Login/LoginPage';
import {useState} from 'react';
import {Navigate} from 'react-router-dom';

const LoginHandler = (props) => {

    const [isLoggedIn, setIsLoggedIn] = useState(parseInt(sessionStorage.getItem('isLoggedIn')));
    function loginHandler(value) {
        setIsLoggedIn(value);
    }
    return (
        <React.Fragment>
            {isLoggedIn!==1
                ? <LoginPage onLogin={loginHandler}/>
                : <Navigate to="/" />}
        </React.Fragment>
    );
}

export default LoginHandler


