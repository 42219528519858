// import React from 'react';
import ReactEcharts from 'echarts-for-react';

function AVCoverage(props){

    const option = {
        tooltip: {
            trigger: 'item',
            formatter: '{b}: {d}%'
        },
        legend: {
            orient: 'vertical',
            show: false,
            left: 0,
            top:10,
            textStyle: {
                color: '#fff'
            },
            data: ['Compliance', 'Non Compliance']
        },
        series: [
            {
                type: 'pie',
                label: {
                    show: true,
                    position: 'inner',
                    formatter: '{b}: {d}%'
                },
                data: [
                    {
                        value: 85, 
                        name: 'Compliance',
                        itemStyle: {
                            color: 'rgb(42, 187, 67)'
                        }
                    },
                    {
                        value: 15,
                        name: 'Non Compliance',
                        itemStyle: {
                            color: '#e63c3c'
                        }
                    }
                ]
            }
        ]
    };
      

    return(
        <ReactEcharts style={{height:250,width:'100%'}} option={option} />
    )
}
export default AVCoverage