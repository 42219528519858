// import React from 'react';
import ReactEcharts from 'echarts-for-react';

function SourceOfEvents(props){

    const option = {
        xAxis: {
            type: 'category',
            data: ['Endpoints', 'Web Proxy', 'Email Gateways', 'Active Directory'],
            axisLabel:{
                rotate:20,
                textStyle: {
                    color: '#ffffff'
                }
            },
            axisLine:{
                lineStyle:{
                        color:'white'
                }
            }
        },
        yAxis: {
            type: 'value'
        },
        textStyle: {
            color:"#fff"
        },
        series: [{
            data: [
                {
                    value: 15,
                    itemStyle: {
                        color: 'rgb(42, 187, 67)'
                    }
                }, 
                {
                    value: 20,
                    itemStyle: {
                        color: 'rgb(59, 155, 216)'
                    }
                }, 
                {
                    value: 50,
                    itemStyle: {
                        color: '#c23531'
                    }
                }, 
                {
                    value: 35,
                    itemStyle: {
                        color: '#0095ff'
                    }
                }, 
            ],
            type: 'bar',
            label: {
                show: false,
                position: 'inner',
                textStyle: {
                    color:"#000"
                }
            },
            showBackground: false,
            backgroundStyle: {
                color: 'rgba(220, 220, 220, 0.8)'
            }
        }]
    };        
        

    return (
        <ReactEcharts 
            style={{height:250,width:'100%'}} 
            option={option} 
        />
    )
    
}
export default SourceOfEvents