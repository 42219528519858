import ReactECharts from 'echarts-for-react';

function Chart(props) {
    const colors = ['#187de8', '#10a6ec', '#1c85bf', '#1CBF81', '#1CBF4B', '#26BF1C', '#77BF1C', '#BABF1C', '#4B1CBF', '#721CBF', '#1C57BF', '#BF721C']
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let last7Days = [];
    let xAxisData = [];
    let seriesData = [];
    if(props.data)
    {
        const propsData = props.data;
        for (let i=6; i > -1; i--) {
            let date = new Date();
            date.setUTCDate(date.getUTCDate() - i);
            last7Days.push(date);
            xAxisData.push(days[date.getDay()]);
        }
        for (const [index, value] of propsData.entries()) {
            //console.log(value);
            if(value.length>0)
            {
                let techComplianceData = [ 0, 0, 0, 0, 0, 0, 0];
                const technologyName = value[0].technology_name;
                for (const [ , val] of value.entries()) {
                    const valueDate = new Date(val.time);
                    const valueComplianceScore = val.technology_compliance;
                    for (const [j, storedDate] of last7Days.entries()) {
                        if(storedDate.getUTCDate() === valueDate.getUTCDate())
                        {
                            techComplianceData[j] = valueComplianceScore;
                        }
                    }
                }
                // console.log(technologyName, techComplianceData);
                const techData = {
                    name: technologyName,
                    type: 'line',
                    // stack: 'someStack',
                    lineStyle: {
                        color: colors[index]
                    },
                    areaStyle: {
                        color: colors[index]
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: techComplianceData
                }

                seriesData.push(techData)
            }
        }
    }

    const options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        textStyle: {
            color: '#fff'
        },
        toolbox: {
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top: '10%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: xAxisData
            }
        ],
        yAxis: [
            {
                type: 'value',
                max: 100,
                splitLine: {
                    lineStyle: {
                        color:'#ffffff44'
                    }
                }
            }
        ],
        series: seriesData
    };

    return (
        <ReactECharts 
            option={options}
            style={{width:"100%", height:"100%"}}
        />
    )
}
export default Chart