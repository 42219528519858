import React from 'react';
import {useState} from 'react';
import {Navigate} from 'react-router-dom';
import LogoutPage from '../Logout/LogoutPage';

const LogoutHandler = (props) => {

    const [isLoggedIn, setIsLoggedIn] = useState(parseInt(sessionStorage.getItem('isLoggedIn')));
    function loginHandler(value) {
        setIsLoggedIn(value);
    }
    return (
        <React.Fragment>
            {isLoggedIn===1
                ? <LogoutPage onLogin={loginHandler}/>
                : <Navigate to="/login" />}
        </React.Fragment>
    );
}

export default LogoutHandler


