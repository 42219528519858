import React from 'react';
import ReactEcharts from 'echarts-for-react';


const options = {
    xAxis: {
        type: 'category',
        data: ['L1', 'L2', 'L3'],
        //rgb
    },
    yAxis: {
        type: 'value'
    },
    textStyle: {
        color:"#fff"
    },
    grid: {
        top:20,
        left:40,
        right:30,
        bottom:25
    },
    series: [{
        data: [
            {
                value:61,
                itemStyle: {
                    color: 'rgb(42, 187, 67)'
                }
            }, 
            {
                value:23,
                itemStyle: {
                    color: 'rgb(59, 155, 216)'
                }
            }, 
            {
                value:16,
                itemStyle: {
                    color: 'rgb(230, 60, 60)'
                }
            }
        ],
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
            color: 'rgba(220, 220, 220, 0.8)'
        }
    }]
};


function ActivitySplit() {
    return (
            <ReactEcharts option={options}/>
    )
}
export default ActivitySplit;